import { RequireAuth } from 'hooks/useAuth';
import 'styles/app.scss';
import Layout from 'layouts/Layout';
import RootLayout from 'layouts/RootLayout';
import AdminUsersPage from 'pages/AdminPage/AdminPage';
import ApplicationDetailPage from 'pages/ApplicationDetailPage/ApplicationDetailPage';
import ApplicationPage from 'pages/ApplicationPage/ApplicationPage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import ErrorPage from 'pages/ErrorPage';
import EvaluationLogicPage from 'pages/EvaluationLogicPage/EvaluationLogicPage';
import EvaluationResultPage from 'pages/EvaluationResultPage/EvaluationResultPage';
import EvaluationStandardPage from 'pages/EvaluationStandardPage/EvaluationStandardPage';
import AuthPage from 'pages/LoginPage/AuthPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import PendingApprovalPage from 'pages/SignupPage/PendingApprovalPage';
import SignupPage from 'pages/SignupPage/SignupPage';
import SurveyCreatorPage from 'pages/SurveyCreatorPage/SurveyCreatorPage';
import TemplatePage from 'pages/SurveyPage/SurveyPage';
import SurveySetEditorPage from 'pages/SurveySetEditorPage/SurveySetEditorPage';
import SurveySetInfoPage from 'pages/SurveySetInfoPage/SurveySetInfoPage';
import InventoryPage from 'pages/SurveySetPage/SurveySetPage';
import VariablePage from 'pages/VariablePage/VariablePage';

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/pending-approval" element={<PendingApprovalPage />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        {/* 대시보드 */}
        <Route index element={<DashboardPage />} />

        {/* COD */}
        <Route path="application">
          <Route index element={<ApplicationPage />} />
          <Route path=":id" element={<ApplicationDetailPage />} />

          <Route
            path="evaluation-standard"
            element={<EvaluationStandardPage />}
          />
          <Route
            path="evaluation-standard/:templateId"
            element={<EvaluationStandardPage />}
          />
          <Route
            path="evaluation-standard/:templateId/logic/:itemId"
            element={<EvaluationLogicPage />}
          />
          <Route
            path="evaluation-standard/:templateId/variable/:itemId"
            element={<VariablePage />}
          />

          <Route path="evaluation-result" element={<EvaluationResultPage />} />
        </Route>

        {/* 질문지 관리 */}
        <Route path="survey">
          <Route index element={<TemplatePage />} />
          <Route path=":id" element={<SurveyCreatorPage />} />

          <Route path="set" element={<InventoryPage />} />
          <Route path="set/new" element={<SurveySetEditorPage />} />
          <Route path="set/:id" element={<SurveySetInfoPage />} />
          <Route path="set/:id/edit" element={<SurveySetEditorPage />} />
        </Route>

        {/* 관리자 권한 */}
        <Route path="admin" element={<AdminUsersPage />} />
      </Route>

      {/* Not Found */}
      <Route path="*" element={<ErrorPage />} />
    </Route>,
  ),
);
