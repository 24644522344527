import { useMemo } from 'react';

import styles from './PaginationBar.module.scss';
import Pagination from '../Pagination/Pagination';
import { SelectBox } from '../SelectBox/SelectBox';

interface Props {
  pageSize: number;
  setPageSize: (number: number) => void;
  currentPage: number;
  setCurrentPage: (number: number) => void;
  total: number;
  options: number[];
}
const PaginationBar = ({
  pageSize,
  setPageSize,
  currentPage,
  setCurrentPage,
  total,
  options,
}: Props) => {
  const changePagenationSelectHandler = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const MemoiziedSelectBox = useMemo(
    () => (
      <SelectBox
        value={pageSize}
        onChange={(value) => changePagenationSelectHandler(value)}
        className={styles.page_size_selectbox}
        options={options.map((pageSize) => ({
          name: `${pageSize}개씩 보기`,
          value: pageSize,
        }))}
        isUpward
      />
    ),
    [pageSize],
  );

  const MemoiziedPagination = useMemo(
    () => (
      <Pagination
        pageSize={pageSize}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        totalItemCount={total}
      />
    ),
    [total, currentPage],
  );

  return (
    <div className={styles.pagination_wrapper}>
      <div className={styles.center}>{MemoiziedPagination}</div>
      <div className={styles.right}>{MemoiziedSelectBox}</div>
    </div>
  );
};

export default PaginationBar;
