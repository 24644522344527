/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GraduateReviewUpsertRequest = {
    title?: string;
    name?: string;
    sequence?: number;
    profileImage?: Blob;
    jobPosition?: string;
    contents?: string;
    bootCampCategory?: GraduateReviewUpsertRequest.bootCampCategory;
    semester?: number;
    id?: number;
};

export namespace GraduateReviewUpsertRequest {

    export enum bootCampCategory {
        ALL = 'ALL',
        SEBFE = 'SEBFE',
        SEBBE = 'SEBBE',
        UUB = 'UUB',
        GMB = 'GMB',
        DOB = 'DOB',
        PMB = 'PMB',
        BEB = 'BEB',
        AIB = 'AIB',
    }


}

