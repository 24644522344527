/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionUpsertRequest } from './ConditionUpsertRequest';
import type { ExecutionUpsertRequest } from './ExecutionUpsertRequest';

export type LogicUpsertRequest = {
    id?: number;
    type?: LogicUpsertRequest.type;
    sequence?: number;
    conditions?: Array<ConditionUpsertRequest>;
    executions?: Array<ExecutionUpsertRequest>;
};

export namespace LogicUpsertRequest {

    export enum type {
        ALWAYS = 'ALWAYS',
        IFTHEN = 'IFTHEN',
    }


}

