import classNames from 'classnames';
import { PageTitle } from 'components/PageTitle/PageTitle';
import Sidebar from 'components/Sidebar/Sidebar';
import { useHeaderInfoStore, useSidebarStore } from 'store';

import { useLayoutEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import styles from './Layout.module.scss';

const Layout = () => {
  const { sidebarStatus } = useSidebarStore();
  const { headerInfo } = useHeaderInfoStore();
  const path = useLocation().pathname;
  const [fullScreen, setFullScreen] = useState(false);

  useLayoutEffect(() => {
    const shouldBeFullScreen = /^\/survey\/\d+$/.test(path); // 질문지 등록 및 수정 페이지
    setFullScreen(shouldBeFullScreen);
  }, [path]);

  return (
    <div
      className={classNames(styles.layout, styles[`sidebar_${sidebarStatus}`], {
        [styles.fullscreen]: fullScreen,
      })}
    >
      {!fullScreen && (
        <div className={styles.sidebar}>
          <Sidebar />
        </div>
      )}

      <main className={styles.main}>
        {!fullScreen && (
          <PageTitle
            title={headerInfo.title}
            pageInfo={headerInfo.pageInfo}
            titleTag={headerInfo.titleTag}
          />
        )}
        <div className={styles.outlet}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Layout;
