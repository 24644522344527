/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IssueEmailLoginRequest = {
    authType?: IssueEmailLoginRequest.authType;
    email?: string;
    password?: string;
};

export namespace IssueEmailLoginRequest {

    export enum authType {
        GOOGLE = 'GOOGLE',
        EMAIL = 'EMAIL',
    }


}

