import { useState } from 'react';

interface ModalContentType {
  title: string;
  subTitle?: string;
  btnLabel?: string;
  onBtnClick?: () => void;
  children: JSX.Element;
  height?: number;
  btnDisabled?: boolean;
}

export const useModal = (initialMode = false) => {
  const [isModalOpen, setIsModalOpen] = useState(initialMode);
  const [modalContent, setModalContent] = useState<ModalContentType>({
    title: '',
    btnLabel: 'Done',
    onBtnClick: () => console.log('modal'),
    children: <p>modal element</p>,
  });
  return { isModalOpen, setIsModalOpen, modalContent, setModalContent };
};
