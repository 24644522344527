import { ReactComponent as BarChartIcon } from 'assets/bar-chart-square-up.svg';
import { Button } from 'components/Button/Button';
import { checkIsOnlyNum } from 'utils/regex/validationChecker';

import type { ChangeEvent } from 'react';
import { useEffect, useRef, useState } from 'react';

import styles from './CutOffLineSettingBar.module.scss';

const THREE_DIGITS = 3;

interface CutOffLineSettingBarProps {
  cutOffScore?: number;
  handleCutOffScoreSave: (score: number) => void;
}

const makeDoubleDigit = (num: string) => {
  if (num.length === 1) {
    return `0${num}`;
  }
  return num;
};

const CutOffLineSettingBar = ({
  cutOffScore,
  handleCutOffScoreSave,
}: CutOffLineSettingBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState<string>('00');
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length === 0) {
      setInputValue('');
    }

    const isOnlyNum = checkIsOnlyNum(value);
    if (!isOnlyNum) {
      return;
    }

    if (value.length < THREE_DIGITS || value === '100') {
      setInputValue(value);
    }
  };

  const handleInputBlur = () => {
    if (inputValue.length === 0) {
      setInputValue('00');
    } else if (inputValue.length === 1) {
      setInputValue(`0${inputValue}`);
    }
  };

  const handleInputFocus = () => {
    setIsEditing(true);
  };

  const handleEditBtnClick = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
  };

  const handleSaveBtnClick = () => {
    handleCutOffScoreSave(Number(inputValue));
    setIsEditing(false);
  };

  useEffect(() => {
    if (cutOffScore) {
      setInputValue(makeDoubleDigit(cutOffScore.toString()));
    } else {
      setInputValue('00');
    }
  }, [cutOffScore]);

  return (
    <div className={styles.cut_off_line_setting}>
      <div className={styles.score_part}>
        <div className={styles.label}>
          <BarChartIcon />
          <p>최종 점수 커트라인</p>
        </div>
        <div className={styles.score}>
          <input
            ref={inputRef}
            value={inputValue}
            className={`${styles.cut_off_score_input} ${
              cutOffScore === undefined && styles.not_yet
            }`}
            onChange={(e) => handleInputChange(e)}
            onBlur={handleInputBlur}
            onFocus={handleInputFocus}
          />
          <p className={styles.unit}>점</p>
        </div>
      </div>
      <div className={styles.button_wrapper}>
        <Button
          variant="line-skeleton"
          isFullWidth
          size="small"
          onClick={isEditing ? handleSaveBtnClick : handleEditBtnClick}
        >
          {isEditing ? '저장' : '수정'}
        </Button>
      </div>
    </div>
  );
};

export default CutOffLineSettingBar;
