import VariableItem from './VariableItem/VariableItem';
import styles from './VariableSection/VariableSection.module.scss';

import type { VariableCardsType } from '../types';

const VariableCards = ({
  variableCards,
  setVariableCards,
  beforeAnnounce,
}: {
  variableCards?: VariableCardsType[];
  setVariableCards: React.Dispatch<React.SetStateAction<VariableCardsType[]>>;
  beforeAnnounce?: boolean;
}) => (
  <ul className={styles.wrapper_variables}>
    {variableCards?.map((variable: VariableCardsType, i: number) => (
      <div key={`${variable.id} + ${i}`}>
        <VariableItem
          variableCards={variableCards}
          setVariableCards={setVariableCards}
          id={variable.id}
          thisIndex={i}
          thisCard={variable}
          beforeAnnounce={beforeAnnounce}
        />
      </div>
    ))}
  </ul>
);

export default VariableCards;
