import { Button } from 'components/Button/Button';

import { PlusCircle } from 'react-feather';

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import type { DropResult } from '@hello-pangea/dnd';

import styles from './SurveySection.module.scss';
import SectionTemplate from '../SectionTemplate/SectionTemplate';
import SurveySectionBar from '../SurveySectionBar/SurveySectionBar';

import type { SurveyResponse } from 'generated';

interface SurveySectionProps {
  page: string;
  surveys?: (SurveyResponse | undefined)[];
  handleAddButtonClick?: () => void;
  handleDeleteButtonClick?: (survey: SurveyResponse) => void;
  setSurveyRepo?: (newSurveyRepo: (SurveyResponse | undefined)[]) => void;
  handlePreviewButtonClick?: (survey: SurveyResponse) => void;
  handleSurveyInputClick?: (survey: SurveyResponse) => void;
  handleSurveySettingClick?: (survey: SurveyResponse) => void;
}

const SurveySection = ({
  page,
  surveys,
  handleAddButtonClick,
  handleDeleteButtonClick,
  setSurveyRepo,
  handlePreviewButtonClick,
  handleSurveyInputClick,
  handleSurveySettingClick,
}: SurveySectionProps) => {
  const onDragEnd = (result: DropResult) => {
    if (!surveys) {
      return;
    }

    const newItems = Array.from(surveys);
    const [removed] = newItems.splice(result.source.index, 1);
    if (result.destination) {
      newItems.splice(result.destination.index, 0, removed);
    }
    if (setSurveyRepo) {
      setSurveyRepo(newItems);
    }
  };

  return (
    <SectionTemplate
      ordinal={2}
      title={
        <>
          연결된 질문지 <strong>*</strong>
        </>
      }
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={styles.survey_section_wrapper}>
          {surveys?.length ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {surveys.map((item, index) => (
                      <Draggable
                        key={item?.id}
                        draggableId={`${item?.id}`}
                        index={index}
                        isDragDisabled={page === 'info'}
                      >
                        {(provided, snapshot) => (
                          <SurveySectionBar
                            disabled={page === 'info'}
                            key={item?.id}
                            surveyTitle={item?.title || ''}
                            survey={item}
                            provided={provided}
                            snapshot={snapshot}
                            handleDeleteButtonClick={handleDeleteButtonClick}
                            handlePreviewButtonClick={handlePreviewButtonClick}
                            handleSurveyInputClick={handleSurveyInputClick}
                            handleSurveySettingClick={handleSurveySettingClick}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className={styles.blank_section}>질문지를 추가해주세요!</div>
          )}
          <div className={styles.button_box}>
            <Button
              rightIcon={<PlusCircle />}
              onClick={handleAddButtonClick}
              isDisabled={page === 'info'}
            >
              질문지 추가하기
            </Button>
          </div>
        </div>
      </DragDropContext>
    </SectionTemplate>
  );
};

export default SurveySection;
