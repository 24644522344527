import { AxiosError } from 'axios';
import { AnswerChangeStateRequest } from 'generated';
import queryAxios from 'query/queryAxios';

import type {
  ApplicationAnswerWithoutResponse,
  ApplicationFullResponse,
} from 'generated';

export const getApplicationByIdRequest = async (
  applicationId: number | undefined,
) => {
  if (!applicationId) {
    throw new AxiosError('application id가 없습니다.');
  }
  return queryAxios()
    .get<ApplicationFullResponse>(`applications/${applicationId}`)
    .then(({ data }) => data);
};

export const getApplicationByUuidRequest = async (
  applicationUuid: string | undefined,
) => {
  if (!applicationUuid) {
    throw new AxiosError('application uuid가 없습니다.');
  }
  return queryAxios()
    .get<ApplicationFullResponse>(`applications/uuid/${applicationUuid}`)
    .then(({ data }) => data);
};

export const updateAnswerStatusRequest = async (
  userUuid: string | undefined,
  applicationUuid: string | undefined,
  answerId: number | undefined,
  request: AnswerChangeStateRequest | undefined,
) => {
  if (!userUuid) {
    throw new AxiosError('user uuid가 없습니다.');
  }
  if (!applicationUuid) {
    throw new AxiosError('application uuid가 없습니다.');
  }
  if (!answerId) {
    throw new AxiosError('answer id가 없습니다.');
  }
  if (!request) {
    throw new AxiosError('request body가 없습니다.');
  }

  return queryAxios().patch(
    `applicants/${userUuid}/applications/${applicationUuid}/answers/${answerId}/state-change`,
    request,
  );
};

export const createAndUpdateStatusRequest = async (
  userUuid: string | undefined,
  applicationUuid: string | undefined,
  itemId: number | undefined,
) => {
  if (!userUuid) {
    throw new AxiosError('user uuid가 없습니다.');
  }
  if (!applicationUuid) {
    throw new AxiosError('application uuid가 없습니다.');
  }
  if (!itemId) {
    throw new AxiosError('item id가 없습니다.');
  }

  const createAnswer =
    await queryAxios().post<ApplicationAnswerWithoutResponse>(
      `applicants/${userUuid}/applications/${applicationUuid}/template-items/${itemId}/answers`,
    );
  const updateAnswer = await queryAxios().patch(
    `applicants/${userUuid}/applications/${applicationUuid}/answers/${createAnswer.data.id}/state-change`,
    {
      changeFrom: AnswerChangeStateRequest.changeFrom.CREATED,
      changeTo: AnswerChangeStateRequest.changeTo.SUBMITTED,
    },
  );
  return updateAnswer;
};
