/* eslint-disable no-useless-escape */
import Input from 'components/Input/Input';
import { checkTextKoreanOrNot } from 'utils/regex/validationChecker';

import { useState } from 'react';
import type { ChangeEvent } from 'react';

import styles from './BasicInfoSection.module.scss';
import SectionTemplate from '../SectionTemplate/SectionTemplate';

interface BasicInfoSectionProps {
  page: string;
  id: string | undefined;
  title: string;
  slugUrl: string;
  handleInputTitleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleInputSlugUrlChange?: (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
  ) => void;
}

const BasicInfoSection = ({
  page,
  id,
  title,
  handleInputTitleChange,
  slugUrl,
  handleInputSlugUrlChange,
}: BasicInfoSectionProps) => {
  const course = slugUrl ? slugUrl.split('-').slice(0, -1).join('-') : '';
  const cohort = slugUrl ? slugUrl.split('-').slice(-1)[0] : '';
  const [alertMessage, setAlertMessage] = useState('');

  const isInputKorean = (e: ChangeEvent<HTMLInputElement>) => {
    const koreanExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
    const isKorean = koreanExp.test(e.target.value);
    if (isKorean) {
      setAlertMessage('한글은 입력하실 수 없습니다.');
      checkTextKoreanOrNot(e);
    } else {
      setAlertMessage('');
    }
  };

  const handleSlugUrlOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    isInputKorean(e);
    if (handleInputSlugUrlChange) {
      handleInputSlugUrlChange(e, type);
    }
  };

  return (
    <SectionTemplate ordinal={1} title={'기본 정보'}>
      <div className={styles.section_content}>
        <div className={styles.set_info}>
          <div className={styles.sub_content}>
            <span className={styles.section_subtitle}>
              세트명 <strong>*</strong>
            </span>
            <div className={styles.input_wrapper}>
              <Input
                defaultValue={title}
                placeholder="질문지 세트명을 작성해주세요."
                isDisabled={page === 'info'}
                onChange={handleInputTitleChange}
              />
            </div>
          </div>
          {id && (
            <div className={styles.sub_content}>
              <span className={styles.section_subtitle}>질문지 세트 ID</span>
              <span className={styles.id}>{id}</span>
            </div>
          )}
        </div>
        <div className={styles.course_info}>
          <div className={styles.sub_content}>
            <span className={styles.section_subtitle}>
              코스 정보 <strong>*</strong>
            </span>
            <div className={styles.inputs_wrapper}>
              <div className={styles.input_wrapper}>
                <Input
                  defaultValue={course}
                  placeholder="코스명"
                  isDisabled={page === 'info'}
                  onChange={(e) => handleSlugUrlOnChange(e, 'course')}
                />
              </div>
              <div className={styles.input_wrapper}>
                <Input
                  defaultValue={cohort}
                  placeholder="기수"
                  isDisabled={page === 'info'}
                  onChange={(e) => handleSlugUrlOnChange(e, 'cohort')}
                />
              </div>
            </div>
          </div>
          <span className={styles.is_slugurl_korean}>{alertMessage}</span>
        </div>
      </div>
    </SectionTemplate>
  );
};

export default BasicInfoSection;
