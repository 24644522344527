import type { RefObject } from 'react';
import { useEffect } from 'react';

type Handler = (event: MouseEvent) => void;

const useOnClickOutside = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler?: Handler,
): void => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const el = ref?.current;
      // ref의 내부를 클릭하면 아무것도 하지 않음
      if (!el || el.contains(event.target as Node)) {
        return;
      }
      if (!handler) {
        return;
      }
      handler(event);
    };

    window.addEventListener('mouseup', listener);

    return () => {
      window.removeEventListener('mouseup', listener);
    };
  }, [handler]);
};

export default useOnClickOutside;
