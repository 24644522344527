import axios, { AxiosError } from 'axios';
import { isNil } from 'lodash-es';

import { useEffect } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { API_ENDPOINT } from '../../constants/env';
import { useAuth } from '../../hooks/useAuth';
import useToast from '../../utils/hooks/useToast';

/** @deprecated */
const AuthPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const social_provider = searchParams.get('social_provider');

  const { showToast, toastType } = useToast();

  const newGoogleLogin = async (code: string) => {
    try {
      const token = await axios
        .post(`${API_ENDPOINT}auth`, {
          code,
          redirectURI: `${window.location.origin}/auth?social_provider=google`,
        })
        .then(({ data }) => {
          const { token } = data;
          if (!token) {
            throw new AxiosError('token이 없습니다.');
          }
          return token;
        });
      auth.signin(token);
      navigate('/', { replace: true });
    } catch (err) {
      const error = err as AxiosError;
      showToast({ message: `${error.message}`, type: toastType.negative });
    }
  };

  useEffect(() => {
    if (social_provider !== 'google' || isNil(code)) {
      navigate('/login', { replace: true });
      // authErrorToast('인증에 실패하였습니다. 엔지니어링팀에 문의하세요.');
      return;
    }
    newGoogleLogin(code);
  }, [social_provider, code]);

  if (auth) {
    return <Navigate to="/" />;
  }

  return <></>;
};

export default AuthPage;
