import type { ReactElement } from 'react';

import styles from './SectionTemplate.module.scss';

interface SectionTemplateProps {
  ordinal: number;
  title: string | ReactElement;
  children: ReactElement;
}

const SectionTemplate = ({
  ordinal,
  title,
  children,
}: SectionTemplateProps) => (
  <div className={styles.template_wrapper}>
    <h3 className={styles.section_title}>
      <span>{ordinal}</span>. {title}
    </h3>
    {children}
  </div>
);

export default SectionTemplate;
