import Lottie from 'lottie-react';

import styles from './Loading.module.scss';
import * as animationData from './admission-loader.json';

interface LoadingProps {
  className?: string;
  iconSize?: {
    width: number | string;
    height: number | string;
  };
  isFull?: boolean;
  isTable?: boolean;
}

const Loading = ({
  iconSize = { width: 135, height: 135 },
  isFull = false,
  isTable = false,
}: LoadingProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
  };

  return isTable ? (
    <div className={styles.loading_fit_container}>
      <Lottie
        {...defaultOptions}
        style={{
          width: iconSize.width,
          height: iconSize.height,
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
    </div>
  ) : isFull ? (
    <div className={styles.loading_full_container}>
      <div className={styles.loading_loader_wrapper}>
        <Lottie
          {...defaultOptions}
          style={{
            width: iconSize.width,
            height: iconSize.height,
          }}
        />
      </div>
    </div>
  ) : (
    <Lottie
      {...defaultOptions}
      style={{
        width: iconSize.width,
        height: iconSize.height,
      }}
    />
  );
};
export default Loading;
