import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';
import { SelectBox } from 'components/SelectBox/SelectBox';
import { ApplicationChangeStateRequest } from 'generated';
import useToast from 'utils/hooks/useToast';

import { useEffect, useState } from 'react';

import styles from './UpdateApplicationModal.module.scss';
import CheckUpdateEvaluationModal from '../CheckUpdateEvaluationModal/CheckUpdateEvaluationModal';

import type { UpdateApplicationInputType } from '../../types';

interface Props {
  updateApplicationInput: UpdateApplicationInputType;
  updateApplicationModalOpen: boolean;
  setUpdateApplicationModalOpen: (boolean: boolean) => void;
  updateApplicationMutator: (request: ApplicationChangeStateRequest) => void;
}

const UpdatedApplicationModal = ({
  updateApplicationInput,
  updateApplicationModalOpen,
  setUpdateApplicationModalOpen,
  updateApplicationMutator,
}: Props) => {
  const { showToast, toastType } = useToast();
  const { name, applicationId, changeType, value, modalOption } =
    updateApplicationInput;

  const [newValue, setNewValue] = useState<string>();
  const [isCheckUpdateModalOpen, setIsCheckUpdateModalOpen] = useState(false);

  const handleUpdate = () => {
    if (applicationId && changeType && newValue) {
      updateApplicationMutator({
        changeType,
        changeFrom: value ?? '',
        changeTo: newValue,
      });
    } else {
      showToast({
        message: '상태 변경에 실패했습니다. (request body data is not found)',
        type: toastType.negative,
      });
      setUpdateApplicationModalOpen(false);
    }
  };

  useEffect(() => {
    if (updateApplicationModalOpen) {
      setNewValue(value);
    }
  }, [updateApplicationModalOpen]);

  return (
    <>
      <Modal
        isOpen={updateApplicationModalOpen}
        onClose={() => setUpdateApplicationModalOpen(false)}
      >
        <Modal.Content>
          <Modal.Header>
            <p>{`${updateApplicationInput.modalTitle} 상태 변경`}</p>
            <Modal.Header.SubTitle>
              지원자의 상태가 변경되니, 신중하게 변경해주세요.
            </Modal.Header.SubTitle>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.query_value_selectbox}>
              <SelectBox
                className={styles.dropdown_middle}
                value={newValue === '' ? 'IN_PROGRESS' : newValue}
                options={modalOption.slice(1)}
                onChange={(value) => setNewValue(value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                if (
                  changeType ===
                  ApplicationChangeStateRequest.changeType.EVALUATION_STATUS
                ) {
                  setUpdateApplicationModalOpen(false);
                  setIsCheckUpdateModalOpen(true);
                } else {
                  handleUpdate();
                }
              }}
            >
              저장하기
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <CheckUpdateEvaluationModal
        userName={name ?? ''}
        isOpen={isCheckUpdateModalOpen}
        onClose={() => setIsCheckUpdateModalOpen(false)}
        onDoneBtnClicked={() => {
          handleUpdate();
          setIsCheckUpdateModalOpen(false);
        }}
      />
    </>
  );
};

export default UpdatedApplicationModal;
