/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Choice } from './Choice';

export type VariableResponse = {
    id?: number;
    varType?: VariableResponse.varType;
    name?: string;
    title?: string;
    valueType?: VariableResponse.valueType;
    defaultValue?: string;
    editable?: boolean;
    choices?: Array<Choice>;
};

export namespace VariableResponse {

    export enum varType {
        ANSWER = 'ANSWER',
        FIXED = 'FIXED',
        CUSTOM = 'CUSTOM',
    }

    export enum valueType {
        STRING = 'STRING',
        NUMBER = 'NUMBER',
        BOOLEAN = 'BOOLEAN',
        DATE = 'DATE',
        CHOICE = 'CHOICE',
        LIST = 'LIST',
    }


}

