import React from 'react';

import styles from './Alert.module.scss';

const AlertFooter = ({ children }: React.PropsWithChildren) => (
  <footer className={styles.alert_footer}>
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          isFullWidth: true,
          size: child.props.size ?? 'large',
        });
      }
      return child;
    })}
  </footer>
);

export default AlertFooter;
