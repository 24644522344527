/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useState, useEffect } from 'react';

import styles from './IsHoverLight.module.scss';

interface Props {
  content: string | number | undefined;
  hoverContent?: any;
  size?: 'md' | 'lg';
}
const IsHoverLight = ({ content, hoverContent, size = 'md' }: Props) => {
  const CONTENT_MAX_WIDTH = size === 'md' ? 80 : 300;

  const [isHoverable, setIsHoverable] = useState(false);
  const [hoverOn, setHoverOn] = useState(false);

  const beforeContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof content === 'number') {
      setIsHoverable(true);
    }
    if (
      typeof content === 'string' &&
      beforeContentRef.current &&
      beforeContentRef.current.clientWidth >= CONTENT_MAX_WIDTH
    ) {
      setIsHoverable(true);
    }
  }, [beforeContentRef.current]);

  return (
    <div
      onMouseEnter={() => {
        if (isHoverable) {
          setHoverOn(true);
        }
      }}
      onMouseLeave={() => setHoverOn(false)}
      className={styles.wrapper}
    >
      {!hoverOn ? (
        <div
          ref={beforeContentRef}
          className={`${styles.before_hover} ${
            size === 'md' ? styles.before_hover_md : styles.before_hover_lg
          }`}
        >
          {content}
        </div>
      ) : (
        <div className={styles.after_hover}>{hoverContent || content} </div>
      )}
    </div>
  );
};

export default IsHoverLight;
