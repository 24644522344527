import { ErrorCode } from 'constants/serverErrorType';

import { VariableResponse } from 'generated';
import { produce } from 'immer';
import {
  checkBarOrUnderBar,
  checkIsOnlyNum,
  checkIsOnlyText,
  preventWriteExclamationMark,
  preventWriteNumber,
  preventWriteText,
} from 'utils/regex/validationChecker';

import { useCallback, useEffect } from 'react';
import type React from 'react';

import type { VariableCardsType } from './types';

const useHandler = (
  errorMessage: {
    title: string;
    value: string;
  },
  setErrorMessage: React.Dispatch<
    React.SetStateAction<{
      title: string;
      value: string;
    }>
  >,
  variableCards: VariableCardsType[],
  setVariableCards: React.Dispatch<React.SetStateAction<VariableCardsType[]>>,
  thisIndex: number,
  thisCard: VariableCardsType,
) => {
  const errCode = thisCard?.errCode;
  const initialErrorMessage = { title: '', value: '' };
  const resetErrorMessage = useCallback(
    () => setErrorMessage(initialErrorMessage),
    [],
  );

  useEffect(() => {
    if (!errCode) {
      resetErrorMessage();
    }

    if (
      errCode === ErrorCode.EMPTY_FIELD ||
      errCode === ErrorCode.DUPLICATED_NAME
    ) {
      setErrorMessage({
        ...errorMessage,
        title: '  ',
      });
    }
  }, [errCode]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 20) {
      setErrorMessage({
        ...errorMessage,
        title: '변수명은 20자 이하로 입력할 수 있습니다.',
      });
      return;
    }
    if (!checkBarOrUnderBar(e.target.value)) {
      preventWriteExclamationMark(e);
      setErrorMessage({
        ...errorMessage,
        title: '특수문자는 -, _만 입력할 수 있습니다.',
      });
      return;
    }

    const changedCards = produce(
      variableCards,
      (draftCard: VariableCardsType[]) => {
        const thisCard = draftCard[thisIndex];
        thisCard.title = e.target.value;
        thisCard.errCode = '';
      },
    );

    setVariableCards(changedCards);
    resetErrorMessage();
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      const changedCards = produce(
        variableCards,
        (draftCard: VariableCardsType[]) => {
          const thisCard = draftCard[thisIndex];
          thisCard.defaultValue = e.target.value;
          thisCard.errCode = '';
        },
      );

      setVariableCards(changedCards);
      resetErrorMessage();
      return;
    }
    if (
      thisCard.valueType === VariableResponse.valueType.NUMBER &&
      !checkIsOnlyNum(e.target.value)
    ) {
      preventWriteText(e);
      setErrorMessage({
        ...errorMessage,
        value: '선택한 유형은 숫자만 입력할 수 있습니다.',
      });
      return;
    }
    if (
      thisCard.valueType === VariableResponse.valueType.STRING &&
      !checkIsOnlyText(e.target.value)
    ) {
      preventWriteNumber(e);
      setErrorMessage({
        ...errorMessage,
        value: '선택한 유형은 텍스트만 입력할 수 있습니다.',
      });
      return;
    }
    const changedCards = produce(
      variableCards,
      (draftCard: VariableCardsType[]) => {
        const thisCard = draftCard[thisIndex];
        thisCard.defaultValue = e.target.value;
        thisCard.errCode = '';
      },
    );

    setVariableCards(changedCards);
    resetErrorMessage();
  };

  const handleChoiceValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedChoiceValue = produce(
      variableCards,
      (draftCard: VariableCardsType[]) => {
        const thisCard = draftCard[thisIndex];
        const choices = e.target.value
          .split(',')
          .map((choice) => ({ value: choice.trim(), text: choice.trim() }));

        thisCard.choices = choices;

        thisCard.defaultValue = choices[0].text;
      },
    );

    setVariableCards(changedChoiceValue);
  };

  const handleDateValueChange = (value?: string) => {
    const changedDateValue = produce(
      variableCards,
      (draftCard: VariableCardsType[]) => {
        const thisCard = draftCard[thisIndex];
        thisCard.defaultValue = value;
        thisCard.errCode = '';
      },
    );
    setVariableCards(changedDateValue);
  };

  const handleValueTypeChange = (e: VariableResponse.valueType) => {
    resetErrorMessage();

    const changedCards = produce(
      variableCards,
      (draftCard: VariableCardsType[]) => {
        const thisCard = draftCard[thisIndex];
        thisCard.errCode = '';
        thisCard.valueType = e;
        thisCard.defaultValue = '';
      },
    );

    setVariableCards(changedCards);
  };

  const handleDeleteCards = () => {
    const changedCards = produce(
      variableCards,
      (draftCard: VariableCardsType[]) => {
        draftCard.splice(thisIndex, 1);
      },
    );
    setVariableCards(changedCards);
  };
  return {
    handleDeleteCards,
    handleTitleChange,
    handleValueChange,
    handleValueTypeChange,
    handleChoiceValueChange,
    handleDateValueChange,
  };
};

export default useHandler;
