/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ApplicationChangeStateRequest = {
    changeType: ApplicationChangeStateRequest.changeType;
    changeFrom: string;
    changeTo: string;
};

export namespace ApplicationChangeStateRequest {

    export enum changeType {
        APPLYING_STATUS = 'APPLYING_STATUS',
        EVALUATION_STATUS = 'EVALUATION_STATUS',
        INTENTION_STATUS = 'INTENTION_STATUS',
    }


}

