import styles from './Table.module.scss';

interface TProps extends React.HTMLAttributes<HTMLDivElement> {
  isResizable?: boolean;
}

const getSizeClassName = (isResizable?: boolean) =>
  !isResizable ? styles.default : styles.resizable;

export const Th = ({ children, className, isResizable }: TProps) => {
  const sizeClassName = getSizeClassName(isResizable);
  className = [styles.th, sizeClassName, className].join(' ');
  return <th className={className}>{children ? <div>{children}</div> : ''}</th>;
};

export const Td = ({ children, className, isResizable }: TProps) => {
  const sizeClassName = getSizeClassName(isResizable);
  className = [styles.td, sizeClassName, className].join(' ');
  return (
    <td className={className}>
      {children ? (
        <section className={styles.td_contents_wrap}>{children}</section>
      ) : (
        <></>
      )}
    </td>
  );
};

export const Tr = ({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  className = [styles.tr, className].join(' ');
  return <tr className={className}>{children}</tr>;
};

export const Tbody = ({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  className = [styles.tbody, className].join(' ');
  return <tbody className={className}>{children}</tbody>;
};

export const Thead = ({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  className = [styles.thead, className].join(' ');
  return <thead className={className}>{children}</thead>;
};
export const Table = ({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>) => {
  className = [styles.table, className].join(' ');
  return <table className={className}>{children}</table>;
};
