/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DateTimePeriod } from './DateTimePeriod';

export type BannerResponse = {
    id?: number;
    title?: string;
    sequence?: number;
    pcImgUrl?: string;
    mobileImgUrl?: string;
    forwardLink?: string;
    bootCampCategory?: BannerResponse.bootCampCategory;
    type?: BannerResponse.type;
    displayPeriod?: DateTimePeriod;
    hide?: boolean;
};

export namespace BannerResponse {

    export enum bootCampCategory {
        ALL = 'ALL',
        SEBFE = 'SEBFE',
        SEBBE = 'SEBBE',
        UUB = 'UUB',
        GMB = 'GMB',
        DOB = 'DOB',
        PMB = 'PMB',
        BEB = 'BEB',
        AIB = 'AIB',
    }

    export enum type {
        HERO = 'HERO',
        POPUP = 'POPUP',
    }


}

