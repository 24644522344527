/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CozWebIndicatorResponse = {
    id?: number;
    bootCampCategory?: CozWebIndicatorResponse.bootCampCategory;
    name?: string;
    value?: string;
    unit?: string;
    caption?: string;
    description?: string;
};

export namespace CozWebIndicatorResponse {

    export enum bootCampCategory {
        ALL = 'ALL',
        SEBFE = 'SEBFE',
        SEBBE = 'SEBBE',
        UUB = 'UUB',
        GMB = 'GMB',
        DOB = 'DOB',
        PMB = 'PMB',
        BEB = 'BEB',
        AIB = 'AIB',
    }


}

