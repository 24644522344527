import { ReactComponent as IconClose } from 'assets/modal-x.svg';
import useOnClickOutside from 'hooks/useOnClickOutside';

import { useEffect, useRef } from 'react';

import styles from './Alert.module.scss';
import { useModalContext } from '../Modal/Modal';

const Alert = ({ children }: React.PropsWithChildren) => {
  const { onClose } = useModalContext();
  const contentRef = useRef(null);
  const onCloseRef = useRef<() => void>();
  useOnClickOutside(contentRef, onCloseRef.current);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  return (
    <div className={styles.alert} ref={contentRef}>
      <button className={styles.alert_btn_close} onClick={onClose}>
        <IconClose />
      </button>

      <div className={styles.alert_content}>{children}</div>
    </div>
  );
};

export default Alert;
