import useHeader from 'utils/hooks/useHeader';

import { useNavigate } from 'react-router-dom';

import VariableSection from './components/VariableSection/VariableSection';

const VariablePage = () => {
  const navigate = useNavigate();

  useHeader({
    title: '변수 관리',
    pageInfo: [
      { page: '심사 기준 관리', onClick: () => navigate(-1) },
      { page: '변수 관리' },
    ],
  });

  return (
    <>
      <VariableSection />
    </>
  );
};

export default VariablePage;
