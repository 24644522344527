import classNames from 'classnames';
import { find } from 'lodash';

import { useState, useCallback, useEffect } from 'react';
import { Check } from 'react-feather';

import styles from './SelectBox.module.scss';

export interface SelectBoxProps {
  value: any;
  options: { value: any; name: string }[];
  onChange?: (value: any) => any;
  className?: string;
  label?: string;
  placeholder?: string;
  isUpward?: boolean;
  isFullHeight?: boolean;
  hasCheckIcon?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
  alignItems?: 'center' | 'left';
  overflowY?: 'visible' | 'scroll';
}

export const SelectBox = ({
  value,
  options,
  onChange,
  className,
  label,
  placeholder = '선택',
  isUpward,
  isFullHeight,
  hasCheckIcon = false,
  isDisabled,
  isInvalid,
  alignItems = 'center',
  overflowY,
}: SelectBoxProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const findNameByValue = useCallback(
    (value: any) => find(options, { value })?.name,
    [options],
  );

  const [selectedName, setSelectedName] = useState<string>();
  const buttonClassNames = classNames(
    styles.selectbox,
    className,
    isOptionsOpen && styles.open,
    isInvalid && styles.invalid,
  );
  const wrapClassNames = classNames(
    styles.selectbox_wrap,
    isFullHeight && styles.isFullHeight,
  );
  const upwardClassName = isUpward && styles.upward;
  const hasCheckIconClassName = hasCheckIcon ? styles.with_check : '';

  useEffect(() => {
    setSelectedName(findNameByValue(value));
  }, [options, value]);

  return (
    <>
      {label && (
        <label className={styles.label} htmlFor={label}>
          {label}
        </label>
      )}

      <div className={wrapClassNames}>
        <button
          name={label}
          className={buttonClassNames}
          onClick={() => setIsOptionsOpen(!isOptionsOpen)}
          onBlur={() => setIsOptionsOpen(false)}
          disabled={isDisabled}
        >
          <p
            className={classNames(
              styles.selectbox_value,
              !selectedName && styles.placeholder,
            )}
          >
            {selectedName ?? placeholder}
          </p>
          <div className={classNames(styles.triangle, upwardClassName)} />
        </button>

        <ul
          className={classNames(
            styles.options,
            !isOptionsOpen && styles.hidden,
            upwardClassName,
            overflowY === 'scroll' && styles.overflow_scroll,
          )}
        >
          <li className={classNames(styles.empty_space, styles.top)} />
          {options.map(({ value, name }, index) => (
            <li key={`${index}-${value}`}>
              <button
                className={classNames(
                  styles.item_button,
                  alignItems === 'left' && styles.align_left,
                  hasCheckIconClassName,
                )}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
                onClick={() => {
                  setSelectedName(name);
                  if (onChange) {
                    onChange(value);
                  }
                  setIsOptionsOpen(false);
                }}
              >
                <p>{name}</p>
                {hasCheckIcon && <Check className={styles.check} />}
              </button>
            </li>
          ))}
          <li className={classNames(styles.empty_space, styles.bottom)} />
        </ul>
      </div>
    </>
  );
};
