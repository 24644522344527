/* eslint-disable array-callback-return */
import produce from 'immer';

import type { CurrentCardTypes, VariableCardsType } from '../types';
import type { ErrorCode } from 'constants/serverErrorType';

export const getNoNameVariablesIndexs = (currentCards?: CurrentCardTypes[]) =>
  currentCards?.map((card, i) => (!card.name ? i : 0)).filter(Boolean);

export const getDuplicatedVariableIndexs = (
  name: string,
  currentCards?: CurrentCardTypes[],
) =>
  currentCards
    ?.map((card, i) => (card.name === name && !card.id ? i : 0))
    .filter(Boolean);

export const setSaveErrorCards = (
  variableCards: VariableCardsType[],
  setVariableCards: React.Dispatch<React.SetStateAction<VariableCardsType[]>>,
  errCode: keyof typeof ErrorCode,
  errIndexs: number[],
) => {
  const changedCards = produce(
    variableCards,
    (draftCard: VariableCardsType[]) => {
      errIndexs.map((errIndex) => {
        draftCard[errIndex].errCode = errCode;
      });
    },
  );

  setVariableCards(changedCards);
};
