/* eslint-disable @typescript-eslint/no-use-before-define */
import classNames from 'classnames';
import DateInput from 'components/DateInput/DateInput';
import Input from 'components/Input/Input';
import SearchSelectBox from 'components/SearchSelectBox/SearchSelectBox';
import { SelectBox } from 'components/SelectBox/SelectBox';
import { ConditionResponse } from 'generated';

import React from 'react';
import { Trash2 } from 'react-feather';

import styles from './Condition.module.scss';
import useCondition from './useCondition';
import { conditionLogicGateOptions, conditionLeftTypeOptions } from '../data';
import { InputStyle } from '../type';

export interface ConditionProps {
  clientId: string;
  condition: ConditionResponse;
  isValid?: boolean;
  isDone?: boolean;
}

const Condition = (props: ConditionProps) => {
  const { condition, isDone } = props;
  const {
    isInvalidActive,
    handleDeleteCondition,
    handleLogicGateChange,
    leftType,
    leftVariable,
    leftVariableOptions,
    handleLeftTypeChange,
    handleLeftVariableChange,
    rightInputStyle,
    rightChoiceOptions,
    handleRightInputChanged,
    handleRightSelectChanged,
    handleRightDateChanged,
    operatorOptions,
    handleOperatorChange,
    preventInputOnWheel,
  } = useCondition(props);

  return (
    <div className={styles.statement_wrapper}>
      {condition.logicGate &&
        condition.logicGate !== ConditionResponse.logicGate.NONE && (
          <div className={classNames(styles.row, styles.logicGate)}>
            <SelectBox
              className={classNames(
                styles.selectbox,
                styles.solid,
                isDone && styles.done,
              )}
              value={condition.logicGate}
              options={conditionLogicGateOptions}
              onChange={handleLogicGateChange}
              alignItems="left"
              isDisabled={isDone}
            />
          </div>
        )}
      <div className={classNames(styles.row, styles.left)}>
        <SelectBox
          className={classNames(
            styles.selectbox,
            styles.left_type,
            isDone && styles.done,
          )}
          value={leftType}
          options={conditionLeftTypeOptions}
          onChange={handleLeftTypeChange}
          isInvalid={isInvalidActive && !leftType}
          alignItems="left"
          isDisabled={isDone}
        />
        <SearchSelectBox
          className={classNames(styles.searchSelectbox, isDone && styles.done)}
          value={condition.leftVariableId}
          options={leftVariableOptions ?? []}
          onChange={handleLeftVariableChange}
          isDisabled={!leftVariableOptions || isDone}
          placeholder="선택해 주세요."
          isInvalid={isInvalidActive && !leftVariable}
        />
      </div>

      <div className={classNames(styles.row, styles.right)}>
        <SelectBox
          className={classNames(
            styles.selectbox,
            styles.operator,
            styles.solid,
            isDone && styles.done,
          )}
          value={condition.operator}
          options={operatorOptions}
          onChange={handleOperatorChange}
          isInvalid={isInvalidActive && !condition.operator}
          alignItems="left"
          overflowY="scroll"
          isDisabled={isDone}
        />

        {rightInputStyle === InputStyle.STRING && (
          <Input
            className={classNames(styles.input, isDone && styles.done)}
            value={condition.rightValue ?? ''}
            onChange={handleRightInputChanged}
            placeholder="작성해 주세요."
            isInvalid={isInvalidActive && !condition.rightValue}
            isDisabled={isDone}
          />
        )}
        {rightInputStyle === InputStyle.NUMBER && (
          <Input
            className={classNames(styles.input, isDone && styles.done)}
            type="number"
            defaultValue={condition.rightValue}
            onChange={handleRightInputChanged}
            onWheel={preventInputOnWheel}
            placeholder="작성해 주세요."
            isInvalid={isInvalidActive && !condition.rightValue}
            isDisabled={isDone}
          />
        )}
        {rightInputStyle === InputStyle.CHOICE && (
          <SearchSelectBox
            className={classNames(
              styles.searchSelectbox,
              isDone && styles.done,
            )}
            value={condition.rightValue}
            onChange={handleRightSelectChanged}
            options={rightChoiceOptions ?? []}
            placeholder="선택해 주세요."
            isInvalid={isInvalidActive && !condition.rightValue}
            isDisabled={isDone}
          />
        )}
        {rightInputStyle === InputStyle.DATE && (
          <DateInput
            value={condition.rightValue}
            onChange={handleRightDateChanged}
            isFullWidth
            placeholder="날짜를 입력해 주세요."
            isInvalid={isInvalidActive && !condition.rightValue}
            isDisabled={isDone}
          />
        )}
        {rightInputStyle === InputStyle.DISABLED && (
          <SelectBox
            className={classNames(styles.selectbox, isDone && styles.done)}
            value={undefined}
            options={[]}
            placeholder="선택해 주세요."
            isDisabled
            isInvalid={isInvalidActive}
            alignItems="left"
          />
        )}
        {rightInputStyle === InputStyle.BLOCKED && (
          <div
            className={classNames(styles.blocked, isDone && styles.disabled)}
          />
        )}
      </div>

      <button
        className={styles.statement_delete}
        onClick={handleDeleteCondition}
        disabled={isDone}
      >
        <Trash2 size={16} />
      </button>
    </div>
  );
};

export default Condition;
