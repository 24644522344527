import { ReactComponent as ArrowDown } from 'assets/selectbox_arrow.svg';
import classNames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { find, filter } from 'lodash';
import { useDebounce } from 'utils/hooks/useDebounce';

import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Search } from 'react-feather';

import styles from './SearchSelectBox.module.scss';

interface SelectOption<TValue> {
  value?: TValue;
  name: string;
}

interface SearchSelectBoxProps<TValue> {
  value?: TValue;
  onChange?: (value: TValue) => void;
  options: SelectOption<TValue>[];
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
}

const SearchSelectBox = <TValue extends string | number>({
  value,
  onChange,
  options,
  className,
  placeholder = '선택',
  isDisabled,
  isInvalid,
}: SearchSelectBoxProps<TValue>) => {
  const [isOptionOpen, setIsOptionOpen] = useState<boolean>(false);
  const [selectedName, setSelectedName] = useState<string>();
  const [search, setSearch, debouncedSearch] = useDebounce<string>('');
  const [innerSize, setInnerSize] = useState<'small' | 'medium'>('medium');

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOptionOpen(false));
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const filteredOptions = useMemo(() => {
    if (debouncedSearch === '') {
      return options;
    }
    return filter(
      options,
      (option) => option.name.indexOf(debouncedSearch) !== -1,
    );
  }, [options, debouncedSearch]);

  const findNameByValue = useCallback(
    (value: any) => {
      const option = find(options, { value });
      return option?.name;
    },
    [options],
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleSelectedButtonClick = useCallback(() => {
    setIsOptionOpen(!isOptionOpen);
  }, [isOptionOpen]);

  const handleOptionClick = useCallback(
    (option: SelectOption<TValue>) => {
      if (option.value) {
        onChange?.(option.value);
      }
      setSelectedName(option.name);
      setIsOptionOpen(false);
      setSearch('');
    },
    [onChange],
  );

  useLayoutEffect(() => {
    setSelectedName(findNameByValue(value));
  }, [value, options]);

  useLayoutEffect(() => {
    if (buttonRef.current) {
      const width = buttonRef.current.clientWidth;
      if (width < 200) {
        setInnerSize('small');
      }
    }
  }, []);

  return (
    <div ref={ref} className={styles.wrapper}>
      <button
        className={classNames(
          styles.selected,
          className,
          isInvalid && styles.invalid,
        )}
        ref={buttonRef}
        onClick={handleSelectedButtonClick}
        disabled={isDisabled}
      >
        <p
          className={classNames(
            styles.selected_value,
            !selectedName && styles.placeholder,
          )}
        >
          {selectedName ?? placeholder}
        </p>
        <ArrowDown className={styles.selected_arrow} />
      </button>

      <div className={classNames(styles.popover, isOptionOpen && styles.open)}>
        <div
          className={classNames(
            styles.option_search,
            innerSize === 'small' && styles.size_small,
          )}
        >
          <input
            value={search}
            onChange={handleSearchChange}
            placeholder="검색어를 입력하세요."
          />
          <Search className={styles.option_search_icon} size={20} />
        </div>

        <ul className={styles.option}>
          {filteredOptions.map((option) => (
            <li key={option.value}>
              <button
                className={classNames(
                  styles.option_item,
                  innerSize === 'small' && styles.size_small,
                )}
                onClick={() => handleOptionClick(option)}
                onMouseDown={(e) => e.preventDefault()}
              >
                <p>{option.name}</p>
              </button>
            </li>
          ))}
          {filteredOptions.length === 0 && (
            <p
              className={styles.option_not_found}
            >{`"${debouncedSearch}"에 대한 검색결과가 없습니다.`}</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default SearchSelectBox;
