import classNames from 'classnames';
import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';
import NumberInput from 'components/NumberInput/NumberInput';
import RadioButton from 'components/RadioButton/RadioButton';
import { SelectBox } from 'components/SelectBox/SelectBox';
import useHeader from 'utils/hooks/useHeader';

import React, { useRef } from 'react';
import { ArrowDown, ArrowUp } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import styles from './EvaluationResultPage.module.scss';
import useEvaluationResultPage from './useEvaluationResultPage';

const EvaluationPage = () => {
  const navigate = useNavigate();
  const uploadRef = useRef<HTMLInputElement>(null);

  useHeader({ title: '심사결과' });

  const {
    isDownloadModalOpen,
    isEvalAlertOpen,
    isEvalInvalidAlertOpen,
    evalFailAlert,
    isDownloadDataLoading,
    isUploadDataLoading,
    isEvaluateTemplateLoading,
    isNumberNotSelected,
    surveySetOptions,
    selectedTemplate,
    evaluationStandardData,
    selectedNumber,
    downloadDocumentType,
    isDownloadQueryEnabled,
    documentTypeOptions,
    isEvalValid,
    setIsEvalInvalidAlertOpen,
    setEvalFailAlert,
    setIsEvalAlertOpen,
    setIsDownloadModalOpen,
    evaluatingHandler,
    handleTemplateAutoEvaluation,
    handleDownloadEvaluation,
    handleUploadEvaluation,
    handleDownloadFormatRadioChanged,
    handleSelectedTemplateChanged,
    handleSelectedNumberChanged,
  } = useEvaluationResultPage();

  // TODO 서버 선발정원 반영 후 -> console.log 삭제
  console.log(isNumberNotSelected);

  return (
    <>
      <Modal
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      >
        <Modal.Content>
          <Modal.Header>
            <p>심사 시트 다운로드</p>
            <Modal.Header.SubTitle>
              데이터 형식을 선택해주세요.
              <br />
              (업로드를 위해서는 칼럼을 변경하지 말아주세요.)
            </Modal.Header.SubTitle>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.downloadModal_radioGroup}>
              {documentTypeOptions.map((option) => (
                <RadioButton
                  key={option.value}
                  name={option.name}
                  value={option.value}
                  onChange={handleDownloadFormatRadioChanged}
                  isChecked={downloadDocumentType.value === option.value}
                >
                  {option.name}
                </RadioButton>
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleDownloadEvaluation}
              isLoading={isDownloadQueryEnabled && isDownloadDataLoading}
            >
              다운로드
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <Modal isOpen={isEvalAlertOpen} onClose={() => setIsEvalAlertOpen(false)}>
        <Alert>
          <Alert.Title>{`“${selectedTemplate?.title}”의\n 심사를 진행하시겠습니까?`}</Alert.Title>
          <Alert.Footer>
            <Button onClick={handleTemplateAutoEvaluation}>확인</Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <Modal
        isOpen={isEvalInvalidAlertOpen}
        onClose={() => setIsEvalInvalidAlertOpen(false)}
      >
        <Alert>
          <Alert.Title>
            {`유효하지 않은 심사 기준이 있어 \n 심사를 진행할 수 없습니다.`}
            <Alert.Title.Caption>
              심사 기준을 확인하러 가시겠습니까?
            </Alert.Title.Caption>
          </Alert.Title>
          <Alert.Footer>
            <Button
              onClick={() =>
                navigate(
                  `/application/evaluation-standard/${selectedTemplate?.id}`,
                )
              }
            >
              확인
            </Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <Modal
        isOpen={evalFailAlert.open}
        onClose={() => setEvalFailAlert({ open: false })}
      >
        <Alert>
          <Alert.Title>
            {`${evalFailAlert.serverResponse?.request ?? 0}개 중 ${
              evalFailAlert.serverResponse?.fails?.length || 0
            }개의 지원서를 심사할 수 없습니다.`}
            <Alert.Title.Caption>
              <>
                심사 실패한 지원서의 상태는 <strong>불합격</strong>으로
                처리됩니다. <br />
                어드민에서 해당하는 지원서의 상태값을 <br />
                <strong>- (해당 없음)</strong>으로 변경한 후 다시 심사할 수
                있습니다.
              </>
            </Alert.Title.Caption>
          </Alert.Title>

          <div className={styles.eval_failed_applications}>
            <div className={styles.table_head}>UserId</div>
            <div className={styles.table_head}>이름</div>
            <div className={styles.table_head}>실패 사유</div>
            {evalFailAlert.serverResponse?.fails?.map(
              ({ userId, userName, reason }) => (
                <React.Fragment key={userId}>
                  <div>{userId ?? ''}</div>
                  <div>{userName ?? ''}</div>
                  <div>{reason ?? ''}</div>
                </React.Fragment>
              ),
            )}
          </div>

          <Alert.Footer>
            <Button onClick={() => setEvalFailAlert({ open: false })}>
              확인
            </Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <div>
        <div className="content-wrapper">
          <main className={styles.wrapper}>
            <section className={styles.template}>
              <p className={styles.template_title}>질문지 세트</p>

              <div className={styles.template_content}>
                <div className={styles.template_selectBox_wrapper}>
                  <SelectBox
                    className={styles.template_selectBox}
                    value={selectedTemplate?.id}
                    options={surveySetOptions}
                    onChange={handleSelectedTemplateChanged}
                    placeholder={'질문지 세트를 선택해 주세요.'}
                  />
                </div>
                {/* TODO 서버 선발정원 반영 후 -> 아래 주석처리 활성화 및 정리 */}
                <div className={styles.template_evaluation}>
                  <Button
                    className={classNames(
                      styles.template_evaluation_btn,
                      !selectedTemplate?.unannounced && styles.alreadyAnnounced,
                      // isNumberNotSelected && styles.numberNotSelected,
                    )}
                    // TODO disabled 조건에 심사정원이 없을 때 추가 필요
                    isDisabled={!isEvalValid}
                    isLoading={isEvaluateTemplateLoading}
                    onClick={evaluatingHandler}
                  >
                    심사
                  </Button>
                  <div
                    className={classNames(
                      styles.template_evaluation_tooltip,
                      styles.alreadyAnnounced,
                    )}
                  >
                    합격발표일이 지난 상품입니다.
                  </div>
                  {/* <div
                    className={classNames(
                      styles.template_evaluation_tooltip,
                      styles.numberNotSelected,
                    )}
                  >
                    선발정원이 설정되지 않았습니다.
                  </div> */}
                </div>
              </div>
            </section>

            <hr className={styles.divider} />

            <section className={styles.evaluation}>
              <div className={styles.evaluation_setting}>
                <div className={styles.evaluation_setting_item}>
                  <p className={styles.label}>심사대상 지원서</p>
                  <p className={styles.value}>
                    {(evaluationStandardData?.completedApplications ?? 0) -
                      (evaluationStandardData?.evaluatedApplications ?? 0)}
                    /{evaluationStandardData?.completedApplications ?? 0}개
                  </p>
                </div>

                <div className={styles.evaluation_setting_item}>
                  <p className={styles.label}>선발 정원</p>
                  <NumberInput
                    value={Number(selectedNumber)}
                    onChange={handleSelectedNumberChanged}
                    isDisabled={!isEvalValid}
                    unitLabel="명"
                    maxValue={999}
                  />
                </div>
              </div>

              <div className={styles.evaluation_actions}>
                <Button
                  size={'small'}
                  variant={'line-skeleton'}
                  rightIcon={<ArrowDown height={16} />}
                  isDisabled={!selectedTemplate || isUploadDataLoading}
                  onClick={() => setIsDownloadModalOpen(true)}
                >
                  심사 시트 다운로드
                </Button>
                <Button
                  size={'small'}
                  variant={'line-skeleton'}
                  rightIcon={<ArrowUp height={16} />}
                  onClick={() => uploadRef.current?.click()}
                  isDisabled={!selectedTemplate || isUploadDataLoading}
                >
                  <>
                    심사 시트 업로드
                    <input
                      type="file"
                      className={styles.hidden}
                      ref={uploadRef}
                      onChange={(e) => {
                        handleUploadEvaluation(e);
                        e.target.value = '';
                      }}
                    />
                  </>
                </Button>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default EvaluationPage;
