/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LandingPageCurriculumResponse } from './LandingPageCurriculumResponse';

export type ApiResponseListLandingPageCurriculumResponse = {
    message?: string;
    code?: ApiResponseListLandingPageCurriculumResponse.code;
    target?: Record<string, any>;
    exceptionCategory?: ApiResponseListLandingPageCurriculumResponse.exceptionCategory;
    errorType?: ApiResponseListLandingPageCurriculumResponse.errorType;
    body?: Array<LandingPageCurriculumResponse>;
    trackingId?: string;
};

export namespace ApiResponseListLandingPageCurriculumResponse {

    export enum code {
        DUPLICATED_NAME = 'DUPLICATED_NAME',
        NOT_FOUND_ENTITY = 'NOT_FOUND_ENTITY',
        EMPTY_FIELD = 'EMPTY_FIELD',
        BAD_VALUE = 'BAD_VALUE',
        NOT_FOUND_DOMAIN = 'NOT_FOUND_DOMAIN',
        UNSUPPORTED_DELETION = 'UNSUPPORTED_DELETION',
        EMPTY_VARIABLE_NAME = 'EMPTY_VARIABLE_NAME',
        BAD_REQUEST = 'BAD_REQUEST',
        NOT_SUPPORTED = 'NOT_SUPPORTED',
        EXPIRED_REQUEST = 'EXPIRED_REQUEST',
    }

    export enum exceptionCategory {
        AUTHENTICATION = 'AUTHENTICATION',
        TEMPLATE = 'TEMPLATE',
        PRODUCT = 'PRODUCT',
        APPLICATION = 'APPLICATION',
        STANDARD = 'STANDARD',
        EVALUATION = 'EVALUATION',
        EVALUATION_FILE = 'EVALUATION_FILE',
        ITEM = 'ITEM',
        ANSWER = 'ANSWER',
        ANSWER_STANDARD = 'ANSWER_STANDARD',
        ANSWER_EVALUATION = 'ANSWER_EVALUATION',
        SURVEY = 'SURVEY',
        VARIABLE = 'VARIABLE',
        USER = 'USER',
        ETC = 'ETC',
        ALL = 'ALL',
    }

    export enum errorType {
        GET = 'GET',
        COPY = 'COPY',
        CREATE = 'CREATE',
        MODIFY = 'MODIFY',
        EVALUATION = 'EVALUATION',
        DELETE = 'DELETE',
        VALIDATE = 'VALIDATE',
    }


}

