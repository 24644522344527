import { DropdownSearchBox } from 'components/DropdownSearchBox/DropdownSearchBox';

import styles from '../SurveySetEditorPage.module.scss';

import type { SurveyResponse, TemplateItemFullResponse } from 'generated';

interface SurveyAddModalProps {
  input: string;
  handleOptionSelected: (survey: SurveyResponse) => void;
  handleInputChanged: (value: string) => void;
  setInput: (value: string) => void;
  surveyListData?: SurveyResponse[];
  surveyRepo: (SurveyResponse | undefined)[];
  deletedSurveys: SurveyResponse[];
  surveyListLoading: boolean;
  isSurveySelected: boolean;
}

const SurveyAddModal = ({
  input,
  handleOptionSelected,
  surveyListData,
  surveyRepo,
  deletedSurveys,
  handleInputChanged,
  setInput,
  surveyListLoading,
  isSurveySelected,
}: SurveyAddModalProps) => {
  if (!surveyListData) {
    return <></>;
  }

  const unusedSurveyList = surveyListData?.filter(
    (survey: TemplateItemFullResponse) => !survey.used,
  );
  const filterAlreadyAddedSurvey = (surveyList: SurveyResponse[]) => {
    if (!surveyList.length) {
      return [];
    }
    return surveyList.filter(
      (survey: SurveyResponse) =>
        !surveyRepo.some((item) => item?.id === survey.id),
    );
  };
  const addDeletedSurvey = (surveyList: SurveyResponse[]) => [
    ...surveyList,
    ...deletedSurveys,
  ];
  const deleteDuplicatedSurvey = (surveyList: SurveyResponse[]) => {
    const onlySurveyId = surveyList.map((survey) => survey.id);
    return surveyList.filter(
      (survey, index) => onlySurveyId.indexOf(survey.id) === index,
    );
  };

  return (
    <DropdownSearchBox
      className={styles.survey_searchbox}
      value={input}
      setValue={setInput}
      onChange={handleInputChanged}
      onSelect={handleOptionSelected}
      options={filterAlreadyAddedSurvey(
        deleteDuplicatedSurvey(addDeletedSurvey(unusedSurveyList)),
      )}
      placeholder="질문지 명을 입력해주세요."
      isOptionLoading={surveyListLoading}
      isOptionSelected={isSurveySelected}
      propertyForOptionTitle="title"
    />
  );
};

export default SurveyAddModal;
