import { ConditionResponse, ExecutionResponse, LogicResponse } from 'generated';

import {
  ExecutionType,
  ConditionLeftType,
  ExecutionArithmeticVarType,
} from './type';

export const logicTypeOptions: { value: LogicResponse.type; name: string }[] = [
  {
    value: LogicResponse.type.ALWAYS,
    name: 'Always',
  },
  {
    value: LogicResponse.type.IFTHEN,
    name: 'If',
  },
];

export const executionTypeOptions: { value: ExecutionType; name: string }[] = [
  {
    value: ExecutionType.ARITHMETIC,
    name: '사칙연산',
  },
  {
    value: ExecutionType.TEXT_REPLACEMENT,
    name: '값 변경',
  },
];

export const executionRightVarTypeOptions: {
  value: ExecutionArithmeticVarType;
  name: string;
}[] = [
  {
    value: ExecutionArithmeticVarType.ANSWER,
    name: '답변',
  },
  {
    value: ExecutionArithmeticVarType.VARIABLE,
    name: '변수',
  },
  {
    value: ExecutionArithmeticVarType.INPUT,
    name: '숫자',
  },
];

type executionArithmeticOperator = Exclude<
  ExecutionResponse.operator,
  ExecutionResponse.operator.ASSIGN
>;

export const executionOperatorOptions: {
  value: executionArithmeticOperator;
  name: string;
}[] = [
  {
    value: ExecutionResponse.operator.PLUS,
    name: '+',
  },
  {
    value: ExecutionResponse.operator.MINUS,
    name: '-',
  },
  {
    value: ExecutionResponse.operator.TIMES,
    name: '×',
  },
  {
    value: ExecutionResponse.operator.DIVIDEDBY,
    name: '÷',
  },
];

export const executionOperatorAltText: Record<
  executionArithmeticOperator,
  { subjectJosa: string; objectJosa: string; verb: string }
> = {
  [ExecutionResponse.operator.PLUS]: {
    subjectJosa: '에',
    objectJosa: '을',
    verb: '더한다',
  },
  [ExecutionResponse.operator.MINUS]: {
    subjectJosa: '에서',
    objectJosa: '을',
    verb: '뺀다',
  },
  [ExecutionResponse.operator.TIMES]: {
    subjectJosa: '에',
    objectJosa: '을',
    verb: '곱한다',
  },
  [ExecutionResponse.operator.DIVIDEDBY]: {
    subjectJosa: '을',
    objectJosa: '로',
    verb: '나눈다',
  },
};

export const conditionLeftTypeOptions: {
  value: ConditionLeftType;
  name: string;
}[] = [
  {
    value: ConditionLeftType.VARIABLE,
    name: '변수',
  },
  {
    value: ConditionLeftType.ANSWER,
    name: '답변',
  },
];

export const conditionOperatorBasicOptions: {
  value: ConditionResponse.operator;
  name: string;
}[] = [
  {
    value: ConditionResponse.operator.EQUAL,
    name: '=',
  },
  {
    value: ConditionResponse.operator.IN_EQUAL,
    name: '≠',
  },
];

export const conditionOperatorArithmeticOptions: {
  value: ConditionResponse.operator;
  name: string;
}[] = [
  {
    value: ConditionResponse.operator.GREATER_THEN,
    name: '>',
  },
  {
    value: ConditionResponse.operator.GREATER_EQUAL,
    name: '≥',
  },
  {
    value: ConditionResponse.operator.LESS_THEN,
    name: '<',
  },
  {
    value: ConditionResponse.operator.LESS_EQUAL,
    name: '≤',
  },
];

export const conditionOperatorChoiceOptions: {
  value: ConditionResponse.operator;
  name: string;
}[] = [
  {
    value: ConditionResponse.operator.IN,
    name: '포함',
  },
  {
    value: ConditionResponse.operator.NOT_IN,
    name: '제외',
  },
];

export const conditionOperatorAnswerOptions: {
  value: ConditionResponse.operator;
  name: string;
}[] = [
  {
    value: ConditionResponse.operator.IS_EMPTY,
    name: '미응답',
  },
  {
    value: ConditionResponse.operator.IS_NOT_EMPTY,
    name: '응답',
  },
];

export const conditionLogicGateOptions: {
  value: ConditionResponse.logicGate;
  name: string;
}[] = [
  {
    value: ConditionResponse.logicGate.AND,
    name: 'and',
  },
  {
    value: ConditionResponse.logicGate.OR,
    name: 'or',
  },
];
