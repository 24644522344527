/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ExecutionUpsertRequest = {
    id?: number;
    sequence?: number;
    leftVariableId?: number;
    operator?: ExecutionUpsertRequest.operator;
    rightVariableId?: number;
    rightValue?: string;
};

export namespace ExecutionUpsertRequest {

    export enum operator {
        PLUS = 'PLUS',
        MINUS = 'MINUS',
        TIMES = 'TIMES',
        DIVIDEDBY = 'DIVIDEDBY',
        ASSIGN = 'ASSIGN',
    }


}

