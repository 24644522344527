/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  DTLAB_IMAGE_UPLOADER_API_KEY,
  DTLAB_IMAGE_UPLOADER_API_URL,
} from 'constants/env';

import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal';
import { FunctionFactory } from 'survey-core';
import { SurveyCreator } from 'survey-creator-react';
import useToast from 'utils/hooks/useToast';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import SurveyCreatorHeader from './Header/SurveyCreatorHeader';
import EditTitleModal from './Modal/EditTitleModal';
import styles from './SurveyCreatorPage.module.scss';
import SurveyCreatorWidget from './SurveyCreatorWidget';
import { getSurveyRequest, updateSurveyRequest } from './requests';
import queryKeys from '../../query/queryKeys';

import type { SurveyResponse } from '../../generated';
import type { AxiosError, AxiosResponse } from 'axios';
import type { ItemValue } from 'survey-core';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: false,
};

function mathRound(params: number[]) {
  return Math.round(params[0]);
}
FunctionFactory.Instance.register('mathRound', mathRound);

const SurveyCreatorPage = () => {
  const { id } = useParams();
  const surveyId = Number(id);

  const newCreator = new SurveyCreator(creatorOptions);
  newCreator.haveCommercialLicense = true;
  newCreator.onUploadFile.add((_, options) => {
    const formData = new FormData();

    options.files.forEach((file: File) => {
      formData.append(file.name, file);
    });

    fetch(DTLAB_IMAGE_UPLOADER_API_URL, {
      method: 'POST',
      body: formData,
      headers: {
        'x-api-key': DTLAB_IMAGE_UPLOADER_API_KEY,
      },
    })
      .then((response) => response.json())
      .then((data) => options.callback('success', data.url))
      .catch(() => options.callback('error', 'error'));
  });

  // state
  const [creator] = useState<SurveyCreator>(newCreator);
  const [survey, setSurvey] = useState<SurveyResponse>();
  const [isTitleModalOpen, setIsTitleModalOpen] = useState(false);
  const [isCheckTemporaryStorage, setIsCheckTemporaryStorage] = useState(false);

  const [isLeaveAlertOpen, setIsLeaveAlertOpen] = useState(false);
  const [isBlockModAlertOpen, setIsBlockModAlertOpen] = useState(false);
  const [isTempStoreAlertOpen, setIsTempStoreAlertOpen] = useState(false);

  const { showToast, toastType } = useToast();
  const navigate = useNavigate();

  const {
    data: getSurveyData,
    isLoading: isGetSurveyDataLoading,
    isError: isGetSurveyDataError,
  } = useQuery(queryKeys.SURVEY_DETAIL_BY_ID(surveyId), () =>
    getSurveyRequest(surveyId),
  );

  const { mutate: updateSurvey, error: updateSurveyError } = useMutation<
    AxiosResponse,
    AxiosError,
    SurveyResponse,
    unknown
  >((request) => updateSurveyRequest(surveyId, request));

  useEffect(() => {
    if (getSurveyData) {
      setSurvey(getSurveyData);
      creator.text = JSON.stringify(getSurveyData.definition);
    }
  }, [getSurveyData]);

  useEffect(() => {
    if (survey && !isCheckTemporaryStorage) {
      checkTemporaryStorageExist();
    }
  }, [survey]);

  const closeWindow = (status: string) => {
    window.localStorage.removeItem(`survey-${surveyId}`);
    navigate('/survey', {
      state: {
        status,
      },
    });
  };

  const saveSurveyHandler = () => {
    const definition = JSON.parse(creator.text);
    const columns = generateColumns(creator);

    updateSurvey(
      {
        ...survey,
        definition,
        columns,
      },
      {
        onSuccess: () => {
          closeWindow('onSaved');
        },
      },
    );
  };

  const cancelSurveyHandler = () => {
    setIsLeaveAlertOpen(true);
  };

  const openEditTitleHandler = () => {
    if (survey?.used) {
      showToast({
        message: '지원이 시작되면 질문지 수정이 안됩니다.',
        type: toastType.negative,
      });
    } else {
      setIsTitleModalOpen(true);
    }
  };

  const saveTitle = (newTitle: string) => {
    updateSurvey(
      { ...survey, title: newTitle },
      {
        onSuccess: () => {
          setSurvey({ ...survey, title: newTitle });
          setIsTitleModalOpen(false);
        },
      },
    );
  };

  const blockModificationHandler = () => {
    setIsBlockModAlertOpen(true);
  };

  const checkTemporaryStorageExist = () => {
    const definitionItem = window.localStorage.getItem(`survey-${surveyId}`);
    if (!definitionItem) {
      setIsCheckTemporaryStorage(true);
      return;
    }
    setIsTempStoreAlertOpen(true);
  };

  const generateColumns = (creator: SurveyCreator) => {
    const blockedQuestionType = ['html', 'image'];
    const selectQuestionType = ['radiogroup', 'checkbox', 'dropdown'];
    const textQuestionType = 'text';

    const questions = creator.surveyValue
      .getAllQuestions()
      .filter((question) => !blockedQuestionType.includes(question.getType()));

    const convertedQuestionEntries = questions.map((question) => {
      const type = question.getType();
      let choices;
      let inputType;
      if (selectQuestionType.includes(type)) {
        choices = question.choices.map((choice: ItemValue) => choice.value);
      }
      if (type === textQuestionType) {
        inputType = question.inputType;
      }
      return [
        question.name,
        {
          label: question.title,
          type,
          choices,
          inputType,
        },
      ];
    });

    return Object.fromEntries(convertedQuestionEntries);
  };

  return (
    <div>
      {survey?.title && (
        <EditTitleModal
          initialTitle={survey.title}
          isOpen={isTitleModalOpen}
          onClose={() => setIsTitleModalOpen(false)}
          onSaved={saveTitle}
          updateError={updateSurveyError}
        />
      )}

      <Modal
        isOpen={isLeaveAlertOpen}
        onClose={() => setIsLeaveAlertOpen(false)}
      >
        <Alert>
          <Alert.Title>
            <p>
              이 페이지를 벗어나면
              <br />
              변경된 내용은 저장하지 않습니다.
            </p>
          </Alert.Title>
          <Alert.Footer>
            <Button onClick={() => closeWindow('onCancelled')}>
              페이지 나가기
            </Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <Modal
        isOpen={isBlockModAlertOpen}
        onClose={() => setIsBlockModAlertOpen(false)}
      >
        <Alert>
          <Alert.Title>
            <p>
              사용중인 질문지는
              <br />
              문구 수정만 가능합니다.
            </p>
          </Alert.Title>
          <Alert.Footer>
            <Button onClick={() => setIsBlockModAlertOpen(false)}>확인</Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <Modal
        isOpen={isTempStoreAlertOpen}
        onClose={() => {
          setIsCheckTemporaryStorage(true);
          setIsTempStoreAlertOpen(false);
        }}
      >
        <Alert>
          <Alert.Title>
            <p>
              작성중이던 질문지가 있습니다.
              <br />
              이어서 작성하시겠습니까?
            </p>
          </Alert.Title>
          <Alert.Footer>
            <Button
              onClick={() => {
                const definitionItem = window.localStorage.getItem(
                  `survey-${surveyId}`,
                );
                if (!definitionItem) {
                  return;
                }

                creator.text = definitionItem;
                setIsTempStoreAlertOpen(false);
                setIsCheckTemporaryStorage(true);
              }}
            >
              확인
            </Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <div className={styles.surveycreator}>
        <SurveyCreatorHeader
          title={survey?.title}
          onSave={saveSurveyHandler}
          onCancel={cancelSurveyHandler}
          onEditTitle={openEditTitleHandler}
        />

        <div className={styles.embedded_wrapper}>
          {isGetSurveyDataLoading ? (
            <Loading />
          ) : isGetSurveyDataError ? (
            <Error isFull />
          ) : (
            <SurveyCreatorWidget
              creator={creator}
              surveyId={surveyId}
              isUsed={!!survey?.used}
              isTimerStarted={isCheckTemporaryStorage}
              onBlockedModification={blockModificationHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SurveyCreatorPage;
