/* eslint-disable promise/prefer-await-to-callbacks */
import React, { useCallback, useMemo } from 'react';

import { getUserInfoFromToken, TOKEN_KEY } from './jwtTokenParser';

import type { UserJwtPayload } from './jwtTokenParser';

export type Maybe<T> = T | null;

export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};

type UserInfo = MakeMaybe<UserJwtPayload, 'name' | 'email' | 'role'>;

interface AuthContext {
  isAuthenticated: boolean;
  user: UserInfo | null;
  signin: (authToken: string, callback?: VoidFunction) => void;
  signout: (callback?: VoidFunction) => void;
}

const authProvider: AuthContext = {
  isAuthenticated: false,
  user: null,
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  signin(authToken: string, callback?: VoidFunction) {
    localStorage.setItem(TOKEN_KEY, authToken);
    authProvider.isAuthenticated = true;
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    callback?.();
  },
  // eslint-disable-next-line promise/prefer-await-to-callbacks
  signout(callback?: VoidFunction) {
    localStorage.removeItem(TOKEN_KEY);
    authProvider.isAuthenticated = false;
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    callback?.();
  },
};

const Context = React.createContext<AuthContext>(authProvider);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setAuthenticated] = React.useState<boolean>(() => {
    const token = localStorage.getItem(TOKEN_KEY) ?? '';
    const user = getUserInfoFromToken(token);

    if (user) {
      authProvider.user = user;
      authProvider.isAuthenticated = true;
      return true;
    }
    return false;
  });

  const signin = useCallback(
    (authToken: string, callback?: VoidFunction) =>
      authProvider.signin(authToken, () => {
        setAuthenticated(true);
        callback?.();
      }),
    [],
  );

  const signout = useCallback(
    (callback?: VoidFunction) =>
      authProvider.signout(() => {
        setAuthenticated(false);
        callback?.();
      }),
    [],
  );

  const value = useMemo(
    () => ({
      isAuthenticated,
      user: authProvider.user,
      signin,
      signout,
    }),
    [isAuthenticated, signout, signin],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export { Context as AuthContext, AuthProvider };
