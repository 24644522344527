import { type LogicResponse, type LogicUpsertRequest } from 'generated';
import queryAxios from 'query/queryAxios';

export const getLogics = async (itemId: number) =>
  queryAxios()
    .get<LogicResponse[]>(`/template-items/${itemId}/standards`)
    .then((res) => res.data);

export const postLogics = async (
  itemId: number,
  payload: LogicUpsertRequest[],
) => queryAxios().post(`template-items/${itemId}/standards`, payload);
