/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ChakraProvider } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material';

import { LoadingStateProvider } from './contexts/loadingState';
import { AuthProvider } from './hooks/useAuth';
import { queryClient } from './query/client';
import reportWebVitals from './reportWebVitals';
import { router } from './routes';
import { theme } from './styles/theme';

const helmetContext = {};
const muiTheme = createTheme();

const root = createRoot(document.getElementById('root')!);
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ThemeProvider theme={muiTheme}>
        <QueryClientProvider client={queryClient}>
          <LoadingStateProvider>
            <HelmetProvider context={helmetContext}>
              <AuthProvider>
                <RouterProvider router={router} />
              </AuthProvider>
            </HelmetProvider>
          </LoadingStateProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ThemeProvider>
    </ChakraProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
