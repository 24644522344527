import { ExecutionResponse } from 'generated';
import { changeJosa } from 'utils/isEndWithConstant';

import { executionOperatorAltText } from './data';
import { ExecutionArithmeticVarType } from './type';

export const generateArithmeticText = (
  leftValue?: any,
  rightType?: ExecutionArithmeticVarType,
  rightValue?: any,
  operator?: ExecutionResponse.operator,
) => {
  if (!leftValue || !rightType || !rightValue || !operator) {
    return;
  }
  if (operator === ExecutionResponse.operator.ASSIGN) {
    return;
  }

  const { subjectJosa, objectJosa, verb } = executionOperatorAltText[operator];

  if (rightType === ExecutionArithmeticVarType.ANSWER) {
    const changedSubjectJosa = changeJosa(leftValue, subjectJosa);
    const changedObjectJosa = changeJosa('응답값', objectJosa);
    return `“${leftValue}”${changedSubjectJosa} 응답값${changedObjectJosa} ${verb}.`;
  }

  if (rightType === ExecutionArithmeticVarType.INPUT) {
    const changedSubjectJosa = changeJosa(leftValue, subjectJosa);
    const changedObjectJosa = changeJosa(rightValue, objectJosa);
    return `“${leftValue}”${changedSubjectJosa} “${rightValue}”${changedObjectJosa} ${verb}`;
  }

  const changedSubjectJosa = changeJosa(leftValue, subjectJosa);
  const changedObjectJosa = changeJosa(rightValue, objectJosa);
  return `“${leftValue}”${changedSubjectJosa} “${rightValue}”${changedObjectJosa} ${verb}.`;
};
