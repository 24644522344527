import axios, { AxiosError } from 'axios';

import { useEffect, useState } from 'react';
import { LogOut } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import styles from './LoginPage.module.scss';
import { ReactComponent as Logo } from '../../assets/adm-logo.svg';
import Input from '../../components/Input/Input';
import { API_ENDPOINT } from '../../constants/env';
import { useAuth } from '../../hooks/useAuth';
import useToast from '../../utils/hooks/useToast';

const googleAuthInfo = {
  response_type: 'code',
  scope: 'profile email',
  redirect_uri: `${window.location.origin}/auth?social_provider=google`,
  client_id:
    '299067943935-947ma6gee34o6orkvian0b8dkfcl2r3n.apps.googleusercontent.com',
};

const googleAuthLink = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${googleAuthInfo.response_type}&scope=${googleAuthInfo.scope}&redirect_uri=${googleAuthInfo.redirect_uri}&propmt=consent&client_id=${googleAuthInfo.client_id}`;

const LoginPage = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const { showToast, toastType } = useToast();

  useEffect(() => {
    if (auth.isAuthenticated) {
      navigate('/', { replace: true });
    }
  }, [auth.isAuthenticated]);

  const emailLogin = async () => {
    try {
      const token = await axios
        .post(`${API_ENDPOINT}auth/email`, {
          email: id,
          password,
        })
        .then(({ data }) => {
          const { token } = data;
          if (!token) {
            throw new AxiosError('token이 없습니다.');
          }
          return token;
        });
      auth.signin(token);
      navigate('/', { replace: true });
    } catch (err) {
      const error = err as AxiosError;
      showToast({ message: `${error.message}`, type: toastType.negative });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.modal_wrapper}>
        <Logo width={180} height={36.79} className={styles.logo} />
        <div className={styles.modal_contents}>
          <p className={styles.title}>
            어서오세요!<span>{`\n지원선발`}</span> 어드민입니다.
          </p>
        </div>
        <div className={styles.login_input}>
          <div>
            <Input
              placeholder="ID (Email) 을 입력하세요"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
              }}
            />
          </div>
          <div>
            <Input
              placeholder="비밀번호를 입력하세요"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              type={'password'}
            />
          </div>
        </div>
        <div>
          <button className={styles.button_normal} onClick={emailLogin}>
            <p>로그인</p>
          </button>
        </div>
        <div>
          <a href={googleAuthLink}>
            <button className={styles.button_google}>
              <LogOut size={22} color="#ffffff" />
              <p>구글 계정으로 로그인</p>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
