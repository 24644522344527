/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ApplicationCreateRequest = {
    templateId?: number;
    paymentModel: ApplicationCreateRequest.paymentModel;
};

export namespace ApplicationCreateRequest {

    export enum paymentModel {
        UPFRONT = 'UPFRONT',
        WEWIN = 'WEWIN',
        GOV_FUNDING = 'GOV_FUNDING',
        B2B = 'B2B',
        IMPACT_CAMPUS = 'IMPACT_CAMPUS',
        HYBRID = 'HYBRID',
        SCHOLARSHIP = 'SCHOLARSHIP',
    }


}

