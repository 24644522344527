// https://www.notion.so/codestates/0979d50fd8244ae59bc6c485ebd53d2b

export const ErrorCode = {
  EMPTY_FIELD: 'EMPTY_FIELD',
  DUPLICATED_NAME: 'DUPLICATED_NAME',
} as const;

export const ErrorType = {
  GET: 'GET',
  COPY: 'COPY',
  CREATE: 'CREATE',
  MODIFY: 'MODIFY',
  EVALUATEION: 'EVALUATION',
  DELETE: 'DELETE',
} as const;

export const ExceptionCategoryType = {
  TEMPLATE: 'TEMPLATE',
  PRODUCT: 'PRODUCT',
  APPLICATION: 'APPLICATION',
  STANDARD: 'STANDARD',
  EVALUATION: 'EVALUATION',
  ITEM: 'ITEM',
  ANSWER: 'ANSWER',
  ANSWER_STANDARD: 'ANSWER_STANDARD',
  ANSWER_EVALUATION: 'ANSWER_EVALUATION',
  SURVEY: 'SURVEY',
  VARIABLE: 'VARIABLE',
  USER: 'USER',
  ETC: 'ETC',
} as const;

export interface ErrorConfigType {
  message: string;
  code: keyof typeof ErrorCode;
  target?: string | null;
  exceptionCategory: keyof typeof ExceptionCategoryType;
  errorType: keyof typeof ErrorType;
  tarckingId?: string;
}
