import { TITLE_TYPING_LIMIT } from 'constants/common';

import { Button } from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal';
import { useDebounce } from 'utils/hooks/useDebounce';

import { useEffect, useState } from 'react';

import styles from './EditTitleModal.module.scss';

import type { AxiosError } from 'axios';

interface Props {
  initialTitle: string;
  isOpen: boolean;
  onClose: () => void;
  onSaved: (newTitle: string) => void;
  updateError: AxiosError | null;
}

const EditTitleModal = ({
  initialTitle,
  isOpen,
  onClose,
  onSaved,
  updateError,
}: Props) => {
  const [title, setTitle, titleDebounceQuery] = useDebounce(initialTitle);
  const [err, setErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleSaveClicked = () => {
    onSaved(title);
  };

  useEffect(() => {
    if (updateError) {
      // TODO: Error message 따라 alert message 설정
      setAlertMessage('중복된 질문지 제목은 입력할 수 없습니다.');
      setErr(true);
    }
  }, [updateError]);

  useEffect(() => {
    setErr(false);
    if (!titleDebounceQuery) {
      setAlertMessage('질문지 제목을 입력해주세요');
    } else if (titleDebounceQuery.length > TITLE_TYPING_LIMIT) {
      setAlertMessage(`${TITLE_TYPING_LIMIT}자까지만 입력할 수 있습니다.`);
    } else {
      setAlertMessage('');
    }
  }, [titleDebounceQuery]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Header>질문지 제목 수정</Modal.Header>
        <Modal.Body>
          <div className={styles.modal_wrapper}>
            <Input
              placeholder="질문지 제목을 입력해주세요"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <div className={styles.nontitle_alert}>{alertMessage}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleSaveClicked}
            isDisabled={
              title.length > TITLE_TYPING_LIMIT || title.length < 1 || err
            }
          >
            수정하기
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default EditTitleModal;
