import classNames from 'classnames';
import Input from 'components/Input/Input';

import { Search } from 'react-feather';

import styles from './SearchBox.module.scss';

import type { InputProps } from 'components/Input/Input';

interface SearchBoxProps extends InputProps {
  handleSearch?: () => void;
  disabled?: boolean;
  variant?: string;
}

const SearchBox = ({ className, ...rest }: SearchBoxProps) => (
  <Input
    className={classNames(className, styles.searchBox)}
    size="small"
    focusStyle="border"
    rightIcon={<Search width={15} height={15} />}
    {...rest}
  />
);

export default SearchBox;
