/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Choice } from './Choice';

export type VariableUpsertRequest = {
    id?: number;
    name: string;
    valueType: VariableUpsertRequest.valueType;
    defaultValue?: string;
    choices?: Array<Choice>;
};

export namespace VariableUpsertRequest {

    export enum valueType {
        STRING = 'STRING',
        NUMBER = 'NUMBER',
        BOOLEAN = 'BOOLEAN',
        DATE = 'DATE',
        CHOICE = 'CHOICE',
        LIST = 'LIST',
    }


}

