/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProductResponse = {
    id?: number;
    uuid?: string;
    name?: string;
    programName?: string;
    academy?: ProductResponse.academy;
    applyStartAt?: string;
    applyEndAt?: string;
    applystartAt?: string;
    applyendAt?: string;
    announcementDate?: string;
};

export namespace ProductResponse {

    export enum academy {
        BOOTCAMP = 'BOOTCAMP',
        B2B = 'B2B',
        WEWIN = 'WEWIN',
    }


}

