import React from 'react';
import { Check } from 'react-feather';

import styles from './Checkbox.module.scss';

export interface CheckboxDefaultProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface CheckboxOptions {
  isChecked?: boolean;
  isDisabled?: boolean;
  isInvalid?: boolean;
}

export interface CheckboxThemingProps {
  size?: 'small' | 'medium' | 'large';
}

export interface CheckboxProps
  extends CheckboxDefaultProps,
    CheckboxOptions,
    CheckboxThemingProps {}

const Checkbox = (props: CheckboxProps) => {
  const {
    onChange,
    isChecked = false,
    isDisabled = false,
    isInvalid = false,
    size = 'medium',
  } = props;

  return (
    <label
      className={[
        styles.checkbox,
        styles[size],
        isChecked && styles.checked,
        isDisabled && styles.disabled,
        isInvalid && styles.invalid,
      ]
        .filter(Boolean)
        .join(' ')}
    >
      <input
        type="checkbox"
        onChange={onChange}
        checked={isChecked}
        disabled={isDisabled}
      />
      <Check size="100%" />
    </label>
  );
};

export default Checkbox;
