import PageFooter from 'components/PageFooter/PageFooter';
import { omit, some } from 'lodash';
import useHeader from 'utils/hooks/useHeader';
import useToast from 'utils/hooks/useToast';
import { scrollToBottom } from 'utils/scrollToBottom';

import { useState, useCallback } from 'react';
import { flushSync } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './EvaluationLogicPage.module.scss';
import EmptyView from './components/EmptyView';
import LeavePageAlert from './components/LeavePageAlert';
import LogicsView from './components/LogicsView';
import ResetLogicsAlert from './components/ResetLogicsAlert';
import { useLogics, useVariables, useLogicsActions } from './store';
import useEvaluationLogicPage from './useEvaluationLogicPage';

import type { Logic } from './store';

const EvaluationLogicPage = () => {
  const templateId = Number(useParams().templateId);
  const itemId = Number(useParams().itemId);
  const { showToast, toastType } = useToast();

  const { beforeAnnounce, surveyTitle, mutateLogics, resetLogics, isDirty } =
    useEvaluationLogicPage({
      templateId,
      itemId,
    });
  const logics = useLogics();
  const variables = useVariables();
  const { addLogic, updateLogicIsValid } = useLogicsActions();
  const [showAlertResetLogics, setShowAlertResetLogics] = useState(false);

  const navigate = useNavigate();

  useHeader({
    title: '질문지 심사 기준 설정',
    pageInfo: [
      { page: '심사 기준 관리', onClick: () => navigate(-1) },
      { page: '질문지 심사 기준 설정' },
    ],
  });

  const handleSaveLogics = useCallback(
    ({ itemId, logics }: { itemId: number; logics: Logic[] }) => {
      if (!beforeAnnounce || !itemId) {
        return;
      }

      if (some(logics, { isValid: false })) {
        showToast({
          message: '완성되지 않은 규칙이 있습니다.',
          type: toastType.negative,
        });
        return;
      }

      const sanitizedLogics = logics.map((logic) =>
        omit(logic, ['isValid', 'clientId']),
      );
      mutateLogics({ itemId, payload: sanitizedLogics });
    },
    [beforeAnnounce],
  );

  return (
    <div className={styles.evaluation_logic_page}>
      {logics.length === 0 ? (
        <EmptyView className={styles.empty_view} />
      ) : (
        <LogicsView
          className={styles.logics_view}
          beforeAnnounce={beforeAnnounce}
        />
      )}

      <PageFooter
        variant="규칙"
        title={surveyTitle}
        count={logics.length}
        onSave={() => {
          if (!isDirty) {
            return;
          }
          const validatedLogics = updateLogicIsValid(variables);
          handleSaveLogics({ itemId, logics: validatedLogics });
        }}
        onAdd={() => {
          flushSync(() => addLogic());
          scrollToBottom();
        }}
        onInit={() => isDirty && setShowAlertResetLogics(true)}
        beforeAnnounce={beforeAnnounce}
      />

      <ResetLogicsAlert
        isOpen={showAlertResetLogics}
        onConfirm={() => {
          resetLogics();
          setShowAlertResetLogics(false);
        }}
        onDismiss={() => setShowAlertResetLogics(false)}
      />
      <LeavePageAlert isDirty={isDirty} />
    </div>
  );
};

export default EvaluationLogicPage;
