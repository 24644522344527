/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IssueTokenRequest = {
    authType?: IssueTokenRequest.authType;
    code?: string;
    redirectURI?: string;
};

export namespace IssueTokenRequest {

    export enum authType {
        GOOGLE = 'GOOGLE',
        EMAIL = 'EMAIL',
    }


}

