import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal';
import { Pagination } from 'generated';
import { isNil } from 'lodash-es';
import queryKeys from 'query/queryKeys';
import { formatDateTime } from 'utils/datetimeFormatter';
import { useAlert } from 'utils/hooks/useAlert';
import { useDebounce } from 'utils/hooks/useDebounce';
import useHeader from 'utils/hooks/useHeader';
import useToast from 'utils/hooks/useToast';

import React, { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import styles from './SurveySetEditorPage.module.scss';
import BasicInfoSection from './components/BasicInfoSection/BasicInfoSection';
import ProductSection from './components/ProductSection/ProductSection';
import SurveyAddModal from './components/SurveyAddModal';
import SurveySection from './components/SurveySection/SurveySection';
import SurveySettingModal from './components/SurveySettingModal';
import {
  createSurveySetRequest,
  getSurveySetRequest,
  getSurveysRequest,
  updateSurveySetRequest,
} from './request';
import PreviewSurveyModal from '../SurveyPage/Modal/PreviewSurveyModal/PreviewSurveyModal';
import { updateSurveyOnDetailRequest } from '../SurveySetPage/request';

import type { PreviewSurveyState } from '../SurveyPage/Modal/PreviewSurveyModal/PreviewSurveyModal';
import type { AxiosError, AxiosResponse } from 'axios';
import type { SurveyChangeTestRequest, SurveyResponse } from 'generated';
import type { TemplateUpsertRequest } from 'generated/models/TemplateUpsertRequest';

interface SurveySettingState {
  selectedSurvey?: SurveyResponse;
  isModalOpen: boolean;
  timeRequired?: number;
  displayTitle: string;
  isTimeValidationFailed: boolean;
  isTitleValidationFailed: boolean;
}

interface SurveyRepoType extends SurveyResponse {
  itemId?: number;
}
interface SurveyListState {
  title: string;
  isAddModalOpen: boolean;
  isSurveyBeingReplaced: boolean;
  surveyBeingReplaced?: SurveyResponse;
  surveyRepo: (SurveyRepoType | undefined)[];
  deletedSurveys: SurveyResponse[];
  selectedSurvey?: SurveyResponse;
}
export interface SlugUrl {
  course: string;
  cohort: string;
}

const DISPLAY_TITLE_TYPING_LIMIT = 31;

const SurveySetEditorPage = () => {
  const { id } = useParams();
  const isNew = isNil(id);
  const navigate = useNavigate();
  const { showToast, toastType } = useToast();
  const { isAlertOpen, setIsAlertOpen, alertContent, setAlertContent } =
    useAlert();

  const [titleInput, setTitleInput, debouncedTitleInput] = useDebounce('', 200);
  const [surveySet, setSurveySet] = useState<SurveyListState>({
    title: '',
    surveyRepo: [],
    isAddModalOpen: false,
    isSurveyBeingReplaced: false,
    deletedSurveys: [],
  });
  const [slugUrl, setSlugUrl] = useState<SlugUrl>({ course: '', cohort: '' });
  const [previewSurvey, setPreviewSurvey] = useState<PreviewSurveyState>({
    surveyId: undefined,
    isOpen: false,
  });
  const [surveySetting, setSurveySetting] = useState<SurveySettingState>({
    isModalOpen: false,
    displayTitle: '',
    isTimeValidationFailed: false,
    isTitleValidationFailed: false,
  });
  const [surveysForDropdown, setSurveysForDropdown] =
    useState<SurveyResponse[]>();

  const {
    data: surveySetData,
    isLoading: isSurveySetLoading,
    isError: isSurveySetError,
  } = useQuery(
    queryKeys.SURVEYSET_DETAIL_BY_ID(Number(id)),
    () => getSurveySetRequest(Number(id)),
    {
      enabled: !isNew,
      onSuccess: ({ data: surveySetData }) => {
        const { slugUrl, title, product, items } = surveySetData;

        if (product) {
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          openAlreadyLinkedAlert();
        }

        setSurveySet({
          ...surveySet,
          title: title || '',
          surveyRepo:
            items?.map((item) => ({ ...item.survey, itemId: item.id })) || [],
        });
        setSlugUrl({
          cohort: slugUrl ? slugUrl.split('-').slice(-1)[0] : '',
          course: slugUrl ? slugUrl.split('-').slice(0, -1).join('-') : '',
        });
      },
    },
  );

  const getSurveysParams = {
    search: {
      title: debouncedTitleInput === '' ? undefined : debouncedTitleInput,
    },
    pagination: {
      page: 0,
      size: 100,
      sortDirection: Pagination.sortDirection.DESC,
      sortFields: ['id'],
    },
  };

  const { isLoading: isSurveysLoading, refetch: refetchSurveys } = useQuery(
    queryKeys.SURVEY_LIST(getSurveysParams),
    () => getSurveysRequest(getSurveysParams),
    {
      onSuccess: ({ data }) => {
        if (data) {
          setSurveysForDropdown(data.content);
        }
      },
    },
  );

  const { mutate: createSurveySet } = useMutation<
    AxiosResponse,
    AxiosError,
    TemplateUpsertRequest,
    unknown
  >((variables) => createSurveySetRequest(variables));

  const { mutate: updateSurveyOnDetail } = useMutation(
    (variables: { id: number; request: SurveyChangeTestRequest }) =>
      updateSurveyOnDetailRequest(Number(variables.id), variables.request),
  );

  const { mutate: updateSurveySet } = useMutation<
    AxiosResponse,
    AxiosError,
    { id: number; request: TemplateUpsertRequest },
    unknown
  >((variables) =>
    updateSurveySetRequest(Number(variables.id), variables.request),
  );

  const handleInputTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSurveySet({
      ...surveySet,
      title: e.target.value,
    });
  };

  const handleInputSlugUrlChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
  ) => {
    setSlugUrl({ ...slugUrl, ...{ [type]: e.target.value } });
  };

  const handleAddModalInputChange = (value: string) => {
    setTitleInput(value);
  };
  const handleAddButtonClick = () => {
    setSurveySet({
      ...surveySet,
      isAddModalOpen: true,
    });
  };
  const handleModalAddButtonClick = () => {
    if (surveySet.isSurveyBeingReplaced && surveySet.surveyBeingReplaced) {
      const changedSurveyRepo = surveySet.surveyRepo.map(
        (survey?: SurveyRepoType) =>
          survey && survey?.id === surveySet.surveyBeingReplaced?.id
            ? surveySet.selectedSurvey
            : survey,
      );
      setSurveySet({
        ...surveySet,
        surveyRepo: changedSurveyRepo,
        deletedSurveys: [
          ...surveySet.deletedSurveys,
          surveySet.surveyBeingReplaced,
        ],
        isSurveyBeingReplaced: false,
        selectedSurvey: undefined,
      });
    } else {
      setSurveySet({
        ...surveySet,
        surveyRepo: [...surveySet.surveyRepo, surveySet.selectedSurvey],
        selectedSurvey: undefined,
      });
    }
    setTitleInput('');
  };
  const handleSurveyAddModalClosed = () => {
    setSurveySet({
      ...surveySet,
      isAddModalOpen: false,
    });
    setTitleInput('');
  };
  const handleAddModalOptionSelected = (selectedSurvey?: SurveyResponse) => {
    if (!selectedSurvey) {
      return;
    }

    setTitleInput(selectedSurvey.title || '');
    setSurveySet({
      ...surveySet,
      selectedSurvey,
    });
  };
  const handleDeleteButtonClick = (surveyBeingDeleted?: SurveyResponse) => {
    if (!surveyBeingDeleted) {
      return;
    }

    setSurveySet({
      ...surveySet,
      surveyRepo: surveySet.surveyRepo.filter(
        (survey) => survey?.id !== surveyBeingDeleted.id,
      ),
      deletedSurveys: [...surveySet.deletedSurveys, surveyBeingDeleted],
    });
  };
  const handleSurveyInputClick = (clickedSurvey?: SurveyResponse) => {
    if (!clickedSurvey) {
      return;
    }

    setSurveySet({
      ...surveySet,
      isSurveyBeingReplaced: true,
      isAddModalOpen: true,
      surveyBeingReplaced: clickedSurvey,
    });
  };
  const handlePreviewButtonClick = (clickedSurvey: SurveyResponse) => {
    if (!clickedSurvey) {
      return;
    }

    setPreviewSurvey({
      isOpen: true,
      surveyId: clickedSurvey.id,
    });
  };
  const handleTimeRequiredInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSurveySetting({
      ...surveySetting,
      timeRequired: Number(e.target.value),
    });
  };
  const handleDisplayTitleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSurveySetting({
      ...surveySetting,
      displayTitle: e.target.value,
    });
  };
  const handleSurveySettingBtnClick = async () => {
    const updatedSurvey: SurveyResponse | undefined = JSON.parse(
      JSON.stringify(surveySetting.selectedSurvey),
    );
    if (!updatedSurvey) {
      return;
    }

    updatedSurvey.displayTitle = surveySetting.displayTitle;
    updatedSurvey.nextRequiredTime = surveySetting.timeRequired;

    const updatedSurveyRepo = surveySet.surveyRepo.map(
      (survey?: SurveyResponse) =>
        survey && survey.id === updatedSurvey.id ? updatedSurvey : survey,
    );

    setSurveySet({
      ...surveySet,
      surveyRepo: updatedSurveyRepo,
    });
    setSurveySetting({
      ...surveySetting,
      timeRequired: undefined,
      displayTitle: '',
      isModalOpen: false,
    });
    showToast({
      message: '질문지 설정이 저장되었습니다.',
      type: toastType.positive,
    });
  };
  const handleSettingModalClose = () => {
    setSurveySetting({
      ...surveySetting,
      isModalOpen: false,
    });
  };
  const handleSurveySettingClick = (selectedSurvey: SurveyResponse) => {
    setSurveySetting({
      ...surveySetting,
      displayTitle: selectedSurvey.displayTitle || selectedSurvey.title || '',
      timeRequired: selectedSurvey.nextRequiredTime,
      selectedSurvey,
      isModalOpen: true,
    });
  };
  const goBackToSurveySetPage = () => {
    setIsAlertOpen(false);
    navigate('/survey/set');
  };
  const openCancelAlert = () => {
    setAlertContent({
      title: '취소시 수정한 내용이 저장되지 않습니다.',
      doneLabel: '네, 취소합니다.',
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      onDoneClick: handleModalCancelButtonClick,
    });
  };

  const openSaveAlert = () => {
    setAlertContent({
      title: '필수 항목이 모두 채워졌는지 확인해주세요.',
      subTitle:
        '기본 정보 입력, 질문지 연결, 예상 소요 시간 지정이 필요합니다.',
      doneLabel: '확인',
      onDoneClick: () => setIsAlertOpen(false),
    });
  };
  const openAlreadyLinkedAlert = () => {
    setAlertContent({
      title:
        '이미 상품에 연결된 질문지의 경우,\n 변경된 내용을 저장할 수 없습니다.',
      doneLabel: '확인',
      onDoneClick: goBackToSurveySetPage,
      onCancelClick: goBackToSurveySetPage,
    });
  };
  const handleModalCancelButtonClick = () => {
    navigate('/survey/set');
  };
  const isAllSurveysTimeRequiredSet = (
    surveys: (SurveyResponse | undefined)[],
  ) => surveys.every((survey?: SurveyResponse) => survey?.nextRequiredTime);
  const checkRequiredFields = () =>
    surveySet.title.length &&
    slugUrl.cohort.length &&
    slugUrl.course.length &&
    surveySet.surveyRepo.length &&
    isAllSurveysTimeRequiredSet(surveySet.surveyRepo);
  const checkIsNeedToBeUpdated = (survey: SurveyResponse) => {
    const { id, displayTitle, nextRequiredTime } = survey;

    const originalSurvey = surveysForDropdown?.find(
      (survey) => survey.id === id,
    );
    if (!originalSurvey) {
      return;
    }

    const {
      displayTitle: originalDisplayTitle,
      nextRequiredTime: originalNextRequiredTime,
    } = originalSurvey;

    return (
      originalDisplayTitle !== displayTitle ||
      originalNextRequiredTime !== nextRequiredTime
    );
  };

  const handleSaveButtonClick = () => {
    const isRequiredFieldsAllFilled = checkRequiredFields();
    if (!isRequiredFieldsAllFilled) {
      openSaveAlert();
      return;
    }

    surveySet.surveyRepo.forEach((survey?: SurveyResponse) => {
      if (!survey) {
        return;
      }
      const { id, title, displayTitle, nextRequiredTime } = survey;
      if (!id || !title) {
        return;
      }

      if (checkIsNeedToBeUpdated(survey)) {
        updateSurveyOnDetail({
          id,
          request: {
            displayTitle: displayTitle || title,
            nextRequiredTime,
          },
        });
      }
    });

    if (isNew) {
      createSurveySet(
        {
          title: surveySet.title,
          slugUrl: `${slugUrl.course}-${slugUrl.cohort}`,
          description: '',
          items: surveySet.surveyRepo.map((survey, index) => ({
            surveyId: survey?.id || 0,
            sequence: index,
          })),
        },
        {
          onSuccess: () => {
            navigate('/survey/set');
          },
          onError: () => {
            showToast({
              message: '동일한 제목의 질문지 세트를 생성할 수 없습니다.',
              type: toastType.negative,
            });
          },
        },
      );
    } else {
      const items = surveySet.surveyRepo.map((survey, index) => ({
        id: survey?.itemId,
        surveyId: survey?.id || 0,
        sequence: index,
        test: survey?.test,
      }));

      updateSurveySet(
        {
          id: Number(id),
          request: {
            title: surveySet.title,
            slugUrl: `${slugUrl.course}-${slugUrl.cohort}`,
            items,
          },
        },
        {
          onSuccess: () => {
            navigate('/survey/set');
          },
          onError: () => {
            showToast({
              message:
                '질문지 생성중 에러가 발생하였습니다. 관리자에게 문의해주세요.',
              type: toastType.negative,
            });
          },
        },
      );
    }
  };

  useEffect(() => {
    if (surveySetting.displayTitle.length > DISPLAY_TITLE_TYPING_LIMIT) {
      setSurveySetting({
        ...surveySetting,
        isTitleValidationFailed: true,
      });
    } else {
      setSurveySetting({
        ...surveySetting,
        isTitleValidationFailed: false,
      });
    }
  }, [surveySetting.displayTitle, surveySetting.selectedSurvey]);

  useEffect(() => {
    if (!surveySetting.timeRequired) {
      setSurveySetting({
        ...surveySetting,
        isTimeValidationFailed: true,
      });
    } else {
      setSurveySetting({
        ...surveySetting,
        isTimeValidationFailed: false,
      });
    }
  }, [surveySetting.timeRequired, surveySetting.selectedSurvey]);

  useEffect(() => {
    if (surveySetting.selectedSurvey && surveySetData) {
      const selectedSurveyForSetting = surveySet.surveyRepo.find(
        (survey: SurveyResponse | undefined) =>
          survey && survey.id === Number(surveySetting.selectedSurvey?.id),
      );

      if (!selectedSurveyForSetting) {
        return;
      }

      setSurveySetting({
        ...surveySetting,
        displayTitle:
          selectedSurveyForSetting.displayTitle ||
          selectedSurveyForSetting.title ||
          '',
        timeRequired: selectedSurveyForSetting.nextRequiredTime,
      });
    }
  }, [surveySetting.selectedSurvey]);

  useEffect(() => {
    if (alertContent.title && !isAlertOpen) {
      setIsAlertOpen(true);
    }
  }, [alertContent]);

  useEffect(() => {
    refetchSurveys();
  }, [debouncedTitleInput]);

  useHeader({
    title: '질문지 세트 등록 및 수정',
    pageInfo: [
      { page: '질문지 세트', onClick: openCancelAlert },
      { page: '질문지 세트 등록 및 수정' },
    ],
  });

  return (
    <>
      <Modal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
        <Alert>
          <Alert.Title>
            <p>{alertContent.title}</p>
            <Alert.Title.Caption>{alertContent.subTitle}</Alert.Title.Caption>
          </Alert.Title>
          <Alert.Footer>
            <Button onClick={alertContent.onDoneClick}>
              {alertContent.doneLabel}
            </Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <Modal
        isOpen={surveySet.isAddModalOpen}
        onClose={handleSurveyAddModalClosed}
      >
        <Modal.Content>
          <Modal.Header>질문지 추가하기</Modal.Header>
          <Modal.Body>
            <SurveyAddModal
              input={titleInput}
              setInput={setTitleInput}
              handleOptionSelected={handleAddModalOptionSelected}
              handleInputChanged={handleAddModalInputChange}
              surveyListData={surveysForDropdown}
              surveyRepo={surveySet.surveyRepo}
              deletedSurveys={surveySet.deletedSurveys}
              surveyListLoading={isSurveysLoading}
              isSurveySelected={!!surveySet.selectedSurvey}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleModalAddButtonClick}
              isDisabled={!surveySet.selectedSurvey}
            >
              추가하기
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      <Modal
        isOpen={surveySetting.isModalOpen}
        onClose={handleSettingModalClose}
        height={450}
      >
        <Modal.Content>
          <Modal.Header>질문지 설정</Modal.Header>
          <Modal.Body>
            <SurveySettingModal
              surveyRepo={surveySet.surveyRepo}
              selectedSurvey={surveySetting.selectedSurvey}
              handleDisplayTitleInputChange={handleDisplayTitleInputChange}
              isDisplayTitleError={surveySetting.isTitleValidationFailed}
              handleTimeRequiredInputChange={handleTimeRequiredInputChange}
              isTimeRequiredError={surveySetting.isTimeValidationFailed}
              displayTitleTypingLimit={DISPLAY_TITLE_TYPING_LIMIT}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleSurveySettingBtnClick}
              isDisabled={
                surveySetting.isTimeValidationFailed ||
                surveySetting.isTitleValidationFailed
              }
            >
              저장
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      {previewSurvey.surveyId && (
        <PreviewSurveyModal
          surveyId={previewSurvey.surveyId}
          isOpen={previewSurvey.isOpen}
          onClose={() => setPreviewSurvey({ ...previewSurvey, isOpen: false })}
        />
      )}

      {!isNew && isSurveySetLoading ? (
        <Loading isFull />
      ) : isSurveySetError ? (
        <Error isFull={false} />
      ) : (
        <div className={`content-wrapper ${styles.set_editor_page_wrapper}`}>
          <div className={styles.main_contents}>
            <BasicInfoSection
              page={'editor'}
              id={id}
              title={surveySetData?.data.title || ''}
              handleInputTitleChange={handleInputTitleChange}
              handleInputSlugUrlChange={handleInputSlugUrlChange}
              slugUrl={surveySetData?.data.slugUrl || ''}
            />
            <SurveySection
              page={'editor'}
              surveys={surveySet.surveyRepo}
              setSurveyRepo={(updatedSurveyRepo) => {
                setSurveySet({
                  ...surveySet,
                  surveyRepo: updatedSurveyRepo,
                });
              }}
              handleAddButtonClick={handleAddButtonClick}
              handleDeleteButtonClick={handleDeleteButtonClick}
              handlePreviewButtonClick={handlePreviewButtonClick}
              handleSurveyInputClick={handleSurveyInputClick}
              handleSurveySettingClick={handleSurveySettingClick}
            />
            <ProductSection
              products={
                surveySetData?.data.product ? [surveySetData?.data.product] : []
              }
            />
          </div>
          <div className={styles.content_footer}>
            <div className={styles.date_info}>
              {isNew ? null : (
                <span>{`생성일: ${formatDateTime(
                  surveySetData?.data.createdAt || '',
                )} | 최종 업데이트일: ${formatDateTime(
                  surveySetData?.data.updatedAt || '',
                )}`}</span>
              )}
            </div>
            <div className={styles.button_box}>
              <Button size="big" variant="skeleton" onClick={openCancelAlert}>
                취소
              </Button>
              <Button size="big" onClick={handleSaveButtonClick}>
                저장하기
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SurveySetEditorPage;
