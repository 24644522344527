import React from 'react';

import styles from './Input.module.scss';

type InputValueType = string | number | readonly string[] | undefined;

export interface InputDefaultProps {
  id?: string;
  type?: React.HTMLInputTypeAttribute;
  className?: string;
  value?: InputValueType;
  defaultValue?: InputValueType;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
}

export interface InputOptionsProps {
  size?: 'small' | 'medium';
  focusStyle?: 'color' | 'border';
  isSolid?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isFullHeight?: boolean;
  isInvalid?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onWheel?: React.WheelEventHandler<HTMLInputElement>;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
}

export interface InputProps extends InputDefaultProps, InputOptionsProps {}

const Input = (props: InputProps) => {
  const {
    className,
    isSolid,
    isReadOnly,
    isDisabled,
    isFullWidth = true,
    isFullHeight,
    isInvalid,
    focusStyle = 'color',
    leftIcon,
    rightIcon,
    size = 'medium',
    ...rest
  } = props;

  const mergedClassName: string = [
    leftIcon || rightIcon ? styles.inputGroup : styles.input,
    styles[`size_${size}`],
    focusStyle === 'border' && styles.focusBorder,
    isSolid && styles.solid,
    isReadOnly && styles.read_only,
    isDisabled && styles.disabled,
    isInvalid && styles.invalid,
    isFullWidth && styles.fullWidth,
    isFullHeight && styles.fullHeight,
    className,
  ]
    .filter((className) => !!className)
    .join(' ');

  return leftIcon || rightIcon ? (
    <div className={mergedClassName}>
      {leftIcon}
      <input disabled={isDisabled} readOnly={isReadOnly} {...rest} />
      {rightIcon}
    </div>
  ) : (
    <input
      className={mergedClassName}
      disabled={isDisabled}
      readOnly={isReadOnly}
      {...rest}
    />
  );
};

export default Input;
