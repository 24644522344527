import { useAuth } from 'hooks/useAuth';

import { Navigate, useSearchParams } from 'react-router-dom';

import styles from './SignupPage.module.scss';

const SignupPage = () => {
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const accessToken = searchParams.get('accessToken');
  const email = searchParams.get('email') || '';

  if (auth.user) {
    return <Navigate to="/" />;
  }

  if (!accessToken || !email) {
    return <Navigate to="/login" />;
  }

  return <div className={styles.container} />;
};

export default SignupPage;
