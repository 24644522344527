// Choices의 0번째 인덱스가 defaultValue가 되도록 하는 로직
// https://www.notion.so/codestates/ce67286b27f842ae8eb70c8af79e2d04?pvs=4#9b2462e922084346a36682cb1ba9f5eb

import type { Choice } from 'generated';

export const parsingChoices = (defaultValue?: string, choices?: Choice[]) => {
  if (!choices) {
    return '';
  }
  const matchWithDefaultValue = choices.filter(
    (choice) => choice?.value === defaultValue,
  )[0];

  const matchIndex = choices.indexOf(matchWithDefaultValue) || -1;

  const parsedChoices =
    matchIndex === -1
      ? [...choices]
      : [
          matchWithDefaultValue,
          ...choices.slice(0, matchIndex),
          ...choices.slice(matchIndex + 1),
        ];

  const parsedChoiceTexts =
    (parsedChoices &&
      parsedChoices?.map((choice) => choice?.text || '').join(',')) ||
    '';

  return parsedChoiceTexts;
};
