import { Pagination } from 'generated';
import { getTemplateForEvaluationRequest } from 'pages/EvaluationStandardPage/requests';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import useToast from 'utils/hooks/useToast';

import { useMemo, useState } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import {
  downloadEvaluationDataRequest,
  evaluateTemplateRequest,
  getEvaluationStandard,
  getSurveySetListRequest,
  uploadEvaluationDataRequest,
} from './requests';
import { DownloadDocumentType } from './types';

import type { AxiosError } from 'axios';
import type { TemplateResponse, AutomaticEvaluationResponse } from 'generated';

const documentTypeOptions: { name: string; value: DownloadDocumentType }[] = [
  { name: 'XLSX', value: DownloadDocumentType.XLSX },
  { name: 'CSV', value: DownloadDocumentType.CSV },
];

const useEvaluationResultPage = () => {
  const { showToast, toastType } = useToast();
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponse>();
  const [downloadDocumentType, setDownloadDocumentType] = useState<{
    name: string;
    value: DownloadDocumentType;
  }>(documentTypeOptions[0]);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isEvalAlertOpen, setIsEvalAlertOpen] = useState(false);
  const [isEvalInvalidAlertOpen, setIsEvalInvalidAlertOpen] = useState(false);
  const [evalFailAlert, setEvalFailAlert] = useState<{
    open: boolean;
    serverResponse?: AutomaticEvaluationResponse;
  }>({
    open: false,
  });
  const [isDownloadQueryEnabled, setIsDownloadQueryEnabled] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<number>(0);

  const getSurveySetsParams = {
    /* DTLab 데이터도 조회가 가능하도록 하기 위해 비활성화 */
    // search: {
    //   applyStarted: true,
    // },
    search: {},
    pagination: {
      sortFields: ['id'],
      sortDirection: Pagination.sortDirection.DESC,
    },
  };

  const { data: templateListData } = useQuery(
    queryKeys.SURVEYSET_LIST(getSurveySetsParams),
    () => getSurveySetListRequest(getSurveySetsParams),
    {
      staleTime: 5 * 60 * 1000,
    },
  );

  const { data: evaluationStandardData } = useQuery(
    queryKeys.STANDARD_DETAIL_BY_ID(selectedTemplate?.id),
    () => getEvaluationStandard(selectedTemplate?.id),
    {
      onSuccess: (data) => {
        setSelectedNumber(data.selectedNumber ?? 0);
      },
      enabled: !!selectedTemplate,
    },
  );

  // TODO 아래 조건 확인
  const isNumberNotSelected = useMemo(
    () =>
      !!selectedTemplate &&
      !!evaluationStandardData?.completedApplications &&
      !selectedNumber,
    [selectedNumber, selectedTemplate, evaluationStandardData],
  );

  const { data: surveySetForEvaData } = useQuery(
    queryKeys.SURVEYSET_FOR_EVA_DETAIL_BY_ID(selectedTemplate?.id as number),
    () => getTemplateForEvaluationRequest(selectedTemplate?.id as number),
    {
      enabled: !!selectedTemplate && !!selectedTemplate.id,
    },
  );

  const {
    data: downloadData,
    isLoading: isDownloadDataLoading,
    refetch: downloadDataRefetch,
  } = useQuery(
    queryKeys.APPLICATION_DOWNLOAD('evaluation', new Date()),
    () =>
      downloadEvaluationDataRequest(
        downloadDocumentType.value,
        selectedTemplate?.product?.id,
        selectedTemplate?.id,
      ),
    {
      onSuccess: (data) => {
        const blob = new Blob([data.data]);
        const fileUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.style.display = 'none';

        const disposition = data.headers['content-disposition'];
        const filename = disposition
          ?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1]
          .replace(/['"]/g, '');
        link.download =
          filename ??
          `applications.${downloadDocumentType.value.toLowerCase()}`;

        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileUrl);

        setIsDownloadQueryEnabled(false);
        setIsDownloadModalOpen(false);
        showToast({
          message: '심사 시트를 다운로드 했습니다.',
          type: toastType.positive,
        });
      },
      onError: () => {
        showToast({
          message: `심사 시트 다운로드에 실패했습니다.`,
          type: toastType.negative,
        });
        setIsDownloadQueryEnabled(false);
        setIsDownloadModalOpen(false);
      },
      enabled: isDownloadQueryEnabled,
      retry: false,
      staleTime: Infinity,
    },
  );

  const { mutate: uploadData, isLoading: isUploadDataLoading } = useMutation(
    (file: File) => uploadEvaluationDataRequest(file),
    {
      onSuccess: ({ data }) => {
        const { fails } = data;
        if (fails && fails.length > 0) {
          showToast({
            message:
              '심사 시트 결과 업데이트가 일부 실패했습니다. 다시 시도해 주세요.',
            type: toastType.negative,
          });
        } else {
          showToast({
            message: '심사 시트 결과가 업데이트 되었습니다.',
            type: toastType.positive,
          });
        }
      },
      onError: (error: AxiosError<{ message: string }>) => {
        const message = error.response?.data.message ?? error.message;
        showToast({ message, type: toastType.negative });
      },
      retry: false,
    },
  );

  const { mutate: evaluateTemplate, isLoading: isEvaluateTemplateLoading } =
    useMutation(
      // TODO 서버 선발정원 반영 후 -> "selectedNumber || 1" 삭제
      () => evaluateTemplateRequest(selectedNumber || 1, selectedTemplate?.id),
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries(
            queryKeys.STANDARD_DETAIL_BY_ID(selectedTemplate?.id),
          );
          queryClient.invalidateQueries(queryKeys.APPLICATION_LIST());

          if (data.fails?.length) {
            setEvalFailAlert({ open: true, serverResponse: data });
          } else {
            showToast({
              message: `${data.success}개 지원서에 대한 심사를 완료했습니다.`,
              type: toastType.positive,
            });
          }
        },
      },
    );

  const surveySetOptions = useMemo(() => {
    if (!templateListData?.content) {
      return [];
    }
    return templateListData.content.map((data) => ({
      name: data.title ?? '',
      value: data.id,
    }));
  }, [templateListData]);

  const isEvalValid = useMemo(() => {
    if (
      selectedTemplate &&
      selectedTemplate.unannounced &&
      evaluationStandardData &&
      (evaluationStandardData.completedApplications ?? 0) -
        (evaluationStandardData.evaluatedApplications ?? 0) >
        0
    ) {
      return true;
    }
    return false;
  }, [selectedTemplate, evaluationStandardData]);

  const evaluatingHandler = () => {
    const surveys = surveySetForEvaData?.data.items;
    if (!surveys) {
      showToast({
        message: 'survey를 받아오지 못했습니다. ISP에 문의해주세요',
        type: toastType.negative,
      });
      return;
    }

    const isValid = surveys.every((survey) => !survey.hasError);
    if (isValid) {
      setIsEvalAlertOpen(true);
    } else {
      setIsEvalInvalidAlertOpen(true);
    }
  };

  const handleTemplateAutoEvaluation = () => {
    evaluateTemplate();
    setIsEvalAlertOpen(false);
  };

  const handleDownloadEvaluation = () => {
    if (downloadData) {
      downloadDataRefetch();
    }
    setIsDownloadQueryEnabled(true);
  };

  const handleUploadEvaluation = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const { name } = e.target.files[0];
    const typeOfFile = name.split('.')[name.split('.').length - 1];
    if (typeOfFile !== 'xlsx') {
      showToast({
        message: 'xlsx 파일만 업로드가 가능합니다.',
        type: toastType.negative,
      });
      return;
    }
    const file = e.target.files[0];
    uploadData(file);
  };

  const handleDownloadFormatRadioChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value, name } = e.target;
    setDownloadDocumentType({ name, value });
  };

  const handleSelectedTemplateChanged = (value: number | undefined) => {
    const matched = templateListData?.content?.find(
      (template) => template.id === value,
    );
    if (matched) {
      setSelectedTemplate(matched);
    }
  };

  const handleSelectedNumberChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const numberValue = Number(e.target.value);
    setSelectedNumber(Number.isNaN(numberValue) ? 0 : numberValue);
  };

  return {
    isDownloadModalOpen,
    isEvalAlertOpen,
    isEvalInvalidAlertOpen,
    evalFailAlert,
    isDownloadDataLoading,
    isUploadDataLoading,
    isEvaluateTemplateLoading,
    isNumberNotSelected,
    surveySetOptions,
    selectedTemplate,
    evaluationStandardData,
    selectedNumber,
    downloadDocumentType,
    isDownloadQueryEnabled,
    documentTypeOptions,
    isEvalValid,
    setIsEvalInvalidAlertOpen,
    setEvalFailAlert,
    setIsEvalAlertOpen,
    setIsDownloadModalOpen,
    evaluatingHandler,
    handleTemplateAutoEvaluation,
    handleDownloadEvaluation,
    handleUploadEvaluation,
    handleDownloadFormatRadioChanged,
    handleSelectedTemplateChanged,
    handleSelectedNumberChanged,
  };
};

export default useEvaluationResultPage;
