/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { OrganizationUuidResponse } from './OrganizationUuidResponse';

export type UserResponse = {
    id?: number;
    uuid?: string;
    createdBy?: number;
    createdAt?: string;
    updatedBy?: number;
    updatedAt?: string;
    name?: string;
    email?: string;
    mobile?: string;
    approvalStatus?: UserResponse.approvalStatus;
    approvedAt?: string;
    role?: UserResponse.role;
    organization?: OrganizationUuidResponse;
};

export namespace UserResponse {

    export enum approvalStatus {
        PENDING = 'PENDING',
        APPROVED = 'APPROVED',
        DEPRIVATED = 'DEPRIVATED',
    }

    export enum role {
        SUPER_ADMIN = 'SUPER_ADMIN',
        ADMIN = 'ADMIN',
        CREW = 'CREW',
        APPLICANT = 'APPLICANT',
    }


}

