import React from 'react';

import styles from './SurveyCreatorHeader.module.scss';
import { ReactComponent as ChevronForward } from '../../../assets/chevron-forward.svg';
import { ReactComponent as Edit } from '../../../assets/edit-lined.svg';
import { Button } from '../../../components/Button/Button';

interface HeaderProps {
  title: string | undefined;
  onSave: (e: React.MouseEvent) => void;
  onCancel: () => void;
  onEditTitle: () => void;
}
const SurveyCreatorHeader = ({
  title,
  onCancel,
  onSave,
  onEditTitle,
}: HeaderProps) => {
  const pageInfo = ['질문지', '질문지 등록 및 수정'];

  return (
    <header className={styles.header}>
      <div className={styles.header_content_wrapper}>
        <section className={styles.title}>
          <h1 className={styles.page_title}>질문지 등록 및 수정</h1>
          <div className={styles.breadcrumbs}>
            <button className="text-style-t1" onClick={() => onCancel()}>
              <span>{pageInfo[0]}</span>
            </button>
            <ChevronForward />
            <span className="text-style-body">{pageInfo[1]}</span>
          </div>
        </section>

        <section className={styles.controller}>
          <div className={styles.edit_survey_title}>
            <div className={styles.title}>
              <h5>{title}</h5>
            </div>
            <button className={styles.btn_edit} onClick={() => onEditTitle()}>
              <Edit />
            </button>
          </div>

          <Button variant="skeleton" onClick={() => onCancel()}>
            <p className="text-style-t1">취소</p>
          </Button>
          <Button variant="solid" onClick={(e: React.MouseEvent) => onSave(e)}>
            <p className="text-style-t1">질문지 저장</p>
          </Button>
        </section>
      </div>
    </header>
  );
};

export default SurveyCreatorHeader;
