export default function getUrls(path = '', root = 'codestates.com') {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return {
        cod_admin: `https://admin.${root}/${path}`,
        cod_urclass: `https://urclass.${root}/${path}`,
        adm_admin: `https://admission-admin.${root}/${path}`,
      };
    case 'qa':
      return {
        cod_admin: `https://staging-admin.${root}/${path}`,
        cod_urclass: `https://staging-urclass.${root}/${path}`,
        adm_admin: `https://admission-admin.${root}/${path}`,
      };
    case 'development':
      return {
        cod_admin: `http://localhost:3001/${path}`,
        cod_urclass: `http://localhost:3000/${path}`,
        adm_admin: `https://localhost:8080/${path}`,
      };
    default:
      return {
        cod_admin: `http://localhost:3001/${path}`,
        cod_urclass: `http://localhost:3000/${path}`,
        adm_admin: `https://localhost:8080/${path}`,
      };
  }
}
