import styles from './Equal.module.scss';

const Equal = () => (
  <div className={styles.equal}>
    <div className={styles.equal_black} />
    <div className={styles.equal_black} />
  </div>
);

export default Equal;
