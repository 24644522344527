/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button } from 'components/Button/Button';
import Input from 'components/Input/Input';

import { Menu, Settings, Trash2 } from 'react-feather';

import styles from './SurveySectionBar.module.scss';

import type { SurveyResponse } from 'generated';

interface SurveySectionBarProps {
  disabled: boolean;
  surveyTitle: string;
  survey?: SurveyResponse;
  handleDeleteButtonClick?: (survey: SurveyResponse) => void;
  // TODO: 타입 지정
  provided?: any;
  snapshot?: any;
  handlePreviewButtonClick?: (survey: SurveyResponse) => void;
  handleSurveyInputClick?: (survey: SurveyResponse) => void;
  handleSurveySettingClick?: (survey: SurveyResponse) => void;
}

const SurveySectionBar = ({
  disabled,
  surveyTitle,
  survey,
  handleDeleteButtonClick,
  provided,
  snapshot,
  handlePreviewButtonClick,
  handleSurveyInputClick,
  handleSurveySettingClick,
}: SurveySectionBarProps) => (
  <div
    className={styles.bar_wrapper}
    ref={provided?.innerRef}
    // eslint-disable-next-line react/no-unknown-property
    snapshot={snapshot}
    {...provided?.draggableProps}
  >
    <div {...provided?.dragHandleProps}>
      <Menu />
    </div>
    <div className={`${styles.bar} ${disabled ? styles.disabled : ''}`}>
      <div
        className={styles.input_wrapper}
        onClick={() => {
          if (handleSurveyInputClick && survey) {
            handleSurveyInputClick(survey);
          }
        }}
        onKeyPress={() => {
          if (handleSurveyInputClick && survey) {
            handleSurveyInputClick(survey);
          }
        }}
        role="button"
        tabIndex={0}
      >
        <Input defaultValue={surveyTitle} isDisabled />
      </div>
      <Button
        variant="skeleton"
        className={styles.preview_button}
        onClick={() => {
          if (handlePreviewButtonClick && survey) {
            handlePreviewButtonClick(survey);
          }
        }}
      >
        미리보기
      </Button>
      <Settings
        className={styles.setting_icon}
        onClick={() =>
          handleSurveySettingClick && survey && handleSurveySettingClick(survey)
        }
      />
      <Trash2
        onClick={() => {
          if (handleDeleteButtonClick && survey) {
            handleDeleteButtonClick(survey);
          }
        }}
      />
    </div>
  </div>
);

export default SurveySectionBar;
