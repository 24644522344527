import { useHeaderInfoStore } from 'store';

import type { DependencyList } from 'react';
import { useLayoutEffect } from 'react';

import type { HeaderInfoType } from 'store';

const useHeader = (
  { title, titleTag, pageInfo }: HeaderInfoType,
  DependencyList?: DependencyList,
) => {
  const { setHeaderInfo } = useHeaderInfoStore();

  useLayoutEffect(() => {
    setHeaderInfo({
      title,
      titleTag,
      pageInfo,
    });
  }, DependencyList ?? []);
};

export default useHeader;
