import Lottie from 'lottie-react';

import * as React from 'react';

import styles from './Button.module.scss';
import * as animationData from './button-loader.json';

export interface ButtonDefaultProps {
  children: React.ReactNode;
  className?: string;
  onClick?: (e?: any) => void;
}

export interface ButtonOptions {
  isLoading?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
}

export interface ButtonThemingProps {
  size?: 'small' | 'medium' | 'large' | 'big';
  variant?:
    | 'solid'
    | 'light_solid'
    | 'skeleton'
    | 'line-skeleton'
    | 'skeleton-gray'
    | 'solid-gray';
  contentAlign?: 'center' | 'right';
}

export interface ButtonProps
  extends ButtonDefaultProps,
    ButtonOptions,
    ButtonThemingProps {}

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
};

export const Button = (props: ButtonProps) => {
  const {
    isDisabled,
    isLoading,
    isFullWidth,
    children,
    leftIcon,
    rightIcon,
    className,
    size = 'medium',
    variant = 'solid',
    contentAlign = 'center',
    onClick,
  } = props;

  return (
    <button
      onClick={onClick}
      disabled={isDisabled || isLoading}
      className={`${styles.button} ${
        size === 'small'
          ? styles.small
          : size === 'large'
          ? styles.large
          : size === 'big'
          ? styles.big
          : styles.medium
      } ${
        variant === 'skeleton'
          ? styles.skeleton
          : variant === 'line-skeleton'
          ? styles.line_skeleton
          : variant === 'light_solid'
          ? styles.light_solid
          : variant === 'skeleton-gray'
          ? styles.skeleton_gray
          : variant === 'solid-gray'
          ? styles.solid_gray
          : styles.solid
      } ${
        contentAlign === 'right' ? styles.align_right : styles.align_center
      } ${isFullWidth && styles.full_width} ${className}`}
    >
      {isLoading ? (
        <div className={styles.spinner_box}>
          <Lottie {...lottieOptions} style={{ width: '85%', height: '85%' }} />
        </div>
      ) : (
        <>
          {leftIcon && <div className={styles.left_icon}>{leftIcon}</div>}
          {children}
          {rightIcon && <div className={styles.right_icon}>{rightIcon}</div>}
        </>
      )}
    </button>
  );
};
