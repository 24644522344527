/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LandingPageCurriculumUpsertRequest = {
    bootCampCategory?: LandingPageCurriculumUpsertRequest.bootCampCategory;
    sequence?: number;
    part?: string;
    title?: string;
    description?: string;
    contents?: string;
    id?: number;
};

export namespace LandingPageCurriculumUpsertRequest {

    export enum bootCampCategory {
        ALL = 'ALL',
        SEBFE = 'SEBFE',
        SEBBE = 'SEBBE',
        UUB = 'UUB',
        GMB = 'GMB',
        DOB = 'DOB',
        PMB = 'PMB',
        BEB = 'BEB',
        AIB = 'AIB',
    }


}

