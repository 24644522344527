import classNames from 'classnames';
import Portal from 'components/Portal';

import React, { useEffect, useMemo, useState } from 'react';

import styles from './Modal.module.scss';

const MODAL_CLOSE_TIME = 200;
type ModalSizeType = 'default' | 'large';

interface ModalContextType {
  size: ModalSizeType;
  onClose: () => void;
  height?: number;
}

const ModalContext = React.createContext<ModalContextType>({
  size: 'default',
  onClose: () => console.log('modal close'),
});

export const useModalContext = () => React.useContext(ModalContext);

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: ModalSizeType;
  height?: number;
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  isOpen,
  onClose,
  size = 'default',
  height,
  children,
}) => {
  const [transitionIsOpen, setTransitionIsOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setTransitionIsOpen(true);
    } else {
      setTimeout(() => setTransitionIsOpen(false), MODAL_CLOSE_TIME);
    }
  }, [isOpen]);

  const value = useMemo(
    () => ({
      onClose,
      size,
      height,
    }),
    [onClose, size, height],
  );

  return (
    <ModalContext.Provider value={value}>
      {transitionIsOpen && (
        <Portal>
          <div
            className={classNames(
              styles.modal_container,
              isOpen
                ? styles.modal_container_open
                : styles.modal_container_close,
            )}
          >
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className={styles.modal_container_overlay} />
            <div className={styles.modal_container_innerContent}>
              {children}
            </div>
          </div>
        </Portal>
      )}
    </ModalContext.Provider>
  );
};

export default Modal;
