import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';
import queryKeys from 'query/queryKeys';
import useToast from 'utils/hooks/useToast';

import { useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import styles from './ApplicationDownloadModal.module.scss';
import {
  downloadApplicationsDataRequest,
  DownloadDocumentType,
} from '../../requests';

import type { AxiosError } from 'axios';
import type { ApplicationPageFilter, ApplicationPageSearch } from 'generated';

interface Props {
  title: string;
  subTitle?: string;
  exportType: 'APPLICATION' | 'EVALUATION';
  isOpen: boolean;
  onClose: () => void;
  search: ApplicationPageSearch;
  filters: ApplicationPageFilter;
}

const ApplicationDownloadModal = ({
  exportType,
  isOpen,
  onClose,
  filters,
  search,
  title,
  subTitle,
}: Props) => {
  const { showToast, toastType } = useToast();

  const [documentType, setDocumentType] = useState<DownloadDocumentType>(
    DownloadDocumentType.XLSX,
  );
  const [isDownloadQueryEnabled, setIsDownloadQueryEnabled] = useState(false);

  const {
    data: downloadData,
    isLoading: isDownloadDataLoading,
    refetch: downloadDataRefetch,
  } = useQuery(
    queryKeys.APPLICATION_DOWNLOAD(exportType, new Date()),
    () =>
      downloadApplicationsDataRequest(
        exportType,
        documentType,
        filters,
        search,
      ),
    {
      onSuccess: (data) => {
        const blob = new Blob([data.data]);
        const fileUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.style.display = 'none';

        const disposition = data.headers['content-disposition'];
        const filename = disposition
          ?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)?.[1]
          .replace(/['"]/g, '');
        link.download =
          filename ?? `applications.${documentType.toLowerCase()}`;

        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(fileUrl);

        setIsDownloadQueryEnabled(false);
        onClose();
        showToast({ message: '데이터가 성공적으로 다운로드 되었습니다.' });
      },
      onError: (error: AxiosError) => {
        showToast({
          message: `데이터 다운로드에 실패했습니다. [${error.message}]`,
          type: toastType.negative,
        });
        setIsDownloadQueryEnabled(false);
        onClose();
      },
      enabled: isDownloadQueryEnabled,
      staleTime: Infinity,
    },
  );

  const downloadButtonHandler = () => {
    if (downloadData) {
      downloadDataRefetch();
    }
    setIsDownloadQueryEnabled(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <p>{title}</p>
          {subTitle && (
            <Modal.Header.SubTitle>{subTitle}</Modal.Header.SubTitle>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className={styles.wrapper}>
            {Object.entries(DownloadDocumentType).map(([name, value], i) => (
              <label className={styles.radio_wrapper} key={`radio-${i}`}>
                {name}
                <input
                  className={styles.radio_input}
                  type="radio"
                  value={value}
                  checked={documentType === value}
                  onChange={() => setDocumentType(value)}
                />
                <span className={styles.mark} />
              </label>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={downloadButtonHandler}
            isLoading={isDownloadQueryEnabled && isDownloadDataLoading}
          >
            다운로드
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default ApplicationDownloadModal;
