/* eslint-disable @typescript-eslint/no-explicit-any */
import Checkbox from 'components/Checkbox/Checkbox';

import type { Dispatch, SetStateAction } from 'react';
import { memo, useCallback } from 'react';

import NoResult from './NoResults';
import { Table, Tbody, Td, Th, Thead, Tr } from './Table';
import styles from './Table.module.scss';

interface TablePageProps<T> {
  columns: {
    name: string;
  }[];
  contents: T[];
  checkBoxStates: boolean[];
  setCheckBoxStates: Dispatch<SetStateAction<boolean[]>>;
  className?: string | undefined;
  noResultMessage?: string;
}

const TablePage = <T extends object>({
  columns,
  contents,
  checkBoxStates,
  setCheckBoxStates,
  className,
  noResultMessage,
}: TablePageProps<T>) => {
  const checkedCount = checkBoxStates.filter(Boolean).length;
  const showingItemCount = checkBoxStates.length;

  const checkAll = useCallback(
    (check: boolean) => {
      setCheckBoxStates(new Array(showingItemCount).fill(check));
    },
    [showingItemCount],
  );
  if (contents.length === 0) {
    return <NoResult message={noResultMessage || '검색결과가 없습니다.'} />;
  }
  return (
    <section className={className}>
      <Table>
        <Thead>
          <Tr>
            <Th>
              <Checkbox
                onChange={(e) => {
                  checkAll(e.target.checked);
                }}
                isChecked={showingItemCount === checkedCount}
              />
            </Th>
            {columns.map((el: any, i: number) => (
              <Th key={i}>{el.name}</Th>
            ))}
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {contents.map((content: any, index: number) => {
            const list = Object.values(content);
            return (
              <Tr key={index}>
                <Td>
                  <Checkbox
                    onChange={(e) => {
                      setCheckBoxStates([
                        ...checkBoxStates.slice(0, index),
                        e.target.checked,
                        ...checkBoxStates.slice(index + 1),
                      ]);
                    }}
                    isChecked={checkBoxStates[index]}
                  />
                </Td>
                {list.map((el: any, i) => (
                  <Td className={el?.type ? styles.button : ''} key={i}>
                    {el}
                  </Td>
                ))}
              </Tr>
            );
          })}
          <Tr className={styles.bottom} />
        </Tbody>
      </Table>
    </section>
  );
};

const arePropsEqual = <T extends object>(
  prevProps: TablePageProps<T>,
  nextProps: TablePageProps<T>,
) =>
  prevProps.contents === nextProps.contents &&
  prevProps.checkBoxStates === nextProps.checkBoxStates;

export default memo(TablePage, arePropsEqual);
