import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';

import type { Dispatch, SetStateAction } from 'react';

import type { AlertContentType } from 'utils/hooks/useAlert';

interface SurveySetAlertProps {
  isAlertOpen: boolean;
  setIsAlertOpen: Dispatch<SetStateAction<boolean>>;
  alertContent: AlertContentType;
}

const SurveySetAlert = ({
  isAlertOpen,
  setIsAlertOpen,
  alertContent,
}: SurveySetAlertProps) => (
  <Modal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
    <Alert>
      <Alert.Title>
        <p>{alertContent.title}</p>
        <Alert.Title.Caption>{alertContent.caption}</Alert.Title.Caption>
      </Alert.Title>
      <Alert.Footer>
        <Button onClick={alertContent.onDoneClick}>
          {alertContent.doneLabel}
        </Button>
      </Alert.Footer>
    </Alert>
  </Modal>
);

export default SurveySetAlert;
