import styles from './ProductTag.module.scss';

enum InterlockType {
  Bootcamp = 'BOOTCAMP',
  Wewin = 'WEWIN',
}

interface ProductTagProps {
  type: string;
}

const ProductTag = ({ type }: ProductTagProps) => (
  <div
    className={`${styles.tag} ${
      type === InterlockType.Bootcamp ? styles.coz : styles.wewin
    }`}
  >
    <span
      className={`${styles.tag_span} ${
        type === InterlockType.Bootcamp ? styles.coz : styles.wewin
      }`}
    >
      {InterlockType.Bootcamp ? 'SET ID' : 'wewin'}
    </span>
  </div>
);

export default ProductTag;
