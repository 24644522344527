/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserCreateRequest = {
    email?: string;
    name: string;
    role: UserCreateRequest.role;
    mobile?: string;
};

export namespace UserCreateRequest {

    export enum role {
        SUPER_ADMIN = 'SUPER_ADMIN',
        ADMIN = 'ADMIN',
        CREW = 'CREW',
        APPLICANT = 'APPLICANT',
    }


}

