import useHeader from 'utils/hooks/useHeader';

import styles from './DashboardPage.module.scss';

const DashboardPage = () => {
  useHeader({ title: '대시보드', titleTag: '업데이트 예정' });

  return (
    <div>
      <div className="content-wrapper">
        <div className={styles.temporary_cover} />
      </div>
    </div>
  );
};

export default DashboardPage;
