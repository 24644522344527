/* eslint-disable @typescript-eslint/no-unused-vars */

import { VariableResponse } from 'generated';
import queryAxios from 'query/queryAxios';
import queryKeys from 'query/queryKeys';

import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

interface UseVariableQueryType {
  queryKey: number;
  options?: Omit<UseQueryOptions<VariableResponse[]>, 'queryKey' | 'queryFn'>;
}
const getVariables = async (
  itemId: number,
  varTypes: VariableResponse.varType[],
) =>
  queryAxios()
    .get<VariableResponse[]>(
      `/template-items/${itemId}/variables?varTypes=${varTypes}`,
    )
    .then((res) => res.data);

export const useVariableQuery = ({
  queryKey: itemId,
  options,
}: UseVariableQueryType) =>
  useQuery(
    queryKeys.VARIABLE_LIST(itemId, [
      VariableResponse.varType.CUSTOM,
      VariableResponse.varType.FIXED,
    ]),
    () =>
      getVariables(itemId, [
        VariableResponse.varType.CUSTOM,
        VariableResponse.varType.FIXED,
      ]),
    options,
  );
