import classNames from 'classnames';

import React from 'react';

import styles from './RadioButton.module.scss';

type InputValueType = string | number | readonly string[] | undefined;

interface RadioButtonProps {
  name?: string;
  value?: InputValueType;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  children?: string | JSX.Element;
}
const RadioButton = (props: RadioButtonProps) => {
  const { name, value, onChange, className, isChecked, isDisabled, children } =
    props;

  return (
    <div className={styles.radio_item_wrapper}>
      <div className={styles.radio}>
        <input
          className={classNames(styles.radio_origin, className)}
          type={'radio'}
          name={name}
          value={value}
          onChange={onChange}
          disabled={isDisabled}
          checked={isChecked}
        />
        <span className={styles.radio_custom} />
      </div>
      <span className={styles.radio_label}>{children}</span>
    </div>
  );
};

export default RadioButton;
