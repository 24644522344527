/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useContext, useMemo, useState } from 'react';

export interface LoadingState {
  uploadLoading: boolean;
  setUploadLoading: (loading: boolean) => void;
}

const LoadingStateContext = React.createContext<LoadingState>({
  uploadLoading: false,
  setUploadLoading: (loading: boolean) => {},
});

export const LoadingStateConsumer = LoadingStateContext.Consumer;

export const useLoadingState = () => useContext(LoadingStateContext);

export const LoadingStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      uploadLoading,
      setUploadLoading,
    }),
    [uploadLoading, setUploadLoading],
  );

  return (
    <LoadingStateContext.Provider value={value}>
      {children}
    </LoadingStateContext.Provider>
  );
};
