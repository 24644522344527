/* eslint-disable @typescript-eslint/no-use-before-define */

import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import PaginationBar from 'components/PaginationBar/PaginationBar';
import TablePage from 'components/Table/TablePage';
import { Pagination } from 'generated';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import {
  useCreateSurvey,
  useDeleteSurvey,
} from 'utils/hooks/queries/surveys/useSurveyMutation';
import { useGetSurvey } from 'utils/hooks/queries/surveys/useSurveyQuery';
import { useDebounce } from 'utils/hooks/useDebounce';
import useHeader from 'utils/hooks/useHeader';
import useToast from 'utils/hooks/useToast';

import { useState } from 'react';

import CreateSurveyModal from './Modal/CreateSurveyModal/CreateSurveyModal';
import PreviewSurveyModal from './Modal/PreviewSurveyModal/PreviewSurveyModal';
import UpdateSurveyTestTypeModal from './Modal/TestSurveyModal/UpdateSurveyTestTypeModal';
import styles from './SurveyPage.module.scss';
import SurveyPageHeader from './components/SurveyHeader';
import useTable from './hooks/useTable';

const SurveyPage = () => {
  const [searchTitle, setSearchTitle, debouncedSearchTitle] = useDebounce('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);

  const [newSurveyTitle, setNewSurveyTitle, debouncedNewSurveyTitle] =
    useDebounce('');

  const [createModalOn, setCreateModalOn] = useState(false);

  const { showToast, toastType } = useToast();

  const getSurveysParams = {
    search: {
      title: debouncedSearchTitle === '' ? undefined : debouncedSearchTitle,
    },
    pagination: {
      page,
      size: pageSize,
      sortDirection: Pagination.sortDirection.DESC,
      sortFields: ['id'],
    },
  };

  const {
    isFetching: isGetSurveysFetching,
    data: surveys,
    isLoading: isGetSurveysLoading,
    isError: isGetSurveysError,
  } = useGetSurvey({
    params: getSurveysParams,
    options: {
      refetchInterval: 1000 * 60 * 10, // 10m
    },
  });

  const { mutate: createNewSurvey, isLoading: isCreateNewSurveyLoading } =
    useCreateSurvey({
      options: {
        onSuccess: () => {
          setPage(0);
          setSearchTitle('');

          showToast({
            message: '질문지가 성공적으로 복사되었습니다.',
            type: toastType.positive,
          });
          queryClient.invalidateQueries(queryKeys.SURVEY_LIST());
        },
      },
    });

  const { mutate: deleteSurvey, isLoading: isDeleteSurveyLoading } =
    useDeleteSurvey({
      options: {
        onSuccess: () => {
          setPage(0);
          setSearchTitle('');
          showToast({
            message: '질문지가 성공적으로 삭제되었습니다.',
            type: toastType.positive,
          });
          queryClient.invalidateQueries(queryKeys.SURVEY_LIST());
        },
        onError: () => {
          showToast({
            message: '질문지 삭제에 실패했습니다.',
            type: toastType.negative,
          });
        },
      },
    });

  const {
    checkBoxStates,
    setCheckBoxStates,
    content,
    testModal,
    setTestModal,
    previewModal,
    setPreviewModal,
  } = useTable(createNewSurvey, deleteSurvey, surveys);
  useHeader({ title: `질문지 (${surveys?.totalElements || ''})` }, [
    surveys?.totalElements,
  ]);

  const loading =
    isGetSurveysLoading ||
    isCreateNewSurveyLoading ||
    isDeleteSurveyLoading ||
    isGetSurveysFetching;

  if (isGetSurveysError) {
    return <Error isFull message="SurveyListError" />;
  }

  return (
    <div>
      <section>
        <div className="content-wrapper">
          <SurveyPageHeader
            searchTitle={searchTitle}
            setSearchTitle={setSearchTitle}
            setPage={setPage}
            setCreateModalOn={setCreateModalOn}
          />
          {loading ? (
            <Loading isTable />
          ) : (
            <>
              <div className={styles.query_value_table}>
                <TablePage
                  columns={columnProps}
                  contents={content}
                  className={styles.query_value_table}
                  checkBoxStates={checkBoxStates}
                  setCheckBoxStates={setCheckBoxStates}
                  noResultMessage={`"${debouncedSearchTitle}"에 대한 검색결과가 없습니다.`}
                />
              </div>
              <footer>
                <PaginationBar
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={page}
                  setCurrentPage={setPage}
                  total={surveys?.totalElements || 0}
                  options={[50, 30, 10]}
                />
              </footer>
            </>
          )}
        </div>
      </section>

      <CreateSurveyModal
        title={newSurveyTitle}
        setTitle={setNewSurveyTitle}
        titleDebounceQuery={debouncedNewSurveyTitle}
        isOpen={createModalOn}
        onClose={() => setCreateModalOn(false)}
      />
      {previewModal.targetId && (
        <PreviewSurveyModal
          surveyId={previewModal.targetId}
          isOpen={previewModal.isOpen}
          onClose={() => setPreviewModal({ ...previewModal, isOpen: false })}
        />
      )}
      <UpdateSurveyTestTypeModal
        isOpen={testModal.isOpen}
        onClose={() => setTestModal({ ...testModal, isOpen: false })}
        testModal={testModal}
      />
    </div>
  );
};

export default SurveyPage;

const columnProps = [
  {
    name: '질문지 ID',
  },
  {
    name: '질문지 명',
  },
  {
    name: '질문지 유형',
  },
  {
    name: '생성일',
  },
  {
    name: '업데이트일',
  },
  {
    name: '복사',
  },
  {
    name: '미리보기',
  },
  {
    name: '수정',
  },
  {
    name: '삭제',
  },
];
