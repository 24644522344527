import { ReactComponent as IconView } from 'assets/application-view.svg';
import { ReactComponent as IconDone } from 'assets/status-done.svg';
import { ReactComponent as IconNumber } from 'assets/status-number.svg';
import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';
import { useModal } from 'components/Modal/useModal';
import { ApplicationAnswerResponse } from 'generated';
import { isEmpty } from 'lodash-es';
import moment from 'moment';
import { useAlert } from 'utils/hooks/useAlert';
import useToast from 'utils/hooks/useToast';

import styles from './StatusCard.module.scss';
import PreviewSurveySubmissionModal from '../Modal/PreviewSurveySubmissionModal';

import type { TemplateItemFullResponse } from 'generated';

export interface StatusCardProps {
  templateItem: TemplateItemFullResponse | undefined;
  answer: ApplicationAnswerResponse | undefined;
  onChangeStatusSubmitted: (
    itemId?: number | undefined,
    answerId?: number,
    changeFrom?: ApplicationAnswerResponse.status,
  ) => void;
  onChangeStatusCancelled: (
    answerId: number | undefined,
    changeFrom: ApplicationAnswerResponse.status | undefined,
  ) => void;
  isError: boolean;
}

const StatusCard = ({
  templateItem,
  answer,
  onChangeStatusSubmitted,
  onChangeStatusCancelled,
  isError,
}: StatusCardProps) => {
  const isDone = answer?.status === ApplicationAnswerResponse.status.SUBMITTED;

  const { showToast, toastType } = useToast();
  const { isAlertOpen, setIsAlertOpen, alertContent, setAlertContent } =
    useAlert();
  const { isModalOpen, setIsModalOpen, modalContent, setModalContent } =
    useModal();

  const completeSubmissionHandler = () => {
    setAlertContent({
      title: '정말로 해당 지원서를\n제출 처리 하시겠습니까?',
      doneLabel: '확인',
      onDoneClick: () => {
        onChangeStatusSubmitted(templateItem?.id, answer?.id, answer?.status);
        setIsAlertOpen(false);
      },
    });

    setIsAlertOpen(true);
  };

  const cancelSubmissionHandler = () => {
    if (!answer) {
      return;
    }

    setAlertContent({
      title: '정말로 지원자의 제출을\n취소하시겠습니까?',
      doneLabel: '확인',
      onDoneClick: () => {
        onChangeStatusCancelled(answer.id, answer.status);
        setIsAlertOpen(false);
      },
    });
    setIsAlertOpen(true);
  };

  const showPreviewHandler = () => {
    if (!answer) {
      return;
    }

    if (isEmpty(answer.answer)) {
      setAlertContent({
        title: '제출 내용이 없습니다.',
        subTitle: `관리자가 임의로 제출완료 처리를 할 경우,\n제출 내용을 확인할 수 없습니다.`,
        doneLabel: '확인',
        onDoneClick: () => setIsAlertOpen(false),
      });
      setIsAlertOpen(true);
      return;
    }

    if (
      templateItem?.survey &&
      templateItem.survey.definition &&
      answer.answer
    ) {
      setModalContent({
        title: templateItem?.survey?.title ?? '-',
        children: (
          <PreviewSurveySubmissionModal
            definition={templateItem.survey.definition}
            response={answer.answer}
          />
        ),
      });
      setIsModalOpen(true);
      return;
    }

    showToast({
      message: '미리보기 불러오기에 실패했습니다. 다시 시도해주세요.',
      type: toastType.negative,
    });
  };

  return (
    <>
      <Modal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
        <Alert>
          <Alert.Title>
            <p>{alertContent.title}</p>
            {alertContent.subTitle && (
              <Alert.Title.Caption>{alertContent.subTitle}</Alert.Title.Caption>
            )}
          </Alert.Title>
          <Alert.Footer>
            <Button onClick={alertContent.onDoneClick}>
              {alertContent.doneLabel}
            </Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <Modal
        size={'large'}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Modal.Content>
          <Modal.Header>{modalContent.title}</Modal.Header>
          <Modal.Body>{modalContent.children}</Modal.Body>
        </Modal.Content>
      </Modal>

      <div className={styles.wrapper}>
        <div
          className={[
            styles.icon,
            isError ? styles.icon_error : isDone ? styles.icon_done : '',
          ].join(' ')}
        >
          {isError || !isDone ? <IconNumber /> : <IconDone />}
          {isError ? (
            <div className={styles.icon_number}>x</div>
          ) : isDone ? null : (
            <div className={styles.icon_number}>
              {templateItem?.sequence !== undefined
                ? templateItem.sequence + 1
                : 'x'}
            </div>
          )}
        </div>

        <div className={styles.content}>
          <div className={styles.title}>
            {isError
              ? '지원 상태를 가져오는데 실패했습니다.'
              : templateItem?.survey?.title
              ? templateItem.survey.title
              : '-'}
          </div>
          <div className={styles.createdAt}>
            {(isDone &&
              answer &&
              moment(answer.updatedAt).format('YYYY-MM-DD HH:mm:ss')) ??
              '-'}
          </div>

          {isError ? null : isDone ? (
            <div className={styles.controller}>
              <button
                className={styles.btn_cancel}
                onClick={cancelSubmissionHandler}
              >
                <p>제출 취소</p>
              </button>
              <button className={styles.btn_view} onClick={showPreviewHandler}>
                <p>제출 내용 보기</p>
                <IconView />
              </button>
            </div>
          ) : (
            <div className={styles.controller}>
              <button
                className={styles.btn_cancel}
                onClick={completeSubmissionHandler}
              >
                <p>제출 처리</p>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StatusCard;
