import { extendTheme } from '@chakra-ui/react';
import type { Theme } from '@chakra-ui/react';

import { Input } from './styleConfigs/Input';
import { InputGroup } from './styleConfigs/InputGroup';
import { Select } from './styleConfigs/Select';

export const customTheme = {
  colors: {
    primary: '#285E61', // Chakra -> teal.700
    error: '#E53E3E', // Chakra -> red.500
    white: '#ffffff',
    black: '#000000',
    gray: {
      10: 'hsl(0, 0%, 97%)',
      50: 'hsl(0, 0%, 96%)',
      100: 'hsl(0, 0%, 94%)',
      200: 'hsl(0, 0%, 90%)',
      300: 'hsl(0, 0%, 84%)',
      400: 'hsl(0, 0%, 76%)',
      500: 'hsl(0, 0%, 64%)',
      600: 'hsl(0, 0%, 52%)',
      700: 'hsl(0, 0%, 36%)',
      800: 'hsl(0, 0%, 20%)',
      900: 'hsl(0, 0%, 8%)',
    },
    bluishGray: {
      10: 'hsl(219, 25%, 97%)',
      50: 'hsl(219, 25%, 96%)',
      100: 'hsl(219, 25%, 94%)',
      200: 'hsl(219, 25%, 90%)',
      300: 'hsl(219, 25%, 84%)',
      400: 'hsl(219, 25%, 76%)',
      500: 'hsl(219, 25%, 64%)',
      600: 'hsl(219, 25%, 52%)',
      700: 'hsl(219, 25%, 36%)',
      800: 'hsl(219, 25%, 20%)',
      900: 'hsl(219, 25%, 9%)',
    },
    purple: {
      10: 'hsl(248, 72%, 97%)',
      50: 'hsl(248, 72%, 96%)',
      100: 'hsl(248, 72%, 94%)',
      200: 'hsl(248, 72%, 90%)',
      300: 'hsl(248, 72%, 84%)',
      400: 'hsl(248, 72%, 76%)',
      500: 'hsl(248, 72%, 64%)',
      600: 'hsl(248, 72%, 52%)',
      700: 'hsl(248, 72%, 36%)',
      800: 'hsl(248, 72%, 20%)',
      900: 'hsl(248, 72%, 8%)',
    },
    blue: {
      10: 'hsl(223, 75%, 97%)',
      50: 'hsl(223, 75%, 96%)',
      100: 'hsl(223, 75%, 94%)',
      200: 'hsl(223, 75%, 90%)',
      300: 'hsl(223, 75%, 84%)',
      400: 'hsl(223, 75%, 76%)',
      500: 'hsl(223, 75%, 64%)',
      600: 'hsl(223, 75%, 52%)',
      700: 'hsl(223, 75%, 36%)',
      800: 'hsl(223, 75%, 20%)',
      900: 'hsl(223, 75%, 8%)',
    },
    orange: {
      50: 'hsl(24, 100%, 96%)',
      100: 'hsl(24, 100%, 94%)',
      200: 'hsl(24, 100%, 90%)',
      300: 'hsl(24, 100%, 84%)',
      400: 'hsl(24, 100%, 76%)',
      500: 'hsl(24, 100%, 64%)',
      600: 'hsl(24, 100%, 52%)',
      700: 'hsl(24, 100%, 36%)',
    },
    peach: {
      50: 'hsl(11, 100%, 96%)',
      100: 'hsl(11, 100%, 94%)',
      200: 'hsl(11, 100%, 90%)',
      300: 'hsl(11, 100%, 84%)',
      400: 'hsl(11, 100%, 76%)',
      500: 'hsl(11, 100%, 64%)',
      600: 'hsl(11, 100%, 52%)',
      700: 'hsl(11, 100%, 36%)',
    },
    mint: {
      50: 'hsl(155, 100%, 96%)',
      100: 'hsl(155, 100%, 94%)',
      200: 'hsl(155, 100%, 90%)',
      300: 'hsl(155, 100%, 84%)',
      400: 'hsl(155, 100%, 76%)',
      500: 'hsl(155, 100%, 64%)',
      600: 'hsl(155, 100%, 52%)',
      700: 'hsl(155, 100%, 36%)',
    },
  },
  fonts: {
    heading: 'Noto Sans KR, -apple-system, sans-serif',
    body: 'Noto Sans KR, -apple-system, sans-serif',
  },
  textStyles: {
    h1: {
      fontSize: '2.25rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    'h5-bold': {
      fontSize: '1.125rem',
      fontWeight: 700,
    },
    'h5-medium': {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    'h5-regular': {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    'body-bold': {
      fontSize: '1rem',
      fontWeight: 700,
    },
    'body-medium': {
      fontSize: '1rem',
      fontWeight: 500,
    },
    'body-regular': {
      fontSize: '1rem',
      fontWeight: 400,
    },
    't1-medium': {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    't1-regular': {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    't2-medium': {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    't2-regular': {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  },
  components: {
    Input,
    Select,
    InputGroup,
  },
};

export const theme = extendTheme(customTheme);

export type CustomTheme = Theme & typeof customTheme;
