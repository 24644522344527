import queryAxios from 'query/queryAxios';

import type {
  EvalStandardCreateRequest,
  EvalStandardUpdateRequest,
  PageImplTemplateResponse,
  Pagination,
  TemplateForEvalStandardResponse,
  TemplateResponse,
} from 'generated';

interface GetSurveySetListParams {
  search?: {
    title?: string;
  };
  pagination?: {
    page: number;
    size: number;
    sortDirection?: Pagination.sortDirection;
    sortFields?: string[];
  };
}

export const getSurveySetListRequest = async (params: GetSurveySetListParams) =>
  queryAxios().get<PageImplTemplateResponse>('templates', {
    params: { ...params },
  });

export const getSurveySetRequest = async (id: number) =>
  queryAxios().get<TemplateResponse>(`templates/${id}`);

export const getTemplateForEvaluationRequest = async (id: number) =>
  queryAxios().get<TemplateForEvalStandardResponse>(
    `templates/${id}/eva-standards`,
  );

export const createCutOffScoreRequest = async (
  id: number,
  request: EvalStandardCreateRequest,
) =>
  queryAxios().post(`templates/${id}/eva-standards`, {
    ...request,
  });

export const updateCutOffScoreRequest = async (
  id: number,
  request: EvalStandardUpdateRequest,
) =>
  queryAxios().put(`templates/${id}/eva-standards`, {
    ...request,
  });
