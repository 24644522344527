import IsHoverLight from 'pages/ApplicationPage/IsHoverLight';
import { formatDateTime } from 'utils/datetimeFormatter';
import useToast from 'utils/hooks/useToast';

import { useEffect, useState } from 'react';
import { Copy, Edit3, Eye, Trash2 } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import type { UseMutateFunction } from '@tanstack/react-query';

import styles from '../SurveyPage.module.scss';

import type { ContentType } from '../types';
import type { AxiosError, AxiosResponse } from 'axios';
import type {
  PageImplSurveyResponse,
  SurveyCreateRequest,
  SurveyResponse,
} from 'generated';

const useTable = (
  createNewSurvey: UseMutateFunction<
    AxiosResponse<any, any>,
    AxiosError<unknown, any>,
    SurveyCreateRequest,
    unknown
  >,
  deleteSurvey: UseMutateFunction<
    AxiosResponse<any, any>,
    AxiosError<unknown, any>,
    {
      id: number;
    },
    unknown
  >,
  surveys?: PageImplSurveyResponse,
) => {
  const navigate = useNavigate();

  const [checkBoxStates, setCheckBoxStates] = useState<boolean[]>([]);
  const [content, setContent] = useState<ContentType[]>([]);
  const { showToast, toastType } = useToast();
  const [testModal, setTestModal] = useState<{
    targetId?: number;
    targetValue?: boolean;
    isOpen: boolean;
  }>({
    targetId: undefined,
    targetValue: undefined,
    isOpen: false,
  });

  const [previewModal, setPreviewModal] = useState<{
    targetId?: number;
    isOpen: boolean;
  }>({
    targetId: undefined,
    isOpen: false,
  });

  const handleCopySurvey = (copySurveyId?: number, title?: string) => {
    if (copySurveyId === undefined || !title) {
      showToast({
        message: '질문지 복사에 실패했습니다.',
        type: toastType.negative,
      });
      return;
    }

    createNewSurvey({
      title,
      copySurveyId,
    });
  };

  const editTestTypeHandler = (
    surveyId?: number,
    surveyTest?: boolean,
    used?: boolean,
  ) => {
    if (used) {
      showToast({
        message: '사용 중인 질문지는 변경할 수 없습니다.',
        type: toastType.negative,
      });
      return;
    }
    if (surveyId === undefined || surveyTest === undefined) {
      return;
    }

    setTestModal({
      targetId: surveyId,
      targetValue: surveyTest,
      isOpen: true,
    });
  };

  const deleteHandler = (deletedSurveyId?: number, used?: boolean) => {
    if (used) {
      showToast({
        message: '사용중인 질문지는 삭제할 수 없습니다.',
        type: toastType.negative,
      });
      return;
    }
    if (deletedSurveyId === undefined) {
      return;
    }

    deleteSurvey({ id: deletedSurveyId });
  };

  useEffect(() => {
    if (surveys && surveys.content) {
      setCheckBoxStates(new Array(surveys.content.length).fill(false));
      setContent(
        surveys.content.map((survey: SurveyResponse) => {
          const { id, title, used, createdAt, updatedAt } = survey;
          return {
            id,
            title: <IsHoverLight content={title} size="lg" />,
            testButton: (
              <button
                onClick={() =>
                  editTestTypeHandler(survey.id, survey.test, survey.used)
                }
                className={
                  !survey.used
                    ? styles.table_text_button__underlined
                    : styles.table_text_button__disabled
                }
              >
                {survey.test === true ? '테스트' : '일반'}
              </button>
            ),
            createdAt: createdAt ? formatDateTime(createdAt) : '-',
            updatedAt: updatedAt ? formatDateTime(updatedAt) : '-',
            copyButton: (
              <div className={styles.center}>
                <Copy
                  size={18}
                  color="#5C5C5C"
                  onClick={() => {
                    handleCopySurvey(id, title);
                  }}
                />
              </div>
            ),
            previewButton: (
              <div className={styles.center}>
                <Eye
                  size={20}
                  color="#5C5C5C"
                  onClick={() => {
                    setPreviewModal({
                      targetId: survey.id,
                      isOpen: true,
                    });
                  }}
                />
              </div>
            ),
            editButton: (
              <div className={styles.center}>
                <Edit3
                  size={20}
                  onClick={() => navigate(`./${id}`)}
                  color="#5C5C5C"
                />
              </div>
            ),
            deleteButton: (
              <div className={styles.center}>
                <Trash2
                  size={20}
                  onClick={() => deleteHandler(id, used)}
                  color={used ? '#E6E6E6' : '#5C5C5C'}
                />
              </div>
            ),
          };
        }),
      );
    }
  }, [surveys]);
  return {
    content,
    checkBoxStates,
    setCheckBoxStates,
    testModal,
    setTestModal,
    previewModal,
    setPreviewModal,
  };
};

export default useTable;
