import {
  ChevronsLeft,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
} from 'react-feather';

import styles from './Pagination.module.scss';

export interface PaginationProps {
  totalItemCount: number;
  pageSize: number;
  currentPage: number;
  setCurrentPage: (number: number) => void;
}
const Pagination = ({
  totalItemCount,
  pageSize,
  currentPage,
  setCurrentPage,
}: PaginationProps) => {
  if (!totalItemCount) {
    return <></>;
  }
  const pageCount = Math.ceil(totalItemCount / pageSize);
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === pageCount - 1;
  const packagedSetCurrentPage = (num: number) => setCurrentPage(num);
  return (
    <nav className={styles.pagenation}>
      <button
        className={styles.move_button}
        disabled={isFirstPage}
        onClick={() => packagedSetCurrentPage(0)}
      >
        <ChevronsLeft />
      </button>
      <button
        className={styles.move_button}
        disabled={isFirstPage}
        onClick={() => packagedSetCurrentPage(currentPage - 1)}
      >
        <ChevronLeft />
      </button>
      <div className={styles.page_number}>
        Page<span>{currentPage + 1}</span> of <span>{pageCount}</span>
      </div>
      <button
        className={styles.move_button}
        disabled={isLastPage}
        onClick={() => packagedSetCurrentPage(currentPage + 1)}
      >
        <ChevronRight />
      </button>

      <button
        className={styles.move_button}
        disabled={isLastPage}
        onClick={() => packagedSetCurrentPage(pageCount - 1)}
      >
        <ChevronsRight />
      </button>
    </nav>
  );
};

export default Pagination;
