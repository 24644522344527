import moment from 'moment';

import { useState } from 'react';

import type { SxProps } from '@mui/material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import type { InputProps } from '../Input/Input';
import type { Moment } from 'moment';

const format = 'YYYY-MM-DD';

type ModifiedInputProps = Omit<InputProps, 'onChange' | 'value' | 'focusStyle'>;

interface DateInputProps extends ModifiedInputProps {
  value?: string;
  onChange: (value?: string) => void;
  isInvalid?: boolean;
  sx?: SxProps;
}

const DateInput = ({
  value,
  onChange,
  className,
  placeholder = format,
  isFullWidth,
  isFullHeight,
  isInvalid,
  isReadOnly,
  isDisabled,
  sx,
}: DateInputProps) => {
  const [momentValue, setMomentValue] = useState<Moment | null>(
    value ? moment(value) : null,
  );

  const handleOnChange = (newValue: Moment | null) => {
    setMomentValue(newValue);

    const stringValue = newValue !== null ? newValue.format(format) : undefined;
    onChange(stringValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DateField
        className={className}
        inputProps={{ placeholder }}
        value={momentValue}
        onChange={handleOnChange}
        format={format}
        disabled={isDisabled}
        readOnly={isReadOnly}
        sx={{
          outline: 'none',
          border: 'none',
          width: isFullWidth ? '100%' : 'fit-content',
          '& .MuiInputBase-input': {
            height: isFullHeight ? '100%' : '40px',
            padding: '0 16px',
            border: '1px solid #CCD3E0',
            borderColor: isInvalid ? '#FF360A' : '#CCD3E0',
            borderRadius: '4px',
            backgroundColor: '#FFFFFF',
            color: '#333333',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '-0.025em',
            transition: 'color 0.3s, border-color 0.3s',
            fontFamily: 'Noto Sans KR',
            '&::placeholder': {
              color: '#858585',
            },
            '&:read-only': {
              backgroundColor: '#F2F4F7', // Bluish Gray 50
              color: '#455573', // Bluish Gray 600
              border: 'none',
            },
            '&:disabled': {
              color: '#455573', // Bluish Gray 600
              backgroundColor: '#ECEFF4', // Bluish Gray 100
              border: 'none',
              WebkitTextFillColor: 'unset',
              cursor: 'not-allowed',
            },
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },

          ...sx,
        }}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
