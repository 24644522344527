/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactComponent as SurveySetIcon } from 'assets/survey-set.svg';
import Loading from 'components/Loading/Loading';
import { SelectBox } from 'components/SelectBox/SelectBox';
import { Pagination } from 'generated';
import { isNil } from 'lodash-es';
import queryKeys from 'query/queryKeys';
import useHeader from 'utils/hooks/useHeader';
import useToast from 'utils/hooks/useToast';

import { useParams, useNavigate } from 'react-router-dom';

import { useMutation, useQuery } from '@tanstack/react-query';

import styles from './EvaluationStandardPage.module.scss';
import CutOffLineSettingBar from './components/CutOffLineSettingBar/CutOffLineSettingBar';
import { EvaStandardStatus } from './components/SettingStatusBadge/SettingStatusBadge';
import StandardBar from './components/StandardBar/StandardBar';
import {
  createCutOffScoreRequest,
  getSurveySetListRequest,
  getTemplateForEvaluationRequest,
  updateCutOffScoreRequest,
} from './requests';

import type { TemplateItemNoSurveyDetailResponse } from 'generated';

const sortTemplateItemsBySequence = (
  templateItem: TemplateItemNoSurveyDetailResponse[] | undefined,
) => {
  if (!templateItem) {
    return [];
  }

  return [...templateItem].sort((a, b) => {
    if (
      (a.sequence !== 0 && !a.sequence) ||
      (b.sequence !== 0 && !b.sequence)
    ) {
      return 0;
    }
    return a.sequence - b.sequence;
  });
};

const EvaluationStandardPage = () => {
  const { showToast, toastType } = useToast();
  const surveySetId = Number(useParams().templateId) || 0;
  const navigate = useNavigate();

  useHeader({ title: '심사 기준 관리', titleTag: '' });

  const getSurveySetsParams = {
    search: {
      title: '',
    },
    pagination: {
      page: 0,
      size: 100,
      sortDirection: Pagination.sortDirection.DESC,
      sortFields: ['id'],
    },
  };

  const { data: surveySetListData, isLoading: isSurveySetListLoading } =
    useQuery(queryKeys.SURVEYSET_LIST(getSurveySetsParams), () =>
      getSurveySetListRequest(getSurveySetsParams),
    );

  const { data: surveySetForEvaData, refetch: refetchSurveySetForEvaData } =
    useQuery(
      queryKeys.SURVEYSET_FOR_EVA_DETAIL_BY_ID(surveySetId),
      () => getTemplateForEvaluationRequest(surveySetId),
      {
        enabled: !!surveySetId,
      },
    );

  const { mutate: createCutOffScore } = useMutation(
    (cutOffScore: number) =>
      createCutOffScoreRequest(surveySetId, { scoreCutline: cutOffScore }),
    {
      onSuccess: () => {
        showToast({
          message: '최종 점수 커트라인을 저장합니다.',
          type: toastType.positive,
        });
        refetchSurveySetForEvaData();
      },
    },
  );

  const { mutate: updateCutOffScore } = useMutation(
    (cutOffScore: number) =>
      updateCutOffScoreRequest(surveySetId, { scoreCutline: cutOffScore }),
    {
      onSuccess: () => {
        showToast({
          message: '최종 점수 커트라인을 저장합니다.',
          type: toastType.positive,
        });
        refetchSurveySetForEvaData();
      },
    },
  );

  const handleSelectBoxChange = (title: string) => {
    const clickedSurveySet = surveySetListData?.data?.content?.find(
      (surveySet) => surveySet.title === title,
    );
    if (clickedSurveySet) {
      navigate(`/application/evaluation-standard/${clickedSurveySet.id}`, {
        replace: true,
      });
    }
  };

  const handleCutOffScoreSave = (score: number) => {
    if (!surveySetForEvaData?.data.beforeAnnounce) {
      showToast({
        message: '합격발표일이 지나 심사 기준을 변경할 수 없는 상태입니다.',
        type: toastType.negative,
      });
      return;
    }

    if (isNil(surveySetForEvaData?.data?.evalStandard?.scoreCutline)) {
      createCutOffScore(score);
    } else {
      updateCutOffScore(score);
    }
  };

  return (
    <>
      <div className={`${styles.contents_wrapper} content-wrapper expanded`}>
        {isSurveySetListLoading ? (
          <Loading isTable />
        ) : (
          <>
            <div className={styles.contents_box}>
              <div className={styles.contents_header}>
                <p>질문지 세트 선택</p>
                <div className={styles.header_inner}>
                  <div className={styles.select_box_wrapper}>
                    <SelectBox
                      className={styles.select_box}
                      value={surveySetForEvaData?.data?.title}
                      options={
                        surveySetListData?.data?.content?.map((surveySet) => ({
                          name: surveySet.title || '',
                          value: surveySet.title || '',
                        })) || []
                      }
                      placeholder="질문지 세트를 선택해주세요."
                      onChange={(title) => handleSelectBoxChange(title)}
                    />
                  </div>
                  {surveySetId && !surveySetForEvaData?.data.beforeAnnounce ? (
                    <p>
                      합격발표일이 지나 심사 기준을 변경할 수 없는 상태입니다.
                    </p>
                  ) : null}
                </div>
              </div>
              <div
                className={`${styles.contents_main} ${
                  !surveySetForEvaData?.data && styles.not_chosen
                }`}
              >
                {surveySetForEvaData?.data ? (
                  <div className={styles.standard_bar_box}>
                    <div className={styles.standard_bar_main}>
                      <p>질문지 심사 기준</p>
                      <ul>
                        {sortTemplateItemsBySequence(
                          surveySetForEvaData.data.items,
                          // eslint-disable-next-line array-callback-return
                        ).map((item) => {
                          if (item.id) {
                            return (
                              <StandardBar
                                key={item.id}
                                itemId={item.id}
                                index={(item.sequence || 0) + 1}
                                settingStatus={
                                  item.hasError
                                    ? EvaStandardStatus.ERROR
                                    : item.hasLogic
                                    ? EvaStandardStatus.COMPLETED
                                    : EvaStandardStatus.INCOMPLETE
                                }
                                surveyTitle={item.survey?.title || ''}
                              />
                            );
                          }
                        })}
                      </ul>
                    </div>
                    <div className={styles.setting_bar}>
                      <p>기타 설정</p>
                      <CutOffLineSettingBar
                        cutOffScore={
                          surveySetForEvaData?.data?.evalStandard?.scoreCutline
                        }
                        handleCutOffScoreSave={handleCutOffScoreSave}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles.info_box}>
                    <SurveySetIcon />
                    <p>질문지 세트 선택 후 심사 기준을 관리할 수 있습니다.</p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EvaluationStandardPage;
