import classNames from 'classnames';

import React from 'react';

import styles from './Alert.module.scss';

const AlertTitle = ({ children }: React.PropsWithChildren) => (
  <div className={styles.alert_title}>{children}</div>
);

interface AlertTitleCaptionProps {
  isTinted?: boolean;
}
const AlertTitleCaption: React.FC<
  React.PropsWithChildren<AlertTitleCaptionProps>
> = ({ isTinted = true, children }) => (
  <p
    className={classNames(
      styles.alert_title_caption,
      isTinted && styles.tinted,
    )}
  >
    {children}
  </p>
);

export default Object.assign(AlertTitle, {
  Caption: AlertTitleCaption,
});
