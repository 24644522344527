import { ConditionResponse } from 'generated';
import { produce } from 'immer';
import { find, findIndex, last, remove, omit } from 'lodash';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

import { generateClientId, validateLogic } from './util';

import type {
  ExecutionResponse,
  VariableResponse,
  LogicResponse,
} from 'generated';
import type { StoreApi, UseBoundStore } from 'zustand';

export interface Logic extends LogicResponse {
  isValid?: boolean;
  clientId: string;
}

interface LogicStoreType {
  logics: Logic[];
  actions: {
    initLogics: (logics: LogicResponse[]) => void;
    clearLogics: () => void;
    addLogic: () => void;
    updateLogic: (logic: Logic) => void;
    deleteLogic: (clientId: string) => void;
    copyLogic: (clientId: string) => void;
    reorderLogics: (logics: Logic[]) => void;
    syncLogicSequence: () => void;
    updateLogicIsValid: (variables: VariableResponse[]) => Logic[];
    cleanLogicIsValid: (clientId: string) => void;
    updateLogicType: (clientId: string, type: LogicResponse.type) => void;
    addLogicExecution: (clientId: string) => void;
    updateLogicExecution: (
      clientId: string,
      execution: ExecutionResponse,
    ) => void;
    deleteLogicExecution: (clientId: string, sequence: number) => void;
    syncLogicExecutionSequence: (clientId: string) => void;
    addLogicCondition: (clientId: string) => void;
    updateLogicCondition: (
      clientId: string,
      condition: ConditionResponse,
    ) => void;
    deleteLogicCondition: (clientId: string, sequence: number) => void;
    syncLogicConditionSequence: (clientId: string) => void;
  };
}

const logicsStore: UseBoundStore<StoreApi<LogicStoreType>> = create(
  (set, get) => ({
    logics: [],
    actions: {
      initLogics: (logics) =>
        set({
          logics: logics.map((logic) => ({
            ...logic,
            clientId: generateClientId(),
          })),
        }),
      clearLogics: () => set({ logics: [] }),
      addLogic: () =>
        set((state) =>
          produce(state, (draft) => {
            const lastSequence = last(draft.logics)?.sequence;

            draft.logics.push({
              sequence: lastSequence !== undefined ? lastSequence + 1 : 0,
              conditions: [],
              executions: [],
              clientId: generateClientId(),
            });
          }),
        ),
      updateLogic: (logic) =>
        set((state) =>
          produce(state, (draft) => {
            const index = findIndex(draft.logics, { clientId: logic.clientId });
            if (index !== -1) {
              draft.logics[index] = logic;
            }
          }),
        ),
      deleteLogic: (clientId) => {
        set((state) =>
          produce(state, (draft) => {
            const index = findIndex(draft.logics, { clientId });
            if (index !== -1) {
              draft.logics.splice(index, 1);
            }
          }),
        );
        get().actions.syncLogicSequence();
      },
      copyLogic: (clientId) => {
        set((state) =>
          produce(state, (draft) => {
            const index = findIndex(draft.logics, { clientId });
            if (index !== -1) {
              const copyObj = {
                ...omit(state.logics[index], ['id', 'clientId']),
                clientId: generateClientId(),
              };
              draft.logics.splice(index + 1, 0, copyObj);
            }
          }),
        );
        get().actions.syncLogicSequence();
      },
      reorderLogics: (logics) => {
        set({ logics });
        get().actions.syncLogicSequence();
      },
      syncLogicSequence: () =>
        set((state) =>
          produce(state, (draft) => {
            draft.logics.forEach((logic, idx) => {
              logic.sequence = idx;
            });
          }),
        ),
      updateLogicIsValid: (variables: VariableResponse[]) => {
        set((state) =>
          produce(state, (draft) => {
            draft.logics.forEach((logic) => {
              logic.isValid = validateLogic(logic, variables);
            });
          }),
        );
        return get().logics;
      },
      cleanLogicIsValid: (clientId: string) => {
        set((state) =>
          produce(state, (draft) => {
            const index = findIndex(draft.logics, { clientId });
            if (index !== -1) {
              const omitObj = omit(draft.logics[index], ['isValid']);
              draft.logics.splice(index, 1, omitObj);
            }
          }),
        );
      },
      updateLogicType: (clientId, value) =>
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            if (logic) {
              logic.type = value;
              logic.executions = [];
              logic.conditions = [];
            }
          }),
        ),
      addLogicExecution: (clientId) =>
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            if (logic?.executions) {
              const { executions } = logic;
              const lastSequence = executions[executions.length - 1]?.sequence;
              const newSequence =
                lastSequence !== undefined ? lastSequence + 1 : 0;
              logic.executions.push({ sequence: newSequence });
            }
          }),
        ),
      updateLogicExecution: (clientId, value) =>
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            if (logic?.executions) {
              const { executions } = logic;
              const index = findIndex(executions, { sequence: value.sequence });
              if (index !== -1) {
                executions[index] = value;
              }
            }
          }),
        ),
      deleteLogicExecution: (clientId, sequence) => {
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            if (logic?.executions) {
              remove(
                logic.executions,
                (execution) => execution.sequence === sequence,
              );
            }
          }),
        );
        get().actions.syncLogicExecutionSequence(clientId);
      },
      syncLogicExecutionSequence: (clientId) =>
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            logic?.executions?.forEach((execution, idx) => {
              execution.sequence = idx;
            });
          }),
        ),
      addLogicCondition: (clientId) => {
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            if (logic?.conditions) {
              const { conditions } = logic;
              const lastSequence = conditions[conditions.length - 1]?.sequence;
              const newSequence =
                lastSequence !== undefined ? lastSequence + 1 : 0;
              logic.conditions.push({
                sequence: newSequence,
                logicGate:
                  newSequence === 0
                    ? ConditionResponse.logicGate.NONE
                    : ConditionResponse.logicGate.AND,
              });
            }
          }),
        );
      },
      updateLogicCondition: (clientId, value) =>
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            if (logic?.conditions) {
              const { conditions } = logic;
              const index = findIndex(conditions, { sequence: value.sequence });
              if (index !== -1) {
                conditions[index] = value;
              }
            }
          }),
        ),
      deleteLogicCondition: (clientId, sequence) => {
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            if (logic?.conditions) {
              remove(
                logic.conditions,
                (condition) => condition.sequence === sequence,
              );
            }
          }),
        );
        get().actions.syncLogicConditionSequence(clientId);
      },
      syncLogicConditionSequence: (clientId) =>
        set((state) =>
          produce(state, (draft) => {
            const logic = find(draft.logics, { clientId });
            logic?.conditions?.forEach((condition, idx) => {
              condition.sequence = idx;
              if (idx === 0) {
                condition.logicGate = ConditionResponse.logicGate.NONE;
              }
            });
          }),
        ),
    },
  }),
);
logicsStore.subscribe((state) => console.log(state.logics));

export const useLogics = () => logicsStore((state) => state.logics, shallow);
export const useLogicsActions = () =>
  logicsStore((state) => state.actions, shallow);

interface VariableStoreType {
  variables: VariableResponse[];
  actions: {
    initVariables: (variables: VariableResponse[]) => void;
    clearVariables: () => void;
  };
}

export const variablesStore: UseBoundStore<StoreApi<VariableStoreType>> =
  create((set) => ({
    variables: [],
    actions: {
      initVariables: (variables) => set({ variables }),
      clearVariables: () => set({ variables: [] }),
      // TODO: variable interface 작성하기
    },
  }));
variablesStore.subscribe((state) => console.log(state.variables));

export const useVariables = () =>
  variablesStore((state) => state.variables, shallow);
export const useVariablesActions = () =>
  variablesStore((state) => state.actions, shallow);
