import { isNil } from 'lodash-es';

import queryAxios from '../../query/queryAxios';

import type {
  PageImplTemplateResponse,
  Pagination,
  SurveyChangeTestRequest,
} from '../../generated';

interface GetSurveySetListParams {
  search?: {
    title?: string;
  };
  pagination?: {
    page: number;
    size: number;
    sortDirection?: Pagination.sortDirection;
    sortFields?: string[];
  };
}

export const getSurveySetListRequest = async (params: GetSurveySetListParams) =>
  queryAxios().get<PageImplTemplateResponse>('templates', {
    params: { ...params },
  });

export const deleteSurveySetRequest = async (id?: number | null) => {
  if (isNil(id)) {
    return;
  }
  return queryAxios().delete(`templates/${id}`);
};

export const copySurveySetRequest = async (id?: number | null) => {
  if (isNil(id)) {
    return;
  }
  return queryAxios().post(`templates/${id}/copy`);
};

export const updateSurveyOnDetailRequest = async (
  id: number,
  request: SurveyChangeTestRequest,
) => queryAxios().patch(`surveys/${id}`, { ...request });
