import classNames from 'classnames';

import React, { useEffect, useRef } from 'react';

import { useModalContext } from './Modal';
import styles from './Modal.module.scss';
import { useModalStyleContext } from './ModalContent';

const ModalFooter = ({ children }: React.PropsWithChildren) => {
  const { size } = useModalContext();
  const { setFooterRef } = useModalStyleContext();
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (setFooterRef && ref.current) {
      setFooterRef(ref.current);
    }
  }, [ref]);

  return (
    <footer
      ref={ref}
      className={classNames(
        styles.modal_footer,
        size === 'large' && styles.modal_footer_large,
      )}
    >
      {size === 'default'
        ? React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
              return React.cloneElement(child, {
                ...child.props,
                className: classNames(
                  child.props.className,
                  'modal_footer_button',
                ),
                isFullWidth: true,
                size: child.props.size ?? 'large',
              });
            }
            return child;
          })
        : React.Children.toArray(children)[0]}
    </footer>
  );
};

export default ModalFooter;
