import useHeader from 'utils/hooks/useHeader';

import styles from './AdminPage.module.scss';

const AdminPage = () => {
  useHeader({
    title: '관리자 권한',
    titleTag: '업데이트 예정',
  });

  return (
    <div>
      <div className="content-wrapper">
        <div className={styles.temporary_cover} />
      </div>
    </div>
  );
};
export default AdminPage;
