import Input from 'components/Input/Input';

import { Clock, Eye } from 'react-feather';

import styles from '../SurveySetEditorPage.module.scss';

import type { SurveyResponse } from 'generated';

interface SurveySettingModalProps {
  surveyRepo: (SurveyResponse | undefined)[];
  selectedSurvey?: SurveyResponse;
  handleDisplayTitleInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  isDisplayTitleError: boolean;
  handleTimeRequiredInputChange: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  isTimeRequiredError: boolean;
  displayTitleTypingLimit: number;
}

const SurveySettingModal = ({
  selectedSurvey,
  handleDisplayTitleInputChange,
  isDisplayTitleError,
  handleTimeRequiredInputChange,
  isTimeRequiredError,
  displayTitleTypingLimit,
}: SurveySettingModalProps) => (
  <div className={styles.survey_setting_modal}>
    <div className={styles.setting_item}>
      <div className={styles.setting_item_title}>
        <Eye /> 지원자 표시 제목
      </div>
      <div className={styles.setting_item_input_title}>
        <Input
          placeholder={'지원 단계에 표시될 제목을 작성해 주세요.'}
          defaultValue={
            selectedSurvey?.displayTitle || selectedSurvey?.title || ''
          }
          onChange={handleDisplayTitleInputChange}
        />
      </div>
      <div
        className={`${styles.alert_message} ${
          !isDisplayTitleError ? styles.hidden : ''
        }`}
      >
        {`${displayTitleTypingLimit}자까지 입력할 수 있습니다.`}
      </div>
    </div>
    <div className={styles.setting_item}>
      <div className={styles.setting_item_title}>
        <Clock />
        예상 소요 시간
        <span> *</span>
      </div>
      <div className={styles.setting_item_input_time}>
        <Input
          placeholder={'00'}
          type="number"
          onChange={handleTimeRequiredInputChange}
          defaultValue={selectedSurvey?.nextRequiredTime}
          isFullWidth={false}
        />
        <span>분</span>
      </div>
      <div
        className={`${styles.alert_message} ${
          !isTimeRequiredError ? styles.hidden : ''
        }`}
      >
        필수 입력 항목입니다.
      </div>
    </div>
  </div>
);

export default SurveySettingModal;
