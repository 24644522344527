import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';

import styles from './CheckUpdateEvaluationModal.module.scss';

interface Props {
  userName: string;
  isOpen: boolean;
  onClose: () => void;
  onDoneBtnClicked: () => void;
}

const CheckUpdateEvaluationModal = ({
  userName,
  isOpen,
  onClose,
  onDoneBtnClicked,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} height={287}>
    <Modal.Content>
      <Modal.Header height={47} />
      <Modal.Body>
        <section className={styles.wrapper}>
          <div>{userName} 지원자의 심사 결과가 변경됩니다.</div>
          <div>정말로 저장하시겠습니까?</div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={'light_solid'} onClick={onClose}>
          다시 생각해볼게요.
        </Button>
        <Button onClick={onDoneBtnClicked}>네 저장합니다.</Button>
      </Modal.Footer>
    </Modal.Content>
  </Modal>
);

export default CheckUpdateEvaluationModal;
