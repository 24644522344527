/* eslint-disable @typescript-eslint/no-use-before-define */
import { VariableResponse, ConditionResponse } from 'generated';
import { find, concat } from 'lodash';

import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useLogicsActions, useVariables } from '../../../store';
import {
  conditionOperatorBasicOptions,
  conditionOperatorArithmeticOptions,
  conditionOperatorChoiceOptions,
  conditionOperatorAnswerOptions,
} from '../data';
import { ConditionLeftType, InputStyle } from '../type';

import type { ConditionProps } from './Condition';

import VarType = VariableResponse.varType;

const useCondition = ({ clientId, condition, isValid }: ConditionProps) => {
  const variables = useVariables();
  const { updateLogicCondition, deleteLogicCondition, cleanLogicIsValid } =
    useLogicsActions();
  const [leftType, setLeftType] = useState<ConditionLeftType>();

  const withCleanValidation =
    <T extends any[]>(handler: (...args: T) => void) =>
    (...args: T) => {
      handler(...args);
      cleanLogicIsValid(clientId);
    };

  const isInvalidActive = useMemo(() => isValid === false, [isValid]);

  const handleDeleteCondition = () => {
    if (condition.sequence === undefined) {
      return;
    }
    deleteLogicCondition(clientId, condition.sequence);
  };

  const handleLogicGateChange = withCleanValidation(
    (value: ConditionResponse.logicGate) => {
      if (condition.logicGate === value) {
        return;
      }
      updateLogicCondition(clientId, {
        ...condition,
        logicGate: value,
      });
    },
  );

  // left
  const leftVariable: VariableResponse | undefined = useMemo(() => {
    if (!condition.leftVariableId) {
      return;
    }
    return find(variables, { id: condition.leftVariableId });
  }, [condition.leftVariableId]);

  const leftVariableOptions = useMemo(() => {
    if (!leftType) {
      return;
    }
    const filterAndConvert = (varTypes: VarType[]) =>
      variables
        .filter((v) => v.varType && varTypes.includes(v.varType))
        .map((v) => ({
          value: v.id,
          name: varTypes.includes(VarType.ANSWER)
            ? `[${v.name}] ${v.title}`
            : v.title ?? '',
        }));

    switch (leftType) {
      case ConditionLeftType.VARIABLE:
        return filterAndConvert([VarType.CUSTOM, VarType.FIXED]);
      case ConditionLeftType.ANSWER:
        return filterAndConvert([VarType.ANSWER]);
      default:
        return [];
    }
  }, [variables, leftType]);

  const handleLeftTypeChange = withCleanValidation(
    (value: ConditionLeftType) => {
      if (leftType === value) {
        return;
      }
      setLeftType(value);
      updateLogicCondition(clientId, {
        ...condition,
        operator: undefined,
        leftVariableId: undefined,
      });
    },
  );

  const handleLeftVariableChange = withCleanValidation((value: number) => {
    if (condition.leftVariableId === value) {
      return;
    }
    updateLogicCondition(clientId, {
      ...condition,
      operator: undefined,
      leftVariableId: value,
      rightVariableId: undefined,
      rightValue: undefined,
    });
  });

  // right
  const rightInputStyle: InputStyle = useMemo(() => {
    if (!leftType || !leftVariable) {
      return InputStyle.DISABLED;
    }

    if (
      condition.operator === ConditionResponse.operator.IS_EMPTY ||
      condition.operator === ConditionResponse.operator.IS_NOT_EMPTY
    ) {
      return InputStyle.BLOCKED;
    }

    const { valueType } = leftVariable;
    if (valueType) {
      switch (valueType) {
        case VariableResponse.valueType.CHOICE:
        case VariableResponse.valueType.LIST:
          return InputStyle.CHOICE;
        case VariableResponse.valueType.DATE:
          return InputStyle.DATE;
        case VariableResponse.valueType.NUMBER:
          return InputStyle.NUMBER;
        default:
          return InputStyle.STRING;
      }
    }
    return InputStyle.DISABLED;
  }, [leftType, leftVariable, condition.operator]);

  const rightChoiceOptions = useMemo(() => {
    if (rightInputStyle === InputStyle.CHOICE && leftVariable?.choices) {
      return leftVariable.choices.map(({ value, text }) => ({
        value,
        name: text ?? '',
      }));
    }
  }, [leftVariable, rightInputStyle]);

  const handleRightInputChanged = withCleanValidation(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      updateLogicCondition(clientId, {
        ...condition,
        rightValue: value,
      });
    },
  );

  const handleRightSelectChanged = withCleanValidation((value: string) => {
    if (value === condition.rightValue) {
      return;
    }
    const choice = find(leftVariable?.choices, { value });
    updateLogicCondition(clientId, {
      ...condition,
      rightValue: choice?.value,
    });
  });

  const handleRightDateChanged = withCleanValidation((value?: string) => {
    if (value === condition.rightValue) {
      return;
    }
    updateLogicCondition(clientId, {
      ...condition,
      rightValue: value,
    });
  });

  const operatorOptions = useMemo(() => {
    const isChoice =
      leftVariable?.valueType === VariableResponse.valueType.CHOICE ||
      leftVariable?.valueType === VariableResponse.valueType.LIST;
    const isArithmetic =
      leftVariable?.valueType === VariableResponse.valueType.DATE ||
      leftVariable?.valueType === VariableResponse.valueType.NUMBER;
    const isAnswer = leftType === ConditionLeftType.ANSWER;

    return concat(
      conditionOperatorBasicOptions,
      isChoice ? conditionOperatorChoiceOptions : [],
      isArithmetic ? conditionOperatorArithmeticOptions : [],
      isAnswer ? conditionOperatorAnswerOptions : [],
    );
  }, [leftType, leftVariable]);

  const handleOperatorChange = withCleanValidation(
    (value: ConditionResponse.operator) => {
      if (condition.operator === value) {
        return;
      }
      updateLogicCondition(clientId, {
        ...condition,
        operator: value,
      });
    },
  );

  const preventInputOnWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
    e.stopPropagation();
  };

  useEffect(() => {
    const leftVariable = find(variables, { id: condition.leftVariableId });

    if (!leftVariable) {
      return;
    }
    if (
      leftVariable.varType === VarType.CUSTOM ||
      leftVariable.varType === VarType.FIXED
    ) {
      setLeftType(ConditionLeftType.VARIABLE);
    }
    if (leftVariable.varType === VarType.ANSWER) {
      setLeftType(ConditionLeftType.ANSWER);
    }
  }, []);

  return {
    isInvalidActive,
    handleDeleteCondition,
    handleLogicGateChange,
    // left
    leftType,
    leftVariable,
    leftVariableOptions,
    handleLeftTypeChange,
    handleLeftVariableChange,
    // right
    rightInputStyle,
    rightChoiceOptions,
    handleRightInputChanged,
    handleRightSelectChanged,
    handleRightDateChanged,
    // operator
    operatorOptions,
    handleOperatorChange,
    // etc
    preventInputOnWheel,
  };
};

export default useCondition;
