/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import DateInput from 'components/DateInput/DateInput';
import Equal from 'components/Equal/Equal';
import Input from 'components/Input/Input';
import { SelectBox } from 'components/SelectBox/SelectBox';
import { VariableResponse } from 'generated';
import useHandler from 'pages/VariablePage/useHandler';

import React, { useState } from 'react';
import { Trash2 } from 'react-feather';

import styles from './VariableItem.module.scss';
import ChoiceValue from '../ChoiceValue';

import type { VariableCardsType } from 'pages/VariablePage/types';

const options = [
  { name: '숫자', value: VariableResponse.valueType.NUMBER },
  { name: '텍스트', value: VariableResponse.valueType.STRING },
  { name: '선택형', value: VariableResponse.valueType.CHOICE },
  { name: '날짜', value: VariableResponse.valueType.DATE },
];

interface VariableItemProps {
  variableCards: VariableResponse[];
  setVariableCards: React.Dispatch<React.SetStateAction<VariableResponse[]>>;
  thisIndex: number;
  thisCard: VariableCardsType;
  id?: number;
  beforeAnnounce?: boolean;
}

const VariableItem = ({
  id,
  variableCards,
  setVariableCards,
  thisIndex,
  thisCard,
  beforeAnnounce,
}: VariableItemProps) => {
  const readOnly = !!id || !beforeAnnounce;
  const [isHover, setIsHover] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState({ title: '', value: '' });

  const currentVariableType = options.filter(
    (option) => option.value === thisCard.valueType,
  )[0];

  const {
    handleTitleChange,
    handleDeleteCards,
    handleValueChange,
    handleValueTypeChange,
    handleChoiceValueChange,
    handleDateValueChange,
  } = useHandler(
    errorMessage,
    setErrorMessage,
    variableCards,
    setVariableCards,
    thisIndex,
    thisCard,
  );

  const rightField = () => {
    switch (thisCard.valueType) {
      case VariableResponse.valueType.CHOICE: {
        return (
          <ChoiceValue
            readOnly={readOnly}
            thisCard={thisCard}
            handleChoiceValueChange={handleChoiceValueChange}
          />
        );
      }
      case VariableResponse.valueType.DATE: {
        return (
          <DateInput
            isReadOnly={readOnly}
            value={thisCard.defaultValue}
            onChange={handleDateValueChange}
            isFullWidth
            isInvalid={!!errorMessage.value}
          />
        );
      }
      default:
        return (
          <Input
            isReadOnly={readOnly}
            value={thisCard.defaultValue || ''}
            onChange={handleValueChange}
            isInvalid={!!errorMessage.value}
            placeholder="기본 값 작성 (선택)"
            className={styles.variable_input}
          />
        );
    }
  };

  return (
    <li
      className={styles.item_wrapper}
      onMouseOver={() => {
        if (thisCard.varType !== VariableResponse.varType.FIXED) {
          setIsHover(true);
        }
      }}
      onMouseOut={() => {
        if (thisCard.varType !== VariableResponse.varType.FIXED) {
          setIsHover(false);
        }
      }}
    >
      <div className={styles.inputs_container}>
        <div
          className={`${styles.inputs_container_input_left} ${
            errorMessage.title ? styles.error : ''
          }`}
        >
          <Input
            isReadOnly={!!readOnly}
            isInvalid={!!errorMessage.title}
            value={thisCard.title || ''}
            onChange={handleTitleChange}
            className={styles.variable_input}
          />
          <span className={styles.inputs_container_input_left_error}>
            {errorMessage.title}
          </span>
        </div>
        <div className={styles.inputs_container_equal}>
          <Equal />
        </div>
        <div className={styles.inputs_container_dropdown}>
          {readOnly ? (
            <Input
              isReadOnly
              isInvalid={false}
              value={currentVariableType.name || ''}
              isFullHeight
              className={styles.variable_input}
            />
          ) : (
            <SelectBox
              value={thisCard.valueType}
              onChange={handleValueTypeChange}
              options={options}
              isFullHeight
              className={styles.inputs_container_dropdown}
              alignItems="left"
            />
          )}
        </div>
        <div className={styles.inputs_container_input_right}>
          {rightField()}

          <span className={styles.inputs_container_input_right_error}>
            {errorMessage.value}
          </span>
        </div>
      </div>

      <div className={styles.trash}>
        {isHover && <Trash2 size={20} onClick={handleDeleteCards} />}
      </div>
    </li>
  );
};

export default VariableItem;
