import { LogicResponse, ConditionResponse } from 'generated';
import { find } from 'lodash';

import type { VariableResponse, ExecutionResponse } from 'generated';

export const validateLogic = (
  logic: LogicResponse,
  variables: VariableResponse[],
): boolean => {
  if (
    !logic.type ||
    logic.sequence === undefined ||
    !logic.executions?.length ||
    !logic.conditions ||
    (logic.type === LogicResponse.type.IFTHEN && logic.conditions.length === 0)
  ) {
    return false;
  }

  const validate = (target: ConditionResponse | ExecutionResponse): boolean => {
    const { leftVariableId, operator, rightVariableId, rightValue } = target;

    if (
      !leftVariableId ||
      !operator ||
      (operator !== ConditionResponse.operator.IS_EMPTY &&
        operator !== ConditionResponse.operator.IS_NOT_EMPTY &&
        !rightVariableId &&
        !rightValue)
    ) {
      return false;
    }

    if (!find(variables, { id: leftVariableId })) {
      return false;
    }

    if (!!rightVariableId && !find(variables, { id: rightVariableId })) {
      return false;
    }

    return true;
  };

  for (const condition of logic.conditions) {
    if (validate(condition) === false) {
      return false;
    }
  }

  for (const execution of logic.executions) {
    if (validate(execution) === false) {
      return false;
    }
  }

  return true;
};

export const generateClientId = () =>
  `client-${Math.floor(Math.random() * Date.now())}`;

export const getPromise = (execute: () => void): Promise<string> => {
  execute();
  return new Promise((resolve) => {
    resolve('done');
  });
};
