import type { ComponentStyleConfig } from '@chakra-ui/react';

export const InputGroup: ComponentStyleConfig = {
  variants: {
    default: {
      field: {
        bg: 'white',
        border: '1px solid',
        borderColor: 'bluishGray.200',
        color: 'bluishGray.400',
        fontStyle: 'body-regular',
        _focus: {
          color: 'bluishGray.700',
        },
        _disabled: {
          bgColor: 'bluishGray.100',
          color: 'bluishGray.500',
          borderColor: 'bluishGray.300',
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
};
