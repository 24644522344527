/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LandingPageUpdateRequest = {
    bootCampCategory?: LandingPageUpdateRequest.bootCampCategory;
    sequence?: number;
    contents?: string;
    alt?: string;
    id?: number;
};

export namespace LandingPageUpdateRequest {

    export enum bootCampCategory {
        ALL = 'ALL',
        SEBFE = 'SEBFE',
        SEBBE = 'SEBBE',
        UUB = 'UUB',
        GMB = 'GMB',
        DOB = 'DOB',
        PMB = 'PMB',
        BEB = 'BEB',
        AIB = 'AIB',
    }


}

