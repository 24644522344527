import { Button } from 'components/Button/Button';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import queryKeys from 'query/queryKeys';
import { formatDateTime } from 'utils/datetimeFormatter';
import useHeader from 'utils/hooks/useHeader';

import { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import styles from './SurveySetInfoPage.module.scss';
import PreviewSurveyModal from '../SurveyPage/Modal/PreviewSurveyModal/PreviewSurveyModal';
import BasicInfoSection from '../SurveySetEditorPage/components/BasicInfoSection/BasicInfoSection';
import ProductSection from '../SurveySetEditorPage/components/ProductSection/ProductSection';
import SurveySection from '../SurveySetEditorPage/components/SurveySection/SurveySection';
import { getSurveySetRequest } from '../SurveySetEditorPage/request';

import type { PreviewSurveyState } from '../SurveyPage/Modal/PreviewSurveyModal/PreviewSurveyModal';
import type { SurveyResponse } from 'generated';

const SurveySetInfoPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useHeader({
    title: '질문지 세트 등록 및 수정',
    pageInfo: [
      { page: '질문지 세트', onClick: () => navigate('/survey/set') },
      { page: '질문지 세트 정보 보기' },
    ],
  });

  const [previewSurvey, setPreviewSurvey] = useState<PreviewSurveyState>({
    surveyId: undefined,
    isOpen: false,
  });

  const {
    data: surveySetData,
    isLoading: isSurveySetLoading,
    isError: isSurveySetError,
  } = useQuery(queryKeys.SURVEYSET_DETAIL_BY_ID(Number(id)), () =>
    getSurveySetRequest(Number(id)),
  );

  const handlePreviewButtonClick = (clickedSurvey: SurveyResponse) => {
    setPreviewSurvey({
      isOpen: true,
      surveyId: clickedSurvey.id,
    });
  };

  if (isSurveySetLoading) {
    return <Loading isFull />;
  }
  if (isSurveySetError) {
    return <Error isFull />;
  }

  return (
    <>
      {previewSurvey.surveyId && (
        <PreviewSurveyModal
          surveyId={previewSurvey.surveyId}
          isOpen={previewSurvey.isOpen}
          onClose={() => setPreviewSurvey({ ...previewSurvey, isOpen: false })}
        />
      )}

      <div className={`content-wrapper ${styles.set_editor_page_wrapper}`}>
        <div className={styles.main_contents}>
          <BasicInfoSection
            page={'info'}
            id={id}
            title={surveySetData?.data.title || ''}
            slugUrl={surveySetData?.data.slugUrl || ''}
          />
          <SurveySection
            page={'info'}
            surveys={surveySetData?.data.items?.map((survey) => survey.survey)}
            handlePreviewButtonClick={handlePreviewButtonClick}
          />
          <ProductSection
            products={
              surveySetData?.data.product ? [surveySetData.data.product] : []
            }
          />
        </div>
        <div className={styles.content_footer}>
          <div className={styles.date_info}>
            <span>{`생성일: ${formatDateTime(
              surveySetData?.data.createdAt || '',
            )} | 최종 업데이트일: ${formatDateTime(
              surveySetData?.data.updatedAt || '',
            )}`}</span>
          </div>
          <div className={styles.button_box}>
            <Link to="/survey/set">
              <Button size="big" variant="solid">
                확인
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveySetInfoPage;
