const koreanStringStart = 44032; // 가
const koreanStringEnd = 55203; // 힣
const numberString0 = 48;
const numberString9 = 57;
const numberCodesEndWithVowel = [48, 49, 51, 54, 55, 56]; // 영, 일, 삼, 육, 칠, 팔
const numberCodesEndWithLieul = [49, 55, 56]; // 일, 칠, 팔

export const isEndWithVowel = (target: string) => {
  const lastStringCode = target.charCodeAt(target.length - 1);
  // check is Number
  if (lastStringCode >= numberString0 && lastStringCode <= numberString9) {
    return numberCodesEndWithVowel.includes(lastStringCode);
  }
  // check is English
  if (lastStringCode < koreanStringStart || lastStringCode > koreanStringEnd) {
    return false;
  }
  return (lastStringCode - koreanStringStart) % 28 !== 0;
};

export const isEndWithLieul = (target: string) => {
  const lastStringCode = target.charCodeAt(target.length - 1);
  if (lastStringCode >= numberString0 && lastStringCode <= numberString9) {
    return numberCodesEndWithLieul.includes(lastStringCode);
  }
  if (lastStringCode < koreanStringStart || lastStringCode > koreanStringEnd) {
    return false;
  }
  return (lastStringCode - koreanStringStart) % 28 === 8;
};

const josaTypes = ['을', '를', '로', '으로', '이', '가'] as const;
type JosaType = (typeof josaTypes)[number];

export const changeJosa = (target: string, josa: string) => {
  const matched = josaTypes.find((type) => type === josa);
  if (!matched) {
    return josa;
  }

  const typeCheckedJosa = josa as JosaType;
  const endWithVowel = isEndWithVowel(target);
  const endWidthLieul = isEndWithLieul(target);

  switch (typeCheckedJosa) {
    case '을':
    case '를':
      return endWithVowel ? '을' : '를';
    case '이':
    case '가':
      return endWithVowel ? '이' : '가';
    case '로':
    case '으로':
      return endWithVowel && !endWidthLieul ? '으로' : '로';
    default:
  }
};
