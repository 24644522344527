import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}
const ResetLogicsAlert = ({ isOpen, onConfirm, onDismiss }: Props) => (
  <Modal isOpen={isOpen} onClose={onDismiss}>
    <Alert>
      <Alert.Title>현재 질문지 심사 기준을 초기화 합니다.</Alert.Title>
      <Alert.Footer>
        <Button onClick={onConfirm}>확인</Button>
      </Alert.Footer>
    </Alert>
  </Modal>
);

export default ResetLogicsAlert;
