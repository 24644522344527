/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserBasicInfo } from './UserBasicInfo';

export type ApplicationPageResponse = {
    id?: number;
    uuid?: string;
    createdBy?: number;
    createdAt?: string;
    updatedBy?: number;
    updatedAt?: string;
    academy?: ApplicationPageResponse.academy;
    paymentModel?: ApplicationPageResponse.paymentModel;
    productName?: string;
    templateName?: string;
    organizationName?: string;
    applyingStatus?: ApplicationPageResponse.applyingStatus;
    evaluationStatus?: ApplicationPageResponse.evaluationStatus;
    intentionStatus?: ApplicationPageResponse.intentionStatus;
    memo?: string;
    user?: UserBasicInfo;
    answerProgress?: string;
    productOrTemplateName?: string;
};

export namespace ApplicationPageResponse {

    export enum academy {
        BOOTCAMP = 'BOOTCAMP',
        B2B = 'B2B',
        WEWIN = 'WEWIN',
    }

    export enum paymentModel {
        UPFRONT = 'UPFRONT',
        WEWIN = 'WEWIN',
        GOV_FUNDING = 'GOV_FUNDING',
        B2B = 'B2B',
        IMPACT_CAMPUS = 'IMPACT_CAMPUS',
        HYBRID = 'HYBRID',
        SCHOLARSHIP = 'SCHOLARSHIP',
    }

    export enum applyingStatus {
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        COMPLETED = 'COMPLETED',
        INCOMPLETED = 'INCOMPLETED',
    }

    export enum evaluationStatus {
        NONE = 'NONE',
        WAITING = 'WAITING',
        IN_PROGRESS = 'IN_PROGRESS',
        PASSED = 'PASSED',
        FAILED = 'FAILED',
        RELIEVED = 'RELIEVED',
        RELIEVE_WAIT = 'RELIEVE_WAIT',
        DISQUALIFIED = 'DISQUALIFIED',
        RESIGNED = 'RESIGNED',
    }

    export enum intentionStatus {
        INITIALIZE = 'INITIALIZE',
        CONFIRMED = 'CONFIRMED',
        REVOKED = 'REVOKED',
        NON_RESPONSE = 'NON_RESPONSE',
    }


}

