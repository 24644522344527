/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ApplicationAnswerResponse = {
    id?: number;
    uuid?: string;
    status?: ApplicationAnswerResponse.status;
    answer?: Record<string, Record<string, any>>;
    itemId?: number;
    spentTime?: number;
    resubmitCount?: number;
    lastAnswerQuestion?: string;
    lastAnswerPage?: string;
    lastAnswerPageNumber?: number;
    createdAt?: string;
    updatedAt?: string;
};

export namespace ApplicationAnswerResponse {

    export enum status {
        CREATED = 'CREATED',
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        SUBMITTED = 'SUBMITTED',
        RECREATED = 'RECREATED',
    }


}

