/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Pagination = {
    page?: number;
    size?: number;
    sortDirection?: Pagination.sortDirection;
    sortFields?: Array<string>;
};

export namespace Pagination {

    export enum sortDirection {
        ASC = 'ASC',
        DESC = 'DESC',
    }


}

