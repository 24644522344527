/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionResponse } from './ConditionResponse';
import type { ExecutionResponse } from './ExecutionResponse';

export type LogicResponse = {
    id?: number;
    type?: LogicResponse.type;
    sequence?: number;
    conditions?: Array<ConditionResponse>;
    executions?: Array<ExecutionResponse>;
};

export namespace LogicResponse {

    export enum type {
        ALWAYS = 'ALWAYS',
        IFTHEN = 'IFTHEN',
    }


}

