import { Button } from 'components/Button/Button';
import SearchBox from 'components/SearchBox/SearchBox';

import type { Dispatch, SetStateAction } from 'react';

import styles from '../SurveyPage.module.scss';

const SurveyPageHeader = ({
  searchTitle,
  setSearchTitle,
  setPage,
  setCreateModalOn,
}: {
  searchTitle: string;
  setSearchTitle: Dispatch<SetStateAction<string>>;
  setPage: Dispatch<SetStateAction<number>>;
  setCreateModalOn: Dispatch<SetStateAction<boolean>>;
}) => (
  <div className={styles.search_wrapper}>
    <div className={styles.searchbox_wrapper}>
      <SearchBox
        value={searchTitle}
        onChange={(e) => {
          setPage(0);
          setSearchTitle(e.target.value);
        }}
        placeholder="질문지 명을 입력해주세요."
        className={styles.query_value_searchbox}
        isFullWidth
      />
    </div>
    <div className={styles.button_box}>
      <Button onClick={() => setCreateModalOn(true)}>질문지 등록</Button>
    </div>
  </div>
);

export default SurveyPageHeader;
