/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable array-callback-return */

import { ErrorCode } from 'constants/serverErrorType';

import PageFooter from 'components/PageFooter/PageFooter';
import { VariableResponse } from 'generated';
import { getTemplateForEvaluationRequest } from 'pages/EvaluationStandardPage/requests';
import {
  getDuplicatedVariableIndexs,
  getNoNameVariablesIndexs,
  setSaveErrorCards,
} from 'pages/VariablePage/util/saveErrorHandler';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import { useVariableMutation } from 'utils/hooks/queries/variables/useVariableMutation';
import { useVariableQuery } from 'utils/hooks/queries/variables/useVariableQuery';
import useToast from 'utils/hooks/useToast';
import { scrollToBottom } from 'utils/scrollToBottom';

import { useEffect, useMemo, useState } from 'react';
import { flushSync } from 'react-dom';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import styles from './VariableSection.module.scss';
import LeavePageAlert from '../LeavePageAlert/LeavePageAlert';
import VariableCards from '../VariableCards';

import type { AxiosError } from 'axios';
import type { ErrorConfigType } from 'constants/serverErrorType';
import type {
  CurrentCardTypes,
  VariableCardsType,
} from 'pages/VariablePage/types';

const VariableSection = () => {
  const { showToast, toastType } = useToast();
  const params = useParams<{ templateId: string; itemId: string }>();
  const { useSaveVariable } = useVariableMutation();
  const templateId = Number(params.templateId);
  const itemId = Number(params.itemId);

  const [variableCards, setVariableCards] = useState<VariableCardsType[]>([]);

  const { data: variables } = useVariableQuery({
    queryKey: itemId,
    options: { onError: (err: unknown) => console.error(err) },
  });

  const { data: surveySetForEvaData } = useQuery(
    queryKeys.SURVEYSET_FOR_EVA_DETAIL_BY_ID(templateId),
    () => getTemplateForEvaluationRequest(templateId),
    {
      enabled: !!templateId,
    },
  );

  const { mutateAsync: createVariable } = useSaveVariable({
    params: itemId,
    options: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.VARIABLE_LIST(itemId),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.SURVEYSET_FOR_EVA_DETAIL_BY_ID(templateId),
        });
      },
      onError: (err) => {
        const _variableCards = variableCards || [];
        handleSaveError(err, _variableCards);
      },
    },
  });

  useEffect(() => {
    if (variables) {
      setVariableCards(variables);
    }
  }, [variables]);

  const beforeAnnounce = useMemo(() => {
    if (surveySetForEvaData) {
      return surveySetForEvaData.data.beforeAnnounce;
    }
    return false;
  }, [surveySetForEvaData?.data.id]);

  const selectedItemTitle = useMemo(() => {
    if (surveySetForEvaData) {
      return (
        surveySetForEvaData.data?.items?.find((item) => item.id === itemId)
          ?.survey?.title || ''
      );
    }
    return '';
  }, [surveySetForEvaData?.data.id]);

  const currentCards: CurrentCardTypes[] | undefined = useMemo(
    () =>
      variableCards?.map(
        ({
          id,
          title,
          valueType,
          defaultValue,
          choices,
        }: VariableResponse) => ({
          id,
          name: title?.trim() || '',
          valueType: valueType || VariableResponse.valueType.NUMBER,
          defaultValue: defaultValue || undefined,
          choices,
        }),
      ),
    [variableCards],
  );

  const onAdd = () => {
    const newVariables = {
      varType: VariableResponse.varType.CUSTOM,
      name: '',
      title: '',
      valueType: VariableResponse.valueType.NUMBER,
      defaultValue: '',
      editable: true,
    };

    flushSync(() => setVariableCards([...variableCards, newVariables]));
    scrollToBottom();
  };

  const onSave = () => {
    if (!variableCards || !currentCards) {
      return;
    }

    createVariable(currentCards);
  };

  const isAllSaved = variableCards
    ? !variableCards.find((card) => !card.id)
    : true;

  const handleSaveError = (
    err: unknown,
    variableCards: VariableCardsType[],
  ) => {
    const { code, message, target } = (err as AxiosError).response
      ?.data as ErrorConfigType;

    switch (code) {
      case ErrorCode.EMPTY_FIELD: {
        const errIndexs: number[] =
          getNoNameVariablesIndexs(currentCards) || [];

        setSaveErrorCards(variableCards, setVariableCards, code, errIndexs);

        showToast({
          message: '완성되지 않은 변수가 있습니다.',
          type: toastType.negative,
        });
        break;
      }
      case ErrorCode.DUPLICATED_NAME: {
        if (target) {
          const errIndexs: number[] =
            getDuplicatedVariableIndexs(target, currentCards) || [];

          setSaveErrorCards(variableCards, setVariableCards, code, errIndexs);

          showToast({
            message: '중복된 변수명은 입력할 수 없습니다.',
            type: toastType.negative,
          });
        }
        break;
      }
      default:
        showToast({
          message,
          type: toastType.negative,
        });
    }
  };

  return (
    <>
      <section className={styles.wrapper}>
        <VariableCards
          variableCards={variableCards}
          setVariableCards={setVariableCards}
          beforeAnnounce={beforeAnnounce}
        />
      </section>

      <PageFooter
        variant="변수"
        title={selectedItemTitle}
        count={variableCards?.length || 0}
        onAdd={onAdd}
        onSave={onSave}
        beforeAnnounce={beforeAnnounce}
      />
      <LeavePageAlert isAllSaved={isAllSaved} />
    </>
  );
};

export default VariableSection;
