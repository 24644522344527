import { useNavigate } from 'react-router-dom';

import { ReactComponent as Error } from '../assets/error.svg';
import { Button } from '../components/Button/Button';

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="modal-page-wrapper">
      <Error width={125} height={125} />
      <div style={{ marginBottom: '30px', textAlign: 'center' }}>
        <p className="text-style-h5" style={{ color: '#667BA3' }}>
          존재하지 않는 페이지입니다.
        </p>
        <p className="text-style-t1" style={{ color: '#667BA3' }}>
          Nothing to see here.
        </p>
      </div>

      <Button size="large" variant="solid" onClick={() => navigate('/')}>
        <p>홈으로 이동하기</p>
      </Button>
    </div>
  );
};

export default ErrorPage;
