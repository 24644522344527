import styles from './StatusSummary.module.scss';
import { ApplicationFullResponse } from '../../../generated';

export const applyingStatusOptions = [
  {
    name: '지원 중',
    value: ApplicationFullResponse.applyingStatus.IN_PROGRESS,
  },
  {
    name: '지원 완료',
    value: ApplicationFullResponse.applyingStatus.COMPLETED,
  },
  {
    name: '지원 미완료',
    value: ApplicationFullResponse.applyingStatus.INCOMPLETED,
  },
  {
    name: '지원 취소',
    value: ApplicationFullResponse.applyingStatus.CANCELLED,
  },
];

interface Props {
  progress: string | undefined;
  applyingStatus: ApplicationFullResponse.applyingStatus | undefined;
}

const StatusSummary = ({ progress, applyingStatus }: Props) => {
  if (!progress || !applyingStatus) {
    return <div className="text-style-t2">지원 진행상태가 없습니다.</div>;
  }

  const splitString = progress.split('/');
  const progress_did = splitString[0];
  const progress_whole = splitString[1];
  const isInProgress = applyingStatus === 'IN_PROGRESS';

  return (
    <div className={styles.wrapper}>
      <div className={styles.progress_wrapper}>
        <span className={styles.number_did}>{progress_did}</span>
        <span className={styles.divider}>/</span>
        <span className={styles.number_whole}>{progress_whole}</span>
      </div>
      <div
        className={[
          styles.tag,
          isInProgress ? styles.in_progress : styles.done,
        ].join(' ')}
      >
        <p>
          {applyingStatusOptions.find((x) => x.value === applyingStatus)
            ?.name ?? '지원상태'}
        </p>
      </div>
    </div>
  );
};
export default StatusSummary;
