export const formatDateTime = (datetime: string, joinWith = '.') =>
  datetime.slice(0, 10).split('-').join(joinWith);

export const formatDateTimeDetail = (datetime: string, joinWith = '.') => {
  const myDate = new Date(datetime);

  const hour = myDate.getHours();
  const minutes = myDate.getMinutes();
  const second = myDate.getSeconds();
  return `${formatDateTime(datetime, joinWith)} ${hour}:${minutes}:${second}`;
};
