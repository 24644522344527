import { ArrowRight } from 'react-feather';

import styles from './ProductSectionTab.module.scss';
import { ProductResponse } from '../../../../generated';
import getUrls from '../../../../utils/urls';
import ProductTag from '../../../SurveySetPage/components/ProductTag/ProductTag';

interface ProductSectionTabProps {
  productType?: ProductResponse.academy;
  name: string;
  id: string;
}

const ProductSectionTab = ({
  productType = ProductResponse.academy.BOOTCAMP,
  name,
}: ProductSectionTabProps) => {
  const handleTabClick = () => {
    window.open(getUrls('product').cod_admin);
    // COD 서버 Uuid로 상품 검색 기능 개발 시 윗 줄 삭제 후 아랫 줄 활성화
    // window.open(getUrls(`product/${id}/edit`).admin);
  };

  return (
    <div
      className={styles.tab_wrapper}
      onClick={handleTabClick}
      onKeyDown={handleTabClick}
      role="button"
      tabIndex={0}
    >
      <div className={styles.info}>
        <ProductTag type={productType} />
        {/* 추후 WEWIN에서 사용할 부분 */}
        {/* <div className={styles.institution}>{institution}</div> */}
        <div className={styles.name}>{name}</div>
      </div>
      <div className={styles.svg_wrapper}>
        <ArrowRight />
      </div>
    </div>
  );
};

export default ProductSectionTab;
