import { Button } from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal';

import { useEffect, useState } from 'react';

import styles from './MemoModal.module.scss';

import type { UpdateApplicationMemoInputType } from '../../types';

interface Props {
  input: UpdateApplicationMemoInputType;
  isOpen: boolean;
  onClose: () => void;
  updateMemoMutator: (newMemo: string) => void;
}

const MemoModal = ({ input, isOpen, onClose, updateMemoMutator }: Props) => {
  const { name, memo } = input;
  const [newMemo, setNewMemo] = useState<string>();

  useEffect(() => {
    if (isOpen) {
      setNewMemo(memo);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <span className={styles.coloredTitle}>{name}</span> 지원자 메모
        </Modal.Header>
        <Modal.Body>
          <Input
            value={newMemo}
            onChange={(e) => setNewMemo(e.target.value)}
            isFullWidth
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => updateMemoMutator(newMemo ?? '')}>
            저장하기
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default MemoModal;
