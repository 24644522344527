import { ReactComponent as CheckDone } from 'assets/check-done-icon.svg';
import { ReactComponent as Setting } from 'assets/setting-icon.svg';
import { Button } from 'components/Button/Button';

import { useNavigate } from 'react-router-dom';

import styles from './StandardBar.module.scss';
import SettingStatusBadge from '../SettingStatusBadge/SettingStatusBadge';

import type { SettingStatus } from '../../type';

interface StandardBarProps {
  index: number;
  itemId: number;
  settingStatus: SettingStatus;
  surveyTitle: string;
}

const StandardBar = ({
  index,
  itemId,
  settingStatus,
  surveyTitle,
}: StandardBarProps) => {
  const navigate = useNavigate();

  return (
    <li className={styles.bar_wrapper}>
      <div className={styles.index_box}>
        <p>{index}</p>
      </div>
      <div className={styles.bar_content}>
        <div className={styles.main_content}>
          <SettingStatusBadge
            className={styles.status_badge}
            settingStatus={settingStatus}
          />
          <p className={styles.survey_title}>{surveyTitle}</p>
        </div>
        <div className={styles.button_box}>
          {/* TODO: section3 & section4 완료 후 onClick 함수 할당 */}
          <Button
            variant="solid-gray"
            size="small"
            rightIcon={<Setting />}
            onClick={() => navigate(`./variable/${itemId}`)}
          >
            변수 관리
          </Button>
          {/* TODO: section3 & section4 완료 후 onClick 함수 할당 */}
          <Button
            variant="solid-gray"
            size="small"
            rightIcon={<CheckDone />}
            onClick={() => navigate(`./logic/${itemId}`)}
          >
            규칙 관리
          </Button>
        </div>
      </div>
    </li>
  );
};

export default StandardBar;
