import styles from './Toast.module.scss';
import { ReactComponent as IconFailure } from '../../assets/toast-failure.svg';
import { ReactComponent as IconSuccess } from '../../assets/toast-success.svg';
import { ReactComponent as IconClose } from '../../assets/toast-x.svg';

export const POSITIVE = 'POSITIVE' as const;
export const NEGATIVE = 'NEGATIVE' as const;
type toastType = typeof POSITIVE | typeof NEGATIVE;

export interface ToastProps {
  message: string;
  type?: toastType;
  onClose?: () => void;
}

const Toast = ({ message, type = POSITIVE, onClose }: ToastProps) => (
  <div
    className={`${styles.Toast} ${
      type === POSITIVE ? styles.Toast_positive : styles.Toast_negative
    }`}
  >
    {type === POSITIVE ? (
      <IconSuccess className={styles.Toast_icon} />
    ) : (
      <IconFailure className={styles.Toast_icon} />
    )}
    <p className="Toast-message">{message}</p>
    <IconClose className={styles.btn_close} onClick={onClose} />
  </div>
);

export default Toast;
