/* eslint-disable no-irregular-whitespace */
import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';
import { useAlert } from 'utils/hooks/useAlert';
import useToast from 'utils/hooks/useToast';

import { useState } from 'react';
import { ArrowDown } from 'react-feather';

import ApplicationDownloadModal from '../Modal/ApplicationDownloadModal/ApplicationDownloadModal';

import type {
  ApplicationPageFilter,
  ApplicationPageResponse,
  ApplicationPageSearch,
} from 'generated';

interface DownloadSurveyButtonProps {
  filters: ApplicationPageFilter;
  search: ApplicationPageSearch;
  products: { name: string; value?: number }[];
  applications: ApplicationPageResponse[];
  hasCheckedApplicationIds: boolean;
  hasCheckedApplicationIncompleted: boolean;
}

const DownloadSurveyButton = ({
  filters,
  search,
  products,
  hasCheckedApplicationIds,
  hasCheckedApplicationIncompleted,
  applications,
}: DownloadSurveyButtonProps) => {
  const isDisabled =
    filters.productId === undefined || applications.length === 0;
  const { isAlertOpen, setIsAlertOpen, alertContent, setAlertContent } =
    useAlert();
  const { showToast, toastType } = useToast();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClickHandler = () => {
    // 1. 상품이 필터링 되지 않았을 때
    if (filters.productId === undefined) {
      showToast({
        message: '데이터 다운로드를 위해 특정 상품 필터링을 걸어주세요.',
        type: toastType.negative,
      });
      return;
    }

    if (applications.length === 0) {
      showToast({
        message: '다운로드 할 데이터가 없습니다.',
        type: toastType.negative,
      });
      return;
    }

    // 3. 체크된 데이터가 있을 때
    if (hasCheckedApplicationIds) {
      setAlertContent({
        title: `선택된 지원자들에 대한 지원 데이터를\n다운로드 합니다.`,
        subTitle: hasCheckedApplicationIncompleted
          ? '지원이 완료되지 않은 데이터가 포함되어 있습니다.\n선택된 데이터의 지원 상태를 확인해주세요.'
          : '데이터의 항목(column)을 변경하지 말아주세요.',
        doneLabel: '확인',
        onDoneClick: () => {
          setIsAlertOpen(false);
          setIsModalOpen(true);
        },
      });
    } else {
      // 4. 체크된 데이터가 없을 때
      const filteredProduct = products.find(
        (product) => Number(product.value) === filters.productId,
      )?.name;

      setAlertContent({
        title: `"${filteredProduct}"에 대한 지원 데이터를\n 다운로드 합니다.`,
        subTitle: hasCheckedApplicationIncompleted
          ? '지원이 완료되지 않은 데이터가 포함되어 있습니다.'
          : '데이터의 항목(column)을 변경하지 말아주세요.',
        doneLabel: '확인',
        onDoneClick: () => {
          setIsAlertOpen(false);
          setIsModalOpen(true);
        },
      });
    }
    setIsAlertOpen(true);
  };

  return (
    <>
      <ApplicationDownloadModal
        exportType="EVALUATION"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        filters={filters}
        search={search}
        title="데이터 다운로드"
        subTitle={`데이터 형식을 선택해주세요.\n(업로드를 위해서는 칼럼을 변경하지 말아주세요.)`}
      />

      <Modal isOpen={isAlertOpen} onClose={() => setIsAlertOpen(false)}>
        <Alert>
          <Alert.Title>
            <p>{alertContent.title}</p>
            <Alert.Title.Caption>{alertContent.subTitle}</Alert.Title.Caption>
          </Alert.Title>
          <Alert.Footer>
            <Button onClick={alertContent.onDoneClick}>
              {alertContent.doneLabel}
            </Button>
          </Alert.Footer>
        </Alert>
      </Modal>

      <Button
        isDisabled={isDisabled}
        onClick={onClickHandler}
        rightIcon={<ArrowDown />}
      >
        질문지 다운로드
      </Button>
    </>
  );
};

export default DownloadSurveyButton;
