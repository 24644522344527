import { ApplicationPageFilter, ApplicationPageResponse } from 'generated';

import type { ApplicationPageSearch } from 'generated';

export const searchDropdownOptions: {
  name: string;
  value: keyof ApplicationPageSearch;
}[] = [
  { name: '이름', value: 'name' },
  { name: '이메일', value: 'email' },
  { name: '휴대전화', value: 'mobile' },
  { name: '유저 ID', value: 'userId' },
  { name: '지원 ID', value: 'applicationIds' },
];

export const filterDropdownOptions: {
  name: string;
  value: keyof ApplicationPageFilter;
}[] = [
  { name: '교육 기관', value: 'organizationId' },
  { name: '교육 상품', value: 'productId' },
  { name: '질문지 세트', value: 'templateId' },
  { name: '결제 모델', value: 'paymentModel' },
  { name: '지원 상태', value: 'applyingStatus' },
  { name: '심사 상태', value: 'evaluationStatus' },
  { name: '탑승 희망', value: 'intentionStatus' },
  { name: '메모 상태', value: 'hasMemo' },
];

export const paymentStatusOptions = [
  { name: '모든 결제 모델', value: undefined },
  { name: 'UPFRONT', value: ApplicationPageResponse.paymentModel.UPFRONT },
  {
    name: 'GOV FUNDING',
    value: ApplicationPageResponse.paymentModel.GOV_FUNDING,
  },
  { name: 'WEWIN', value: ApplicationPageResponse.paymentModel.WEWIN },
  { name: 'B2B', value: ApplicationPageResponse.paymentModel.B2B },
  {
    name: 'IMPACT CAMPUS',
    value: ApplicationPageResponse.paymentModel.IMPACT_CAMPUS,
  },
  // { name: 'ISA', value: ApplicationPageResponse.paymentModel.ISA },
  // { name: 'DEFERRED', value: ApplicationPageResponse.paymentModel.DEFERRED },
];

export const applyingStatusOptions = [
  { name: '모든 지원 상태', value: undefined },
  {
    name: '지원 중',
    value: ApplicationPageFilter.applyingStatus.IN_PROGRESS,
  },
  {
    name: '지원 완료',
    value: ApplicationPageFilter.applyingStatus.COMPLETED,
  },
  {
    name: '지원 미완료',
    value: ApplicationPageFilter.applyingStatus.INCOMPLETED,
  },
  {
    name: '지원 취소',
    value: ApplicationPageFilter.applyingStatus.CANCELLED,
  },
];

export const evaluationStatusOptions = [
  { name: '모든 심사 상태', value: undefined },
  { name: '합격', value: ApplicationPageFilter.evaluationStatus.PASSED },
  { name: '불합격', value: ApplicationPageFilter.evaluationStatus.FAILED },
  { name: '구제', value: ApplicationPageFilter.evaluationStatus.RELIEVED },
  { name: '대기', value: ApplicationPageFilter.evaluationStatus.WAITING },
  {
    name: '구제 대기',
    value: ApplicationPageFilter.evaluationStatus.RELIEVE_WAIT,
  },
  { name: '결격', value: ApplicationPageFilter.evaluationStatus.DISQUALIFIED },
  { name: '포기', value: ApplicationPageFilter.evaluationStatus.RESIGNED },
  { name: '-', value: ApplicationPageFilter.evaluationStatus.NONE },
];

export const intentionStatusOptions = [
  { name: '모든 탑승 희망 상태', value: undefined },
  { name: '탑승 희망', value: ApplicationPageFilter.intentionStatus.CONFIRMED },
  { name: '탑승 비희망', value: ApplicationPageFilter.intentionStatus.REVOKED },
  { name: '미제출', value: ApplicationPageFilter.intentionStatus.NON_RESPONSE },
  { name: '-', value: ApplicationPageFilter.intentionStatus.INITIALIZE },
];

export const memoStatusOptions = [
  { name: '모든 메모 상태', value: undefined },
  { name: '메모 있음 (O)', value: true },
  { name: '메모 없음 (X)', value: false },
];

export const columnProps = [
  {
    name: '지원 ID',
  },
  {
    name: '유저 ID',
  },
  {
    name: '이름',
  },
  {
    name: '이메일',
  },
  {
    name: '휴대전화',
  },
  {
    name: '상품/질문세트명',
  },
  {
    name: '결제 모델',
  },
  {
    name: '지원 상태',
  },
  {
    name: '진행량',
  },
  {
    name: '심사 상태',
  },
  {
    name: '탑승 희망',
  },
  {
    name: '메모',
  },
];
