import { Button } from 'components/Button/Button';
import SearchBox from 'components/SearchBox/SearchBox';

import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import styles from '../SurveySetPage.module.scss';

interface SurveySetPageHeaderProps {
  input: string;
  setInput: Dispatch<SetStateAction<string>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}
const SurveySetPageHeader = ({
  input,
  setInput,
  setCurrentPage,
}: SurveySetPageHeaderProps) => (
  <div className={styles.content_header}>
    <div className={styles.searchbox_wrapper}>
      <SearchBox
        value={input}
        placeholder="질문지 세트명을 입력해주세요."
        onChange={(e) => {
          setCurrentPage(0);
          setInput(e.target.value);
        }}
        isFullWidth
      />
    </div>
    <Link to={`./new`}>
      <div className={styles.button_box}>
        <Button isFullWidth>질문지 세트 등록</Button>
      </div>
    </Link>
  </div>
);

export default SurveySetPageHeader;
