import classNames from 'classnames';
import useOnClickOutside from 'hooks/useOnClickOutside';

import React, { useMemo, useState, useRef, useEffect } from 'react';

import { useModalContext } from './Modal';
import styles from './Modal.module.scss';

interface ModalStyleContextType {
  headerRef?: HTMLElement | null;
  footerRef?: HTMLElement | null;
  setHeaderRef?: (ref: HTMLElement) => void;
  setFooterRef?: (ref: HTMLElement) => void;
}

const ModalStyleContext = React.createContext<ModalStyleContextType>({});
export const useModalStyleContext = () => React.useContext(ModalStyleContext);

const ModalContent = ({ children }: React.PropsWithChildren) => {
  const { size, height, onClose } = useModalContext();
  const [refs, setRefs] = useState<{
    header?: HTMLElement | null;
    footer?: HTMLElement | null;
  }>();
  const setHeaderRef = (headerRef: HTMLElement) =>
    setRefs({ ...refs, header: headerRef });
  const setFooterRef = (footerRef: HTMLElement) =>
    setRefs({ ...refs, footer: footerRef });

  const contentRef = useRef<HTMLDivElement | null>(null);
  const onCloseRef = useRef<() => void>();
  useOnClickOutside(contentRef, onCloseRef.current);

  const memoizedStyle = useMemo(
    () => (height ? { height: `${height}px` } : undefined),
    [height],
  );

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  const value = useMemo(
    () => ({
      headerRef: refs?.header,
      footerRef: refs?.footer,
      setHeaderRef,
      setFooterRef,
    }),
    [refs?.footer, refs?.header, setFooterRef, setHeaderRef],
  );

  return (
    <ModalStyleContext.Provider value={value}>
      <div
        className={classNames(
          styles.modal_content,
          size === 'large' && styles.modal_content_large,
        )}
        style={memoizedStyle}
        ref={contentRef}
      >
        {children}
      </div>
    </ModalStyleContext.Provider>
  );
};

export default ModalContent;
