import classNames from 'classnames';
import { SelectBox } from 'components/SelectBox/SelectBox';
import { LogicResponse } from 'generated';

import React, { useCallback, useMemo, useEffect } from 'react';
import { Menu, MoreVertical } from 'react-feather';

import type { DraggableProvidedDragHandleProps } from '@hello-pangea/dnd';

import Condition from './Condition/Condition';
import Execution from './Execution/Execution';
import styles from './LogicBox.module.scss';
import { logicTypeOptions } from './data';
import { useLogicsActions } from '../../store';

import type { Logic } from '../../store';

interface LogicBoxProps {
  logic: Logic;
  className?: string;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  isDone?: boolean;
}

const LogicBox = ({
  logic,
  className,
  dragHandleProps,
  isDone,
}: LogicBoxProps) => {
  const { clientId, isValid } = logic;

  const {
    updateLogicType,
    addLogicExecution,
    addLogicCondition,
    deleteLogic,
    copyLogic,
  } = useLogicsActions();

  const dropdownItems = useMemo(
    () => [
      { name: '삭제', action: deleteLogic },
      { name: '복사', action: copyLogic },
    ],
    [],
  );

  const handleLogicTypeChanged = useCallback(
    (value: LogicResponse.type) => {
      if (logic.type === value) {
        return;
      }
      updateLogicType(clientId, value);
    },
    [logic.type],
  );

  const handleAddExecution = useCallback(() => {
    addLogicExecution(clientId);
  }, [addLogicExecution, clientId]);

  const handleAddCondition = useCallback(() => {
    addLogicCondition(clientId);
  }, [addLogicCondition, clientId]);

  useEffect(() => {
    switch (logic.type) {
      case LogicResponse.type.ALWAYS:
        if (logic.executions?.length === 0) {
          addLogicExecution(clientId);
        }
        break;
      case LogicResponse.type.IFTHEN:
        if (logic.executions?.length === 0) {
          addLogicExecution(clientId);
        }
        if (logic.conditions?.length === 0) {
          addLogicCondition(clientId);
        }
        break;
      default:
    }
  }, [logic.type, logic.executions, logic.conditions]);

  return (
    <div className={classNames(styles.logicBox_wrapper, className)}>
      {logic.type === LogicResponse.type.ALWAYS && (
        <div className={styles.always}>
          <div className={styles.statements}>
            {logic.executions?.map((execution, index) => (
              <Execution
                key={`execution-${index}`}
                execution={execution}
                clientId={clientId}
                isValid={isValid}
                isDone={isDone}
              />
            ))}
            <button
              className={styles.statement_add}
              onClick={handleAddExecution}
              disabled={isDone}
            >
              +행위 추가
            </button>
          </div>
        </div>
      )}

      {logic.type === LogicResponse.type.IFTHEN && (
        <div className={styles.if_then}>
          <div className={styles.statements}>
            {logic.conditions?.map((condition, index) => (
              <Condition
                key={`condition-${index}`}
                clientId={clientId}
                condition={condition}
                isValid={isValid}
                isDone={isDone}
              />
            ))}
            <button
              className={styles.statement_add}
              onClick={handleAddCondition}
              disabled={isDone}
            >
              +조건 추가
            </button>
          </div>
          <div className={classNames(styles.statements, styles.then)}>
            {logic.executions?.map((execution, index) => (
              <Execution
                key={`execution-${index}`}
                clientId={clientId}
                execution={execution}
                isValid={isValid}
                isDone={isDone}
              />
            ))}

            <div className={styles.then_keyword_box}>Then</div>

            <button
              className={styles.statement_add}
              onClick={handleAddExecution}
              disabled={isDone}
            >
              +행위 추가
            </button>
          </div>
        </div>
      )}

      <div className={styles.logicBox_type}>
        <SelectBox
          className={classNames(
            styles.selectbox,
            styles.solid,
            isDone && styles.done,
          )}
          value={logic.type}
          options={logicTypeOptions}
          onChange={handleLogicTypeChanged}
          alignItems="left"
          isDisabled={isDone}
        />
      </div>

      <button
        className={classNames(styles.logicBox_action, styles.move)}
        {...dragHandleProps}
        disabled={isDone}
      >
        <Menu size={20} />
      </button>

      <button
        className={classNames(styles.logicBox_action, styles.more)}
        disabled={isDone}
      >
        <MoreVertical size={20} />
        <ul className={classNames(styles.dropdown)}>
          {dropdownItems.map((item) => (
            <li key={item.name} onClick={() => item.action(logic.clientId)}>
              {item.name}
            </li>
          ))}
        </ul>
      </button>
    </div>
  );
};

export default LogicBox;
