/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectBox } from 'components/SelectBox/SelectBox';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';

import styles from './DropDownContent.module.scss';
import {
  applyingStatusOptions,
  evaluationStatusOptions,
  intentionStatusOptions,
  memoStatusOptions,
  filterDropdownOptions,
  paymentStatusOptions,
} from './dropdownOptions';

import type { ApplicationPageFilter } from 'generated';

interface MiddleContentType {
  type: string;
  selectBox: JSX.Element;
}

interface Props {
  filterOpen: boolean;
  filters: ApplicationPageFilter;
  setFilters: Dispatch<SetStateAction<ApplicationPageFilter>>;
  resetHandler: () => void;
  setCurrentPage: (page: number) => void;
  productsOptions: { name: string; value?: number }[];
  templatesOptions: { name: string; value?: number }[];
  organizationOptions: { name: string; value?: number }[];
}
const DropDownContent = ({
  filterOpen,
  resetHandler,
  filters,
  setFilters,
  setCurrentPage,
  productsOptions,
  templatesOptions,
  organizationOptions,
}: Props) => {
  const [hasClosed, setHasClosed] = useState(false);

  const filterHandler = (
    type: string,
    value: ChangeEvent<HTMLSelectElement>,
  ) => {
    setCurrentPage(0);
    setFilters({ ...filters, [type]: value });
  };

  const generateMiddleContents = (
    dropdownOptions: { name: string; value: keyof ApplicationPageFilter }[],
    options: any,
  ) =>
    dropdownOptions.map((option, i: number) => ({
      type: option.name,
      selectBox: (
        <SelectBox
          className={styles.dropdown_middle}
          value={filters[option.value]}
          onChange={(value) => filterHandler(option.value, value)}
          options={options[i]}
        />
      ),
    }));

  const middleContents = generateMiddleContents(filterDropdownOptions, [
    organizationOptions,
    productsOptions,
    templatesOptions,
    paymentStatusOptions,
    applyingStatusOptions,
    evaluationStatusOptions,
    intentionStatusOptions,
    memoStatusOptions,
  ]);

  useEffect(() => {
    if (!filterOpen) {
      setHasClosed(true);
    }
  }, [filterOpen]);

  return (
    <div
      className={`${styles.dropdown_wrapper} ${
        !filterOpen ? styles.close : hasClosed ? styles.open : ''
      }`}
    >
      <div className={styles.dropdown_container}>
        <button className={styles.left} onClick={resetHandler}>
          모두 초기화
        </button>

        <div className={styles.right}>
          {middleContents.map(
            ({ type, selectBox }: MiddleContentType, i: number) => (
              <div className={styles.dropdown_content} key={i}>
                <div className={styles.dropdown_content_title}>{type}</div>
                <div className={`${styles.selectbox} `}>{selectBox}</div>
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default DropDownContent;
