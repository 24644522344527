import debounce from 'lodash/debounce';

import type { Dispatch, SetStateAction } from 'react';
import { useState, useEffect, useCallback } from 'react';

export function useDebounce<T = string>(
  initialValue: T,
  millisec = 500,
): [T, Dispatch<SetStateAction<T>>, T, Dispatch<SetStateAction<T>>] {
  const [input, setInput] = useState<T>(initialValue);
  const [query, setQuery] = useState<T>(initialValue);

  const handleChangeQuery = useCallback(
    debounce((input: T) => {
      setQuery(input);
    }, millisec),
    [],
  );

  useEffect(() => {
    handleChangeQuery(input || initialValue);
  }, [input]);

  return [input, setInput, query, setQuery];
}
