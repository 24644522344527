import { ReactComponent as ScreeningEmpty } from 'assets/screening_empty2.svg';

interface Props {
  className: string;
}

const EmptyView = ({ className }: Props) => (
  <div className={className}>
    <ScreeningEmpty />
    <p>새 규칙 추가 버튼을 클릭하여 규칙을 추가할 수 있습니다.</p>
  </div>
);

export default EmptyView;
