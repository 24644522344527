import queryAxios from 'query/queryAxios';

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { AxiosError, AxiosResponse } from 'axios';
import type { VariableUpsertRequest } from 'generated';

const saveNewVariable = async (
  itemId: number,
  request: VariableUpsertRequest[],
) => queryAxios().post(`template-items/${itemId}/variables`, request);

interface UseVariableMutationType {
  params: number;
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<any, any>,
      AxiosError<unknown, any>,
      VariableUpsertRequest[],
      unknown
    >,
    'mutationFn'
  >;
}

export const useVariableMutation = () => {
  const useSaveVariable = ({
    params: itemId,
    options,
  }: UseVariableMutationType) =>
    useMutation<AxiosResponse, AxiosError, VariableUpsertRequest[], unknown>(
      (variable) => saveNewVariable(itemId, variable),
      options,
    );
  return { useSaveVariable };
};
