import classNames from 'classnames';

import styles from './SettingStatusBadge.module.scss';

import type { SettingStatus } from '../../type';

interface SettingStatusBadgeProps {
  settingStatus: SettingStatus;
  className?: string;
}

const SETTING_STATUS_TEXT = {
  completed: '설정 완료',
  incomplete: '미설정',
  error: '설정 오류',
};

export const EvaStandardStatus = {
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  ERROR: 'error',
} as const;

const SettingStatusBadge = ({
  settingStatus,
  className,
}: SettingStatusBadgeProps) => (
  <span
    className={classNames(
      className,
      styles['badge-wrapper'],
      settingStatus === EvaStandardStatus.COMPLETED && styles.completed,
      settingStatus === EvaStandardStatus.INCOMPLETE && styles.incomplete,
      settingStatus === EvaStandardStatus.ERROR && styles.error,
    )}
  >
    <p>{SETTING_STATUS_TEXT[settingStatus]}</p>
  </span>
);

export default SettingStatusBadge;
