import useToast from 'utils/hooks/useToast';

import { useState } from 'react';

import styles from './DownloadDataButton.module.scss';
import ApplicationDownloadModal from '../Modal/ApplicationDownloadModal/ApplicationDownloadModal';

import type { ApplicationPageFilter, ApplicationPageSearch } from 'generated';

interface DownloadDataButtonProps {
  filters: ApplicationPageFilter;
  search: ApplicationPageSearch;
  disabled?: boolean;
}

const DownloadDataButton = ({
  filters,
  search,
  disabled,
}: DownloadDataButtonProps) => {
  const { showToast, toastType } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClicked = () => {
    if (disabled) {
      showToast({
        message: '다운로드 할 데이터가 없습니다.',
        type: toastType.negative,
      });
      return;
    }
    setIsModalOpen(true);
  };
  return (
    <>
      <ApplicationDownloadModal
        exportType="APPLICATION"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        filters={filters}
        search={search}
        title="데이터 다운로드"
        subTitle={`데이터 형식을 선택해주세요.\n 필터링 되거나 선택된 데이터가 없을 경우,\n 모든 데이터가 다운로드 됩니다.`}
      />
      <div
        className={[
          styles.download_sentence,
          disabled ? styles.disabled : '',
        ].join(' ')}
      >
        <button onClick={handleButtonClicked}>데이터 다운로드</button>
      </div>
    </>
  );
};

export default DownloadDataButton;
