import classNames from 'classnames';

import React from 'react';

import styles from './NumberInput.module.scss';

interface NumberInputProps {
  value?: number;
  defaultValue?: number;
  maxValue?: number;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  unitLabel?: string;
  isDisabled?: boolean;
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  defaultValue,
  maxValue,
  placeholder = '00',
  onChange,
  size = 'medium',
  className,
  unitLabel,
  isDisabled,
}) => {
  const stringValue = React.useMemo(() => {
    if (value === undefined || value === 0) {
      return '';
    }
    return value.toString();
  }, [value]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (maxValue) {
      const { value } = e.target;
      if (Number(value) > maxValue) {
        return;
      }
    }
    onChange?.(e);
  };

  return (
    <div className={styles.numberInput_wrapper}>
      <input
        className={classNames(
          styles.numberInput,
          styles[`size_${size}`],
          className,
        )}
        value={stringValue}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleOnChange}
        disabled={isDisabled}
        type="number"
        min={0}
        max={maxValue}
      />
      <label
        className={classNames(styles.numberInput_label, styles[`size_${size}`])}
      >
        {unitLabel}
      </label>
    </div>
  );
};

export default NumberInput;
