/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ApplicationAnswerResponse } from './ApplicationAnswerResponse';
import type { TemplateResponse } from './TemplateResponse';
import type { UserResponse } from './UserResponse';

export type ApplicationFullResponse = {
    id?: number;
    uuid?: string;
    paymentModel?: ApplicationFullResponse.paymentModel;
    template?: TemplateResponse;
    user?: UserResponse;
    memo?: string;
    applyingStatus?: ApplicationFullResponse.applyingStatus;
    evaluationStatus?: ApplicationFullResponse.evaluationStatus;
    intentionStatus?: ApplicationFullResponse.intentionStatus;
    createdBy?: number;
    createdAt?: string;
    updatedBy?: number;
    updatedAt?: string;
    answerProgress?: string;
    answers?: Array<ApplicationAnswerResponse>;
};

export namespace ApplicationFullResponse {

    export enum paymentModel {
        UPFRONT = 'UPFRONT',
        WEWIN = 'WEWIN',
        GOV_FUNDING = 'GOV_FUNDING',
        B2B = 'B2B',
        IMPACT_CAMPUS = 'IMPACT_CAMPUS',
        HYBRID = 'HYBRID',
        SCHOLARSHIP = 'SCHOLARSHIP',
    }

    export enum applyingStatus {
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        COMPLETED = 'COMPLETED',
        INCOMPLETED = 'INCOMPLETED',
    }

    export enum evaluationStatus {
        NONE = 'NONE',
        WAITING = 'WAITING',
        IN_PROGRESS = 'IN_PROGRESS',
        PASSED = 'PASSED',
        FAILED = 'FAILED',
        RELIEVED = 'RELIEVED',
        RELIEVE_WAIT = 'RELIEVE_WAIT',
        DISQUALIFIED = 'DISQUALIFIED',
        RESIGNED = 'RESIGNED',
    }

    export enum intentionStatus {
        INITIALIZE = 'INITIALIZE',
        CONFIRMED = 'CONFIRMED',
        REVOKED = 'REVOKED',
        NON_RESPONSE = 'NON_RESPONSE',
    }


}

