import queryAxios from 'query/queryAxios';

import type {
  PageImplSurveyResponse,
  Pagination,
  SurveyUpdateRequest,
  TemplateResponse,
} from 'generated';
import type { TemplateUpsertRequest } from 'generated/models/TemplateUpsertRequest';

export interface GetSurveysParams {
  search?: {
    title?: string;
    used?: boolean;
  };
  pagination?: {
    page: number;
    size: number;
    sortDirection?: Pagination.sortDirection;
    sortFields?: string[];
  };
}

export const getSurveySetRequest = async (id: number) =>
  queryAxios().get<TemplateResponse>(`templates/${id}`);

export const getSurveysRequest = async (params: GetSurveysParams) =>
  queryAxios().get<PageImplSurveyResponse>('surveys', {
    params: { ...params },
  });

export const createSurveySetRequest = async (request: TemplateUpsertRequest) =>
  queryAxios().post<TemplateResponse>('templates', { ...request });

export const updateSurveySetRequest = async (
  id: number,
  request: TemplateUpsertRequest,
) => queryAxios().put<TemplateResponse>(`templates/${id}`, { ...request });

export const updateSurveyRequest = async (
  id: number,
  request: SurveyUpdateRequest,
) => queryAxios().put(`surveys/${id}`, { ...request });
