/* eslint-disable @typescript-eslint/no-unused-vars */
import { VariableResponse } from 'generated';
import { find, isEqual, omit } from 'lodash';
import { getTemplateForEvaluationRequest } from 'pages/EvaluationStandardPage/requests';
import { getVariables } from 'pages/VariablePage/request';
import queryKeys from 'query/queryKeys';
import useToast from 'utils/hooks/useToast';

import { useEffect, useCallback } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';

import { getLogics, postLogics } from './request';
import { useLogics, useLogicsActions, useVariablesActions } from './store';

import type { LogicUpsertRequest } from 'generated';

import Vartype = VariableResponse.varType;

interface Props {
  templateId?: number;
  itemId?: number;
}

const useEvaluationLogicPage = ({ templateId = 0, itemId = 0 }: Props) => {
  const { initLogics, clearLogics, updateLogicIsValid } = useLogicsActions();
  const { initVariables, clearVariables } = useVariablesActions();
  const logics = useLogics();
  const { showToast, toastType } = useToast();

  const { data: serverSideLogics, refetch: refetchLogics } = useQuery(
    queryKeys.LOGIC_LIST(itemId),
    () => getLogics(itemId),
    {
      enabled: !!itemId,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    },
  );

  const { data: serverSideVariables } = useQuery(
    queryKeys.VARIABLE_LIST(itemId, [
      Vartype.ANSWER,
      Vartype.CUSTOM,
      Vartype.FIXED,
    ]),
    () => getVariables(itemId, [Vartype.ANSWER, Vartype.CUSTOM, Vartype.FIXED]),
    {
      enabled: !!itemId,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
    },
  );

  const {
    data: evaStandards = {
      data: { id: undefined, beforeAnnounce: false, items: [] },
    },
    refetch: refetchEvaStandards,
  } = useQuery(
    queryKeys.SURVEYSET_FOR_EVA_DETAIL_BY_ID(templateId),
    () => getTemplateForEvaluationRequest(templateId),
    {
      enabled: !!templateId,
      staleTime: 10 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      refetchInterval: 15 * 60 * 1000,
    },
  );

  const { mutate: mutateLogics } = useMutation(
    ({ itemId, payload }: { itemId: number; payload: LogicUpsertRequest[] }) =>
      postLogics(itemId, payload),
    {
      onSuccess: () => {
        refetchLogics();
        refetchEvaStandards();

        showToast({
          message: '규칙이 저장되었습니다.',
          type: toastType.positive,
        });
      },
    },
  );

  useEffect(() => {
    if (
      serverSideLogics &&
      serverSideVariables &&
      evaStandards.data.id &&
      !!itemId
    ) {
      initLogics(serverSideLogics);
      initVariables(serverSideVariables);

      if (find(evaStandards.data.items, { id: itemId })?.hasError) {
        updateLogicIsValid(serverSideVariables);
      }
    }
  }, [serverSideLogics, serverSideVariables, evaStandards.data, itemId]);

  useEffect(() => {
    if (find(evaStandards.data.items, { id: itemId })?.hasError) {
      showToast({
        message: '문항 혹은 변수가 편집되어 작동할 수 없는 규칙이 있습니다.',
        type: toastType.negative,
      });
    }
  }, [evaStandards.data.items, itemId]);

  useEffect(
    () => () => {
      clearLogics();
      clearVariables();
    },
    [],
  );

  const getIsDirty = useCallback(() => {
    if (!evaStandards.data.beforeAnnounce) {
      return false;
    }

    const sanitizedLogics = logics.map((logic) =>
      omit(logic, ['isValid', 'clientId']),
    );

    return !isEqual(sanitizedLogics, serverSideLogics ?? []);
  }, [logics, serverSideLogics, evaStandards.data.beforeAnnounce]);

  return {
    beforeAnnounce: evaStandards.data.beforeAnnounce,
    surveyTitle:
      find(evaStandards.data.items, { id: itemId })?.survey?.title || '',
    mutateLogics,
    resetLogics: () => initLogics(serverSideLogics ?? []),
    isDirty: getIsDirty(),
  };
};

export default useEvaluationLogicPage;
