/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ApplicationByProductResponse = {
    id?: number;
    uuid?: string;
    applyingStatus?: ApplicationByProductResponse.applyingStatus;
};

export namespace ApplicationByProductResponse {

    export enum applyingStatus {
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        COMPLETED = 'COMPLETED',
        INCOMPLETED = 'INCOMPLETED',
    }


}

