import { AxiosError } from 'axios';
import queryAxios from 'query/queryAxios';

import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

import type { AxiosResponse } from 'axios';
import type { SurveyCreateRequest, SurveyResponse } from 'generated';

export const createSurveyRequest = async (request: SurveyCreateRequest) =>
  queryAxios().post<SurveyResponse>('surveys', { ...request });

export const deleteSurveyRequest = async (id: number) =>
  queryAxios().delete(`surveys/${id}`);

export const updateSurveyTestTypeRequest = async (
  surveyId: number | undefined,
  test?: boolean,
) => {
  if (!surveyId) {
    throw new AxiosError('질문지 ID가 없습니다.');
  }

  return queryAxios().patch(`surveys/${surveyId}`, { test });
};

export const getSurveyByIdRequest = async (surveyId?: number) => {
  if (!surveyId) {
    throw new AxiosError('질문지 ID가 없습니다.');
  }

  return queryAxios()
    .get<SurveyResponse>(`surveys/${surveyId}`)
    .then(({ data }) => data);
};

interface UseSurveyMutationType {
  options?: Omit<
    UseMutationOptions<
      AxiosResponse<any, any>,
      AxiosError<unknown, any>,
      unknown,
      unknown
    >,
    'mutationFn'
  >;
}

interface UseUpdateTestTypeSurveyType extends UseSurveyMutationType {
  surveyId?: number;
  test?: boolean;
}

const useCreateSurvey = (
  { options }: UseSurveyMutationType = { options: {} },
) =>
  useMutation<AxiosResponse, AxiosError, SurveyCreateRequest, unknown>(
    (variables) => createSurveyRequest(variables),
    options,
  );

const useUpdateTestTypeSurvey = (
  { surveyId, test, options }: UseUpdateTestTypeSurveyType = {
    options: {},
    test: false,
  },
) =>
  useMutation<AxiosResponse, AxiosError, boolean>(
    () => updateSurveyTestTypeRequest(surveyId, test),
    options,
  );

const useDeleteSurvey = (
  { options }: UseSurveyMutationType = { options: {} },
) =>
  useMutation<AxiosResponse, AxiosError, { id: number }>(
    ({ id }) => deleteSurveyRequest(id),
    options,
  );

export { useCreateSurvey, useUpdateTestTypeSurvey, useDeleteSurvey };
