import Loading from 'components/Loading/Loading';
import { useAlert } from 'utils/hooks/useAlert';
import { useDebounce } from 'utils/hooks/useDebounce';

import { Suspense, lazy, useState } from 'react';

import styles from './SurveySetPage.module.scss';
import SurveySetAlert from './components/SurveySetAlert';
import SurveySetPageHeader from './components/SurveySetPageHeader';

const SurveySetPageBody = lazy(() => import('./components/SurveySetPageBody'));

const SurveySetPage = () => {
  const { isAlertOpen, setIsAlertOpen, alertContent, setAlertContent } =
    useAlert();

  const [, setCurrentPage] = useState(0);

  const [input, setInput, query] = useDebounce('');

  return (
    <>
      <SurveySetAlert
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        alertContent={alertContent}
      />

      <div className="content-wrapper">
        <div className={styles.page_contents}>
          <SurveySetPageHeader
            input={input}
            setInput={setInput}
            setCurrentPage={setCurrentPage}
          />

          <Suspense fallback={<Loading />}>
            <SurveySetPageBody
              query={query}
              isAlertOpen={isAlertOpen}
              setIsAlertOpen={setIsAlertOpen}
              alertContent={alertContent}
              setAlertContent={setAlertContent}
            />
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default SurveySetPage;
