import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import { SurveyCreator } from 'survey-creator-react';

import styles from './PreviewSurveySubmissionModal.module.scss';

import type {
  QuestionRadiogroupModel,
  ItemValue,
  QuestionCheckboxModel,
} from 'survey-core';

interface Props {
  definition: object;
  response: object;
}

const blockedQuestionType = ['html', 'image'];

const PreviewSurveySubmissionModal = ({ definition, response }: Props) => {
  const creator = new SurveyCreator();
  creator.text = JSON.stringify(definition);

  const surveyValues = creator.surveyValue;
  const questions = surveyValues
    .getAllQuestions()
    .filter((question) => !blockedQuestionType.includes(question.getType()));
  const responses = Object(response);

  const questionTitles = questions.map((question) => question.title);

  const answerTitles = questions.map((question) => {
    const type = question.getType();
    const matched = responses[question.name];

    if (!matched) {
      return undefined;
    }

    try {
      switch (type) {
        case 'text':
        case 'dropdown':
        case 'rating':
        case 'comment':
          return matched;
        case 'radiogroup':
          if (matched === 'other' && responses[`${question.name}-Comment`]) {
            return responses[`${question.name}-Comment`];
          }
          return (
            ((question as QuestionRadiogroupModel).choices as ItemValue[]).find(
              (choice) => choice.value === matched,
            )?.text ?? matched
          );
        case 'checkbox':
          return new Array(...matched)
            .map((matched) => {
              if (
                matched === 'other' &&
                responses[`${question.name}-Comment`]
              ) {
                return responses[`${question.name}-Comment`];
              }
              const matchedChoice = (
                (question as QuestionCheckboxModel).choices as ItemValue[]
              ).find((choice) => choice.value === matched);
              return matchedChoice ? matchedChoice.text : matched;
            })
            .join(', ');
        case 'file':
          return (
            <>
              {matched.map((file: { content: string; name: string }) => (
                <div>
                  <a
                    href={file.content}
                    title={file.name}
                    download={file.name}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file.name}
                  </a>
                </div>
              ))}
            </>
          );
        default:
          return JSON.stringify(matched);
      }
    } catch (e) {
      console.log(e);
      return JSON.stringify(matched);
    }
  });

  return (
    <div className={styles.preview}>
      {questionTitles.map((questionTitle, index) => (
        <div className={styles.wrapper} key={`qna-${index}`}>
          <p className={styles.question}>{questionTitle}</p>
          {answerTitles[index] ? (
            <div className={styles.answer}>
              <p>{answerTitles[index]}</p>
            </div>
          ) : (
            <div className={[styles.answer, styles.disabled].join(' ')}>
              <p>답변 없음</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PreviewSurveySubmissionModal;
