import queryAxios from 'query/queryAxios';

import type { SurveyResponse } from 'generated';

export const getSurveyRequest = async (id: number) =>
  queryAxios()
    .get<SurveyResponse>(`surveys/${id}`)
    .then((data) => data.data);

export const updateSurveyRequest = async (id: number, survey: SurveyResponse) =>
  queryAxios().put<SurveyResponse>(`surveys/${id}`, {
    title: survey.title,
    description: survey.description,
    slugUrl: survey.slugUrl,
    definition: survey.definition,
    columns: survey.columns,
    displayTitle: survey.displayTitle,
  });
