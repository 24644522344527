import Alert from 'components/Alert';
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';
import useCustomPrompt from 'utils/hooks/useCustomPrompt';

interface LeavePageAlertProps {
  isAllSaved: boolean;
}
const LeavePageAlert = ({ isAllSaved }: LeavePageAlertProps) => {
  const { showPrompt, confirmNavigation, cancelNavigation } = useCustomPrompt(
    !isAllSaved,
  );
  return (
    <Modal isOpen={showPrompt} onClose={cancelNavigation}>
      <Alert>
        <Alert.Title>
          {`변경된 내용이 있습니다. \n 변수 관리를 취소할까요?`}
        </Alert.Title>
        <Alert.Footer>
          <Button onClick={confirmNavigation}>확인</Button>
        </Alert.Footer>
      </Alert>
    </Modal>
  );
};

export default LeavePageAlert;
