/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProductUpsertRequest = {
    uuid: string;
    name: string;
    programName: string;
    academy: ProductUpsertRequest.academy;
    applyStart?: string;
    applyEnd?: string;
    trainingStart?: string;
    trainingEnd?: string;
    announcementDate?: string;
};

export namespace ProductUpsertRequest {

    export enum academy {
        BOOTCAMP = 'BOOTCAMP',
        B2B = 'B2B',
        WEWIN = 'WEWIN',
    }


}

