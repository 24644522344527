import decode from 'jwt-decode';
import { isPlainObject, has } from 'lodash-es';

import type { JwtPayload } from 'jwt-decode';

export const TOKEN_KEY = 'token';

export interface UserJwtPayload extends JwtPayload {
  email?: string;
  name: string;
  role: string;
}

const isUserJwtPayload = (jwtPayload: unknown): jwtPayload is UserJwtPayload =>
  isPlainObject(jwtPayload) &&
  has(jwtPayload, 'role') &&
  has(jwtPayload, 'sub') &&
  has(jwtPayload, 'name');

export const getUserInfoFromToken = (token: string) => {
  try {
    const decoded = decode(token);
    return isUserJwtPayload(decoded)
      ? { sub: decoded.sub, name: decoded.name, role: decoded.role }
      : null;
  } catch (ex) {
    return null;
  }
};
