import { useCallback, useEffect } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

export const useCustomPrompt = (shouldBlock: boolean) => {
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (!shouldBlock && blocker.state === 'blocked') {
      blocker.reset();
    }
  }, [shouldBlock, blocker.state]);

  const confirmNavigation = useCallback(() => {
    blocker.proceed?.();
  }, [blocker]);

  const cancelNavigation = useCallback(() => {
    blocker.reset?.();
  }, [blocker]);

  return {
    showPrompt: blocker.state === 'blocked',
    confirmNavigation,
    cancelNavigation,
  };
};

export default useCustomPrompt;
