/**
 * React Query의 mutation(create/update/delete)이 일어날 때
 * 전체화면에 표시되는 로더
 */

import Loading from 'components/Loading/Loading';

import { useIsMutating } from '@tanstack/react-query';

import styles from './FullScreenLoader.module.scss';

const FullScreenLoader = () => {
  const isMutating = useIsMutating();

  return (
    <>
      {isMutating ? (
        <div className={styles.full_screen_loader}>
          <div className={styles.blurred_background} />
          <Loading isFull />
        </div>
      ) : null}
    </>
  );
};
export default FullScreenLoader;
