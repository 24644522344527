import SearchBox from 'components/SearchBox/SearchBox';
import { SelectBox } from 'components/SelectBox/SelectBox';
import { isEndWithVowel } from 'utils/isEndWithConstant';

import React, { useEffect } from 'react';

import styles from './SearchContent.module.scss';
import { searchDropdownOptions } from '../DropDownContent/dropdownOptions';

import type { ApplicationPageSearch } from 'generated';

interface Props {
  input: ApplicationPageSearch;
  setInput: (input: ApplicationPageSearch) => void;
  setCurrentPage: (page: number) => void;
  searchOptions: string[];
  searchDropdownState: string;
  setSearchDropdownState: (string: string) => void;
  handleSearchValueSelected: (
    dropdownState: string,
  ) => string | number | undefined;
}

const SearchContent = ({
  input,
  setInput,
  setCurrentPage,
  searchOptions,
  searchDropdownState,
  setSearchDropdownState,
  handleSearchValueSelected,
}: Props) => {
  useEffect(() => {
    setInput({});
  }, [searchDropdownState]);

  const handleSelectedInputDebounce = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const type = searchDropdownOptions.find(
      (option) => option.name === searchDropdownState,
    );
    if (!type) {
      return;
    }

    setCurrentPage(0); // 검색했을때도 1페이지로 리셋시키지 않으면 버그가 있어서 추가
    switch (type.value) {
      case 'userId':
      case 'applicationIds':
        if (e.target.value === '') {
          setInput({ ...input, [type.value]: undefined });
        } else if (Number.isNaN(Number(e.target.value))) {
          setInput({ ...input, [type.value]: input[type.value] });
        } else if (Number(e.target.value) > Number.MAX_SAFE_INTEGER) {
          setInput({ ...input, [type.value]: input[type.value] });
        } else {
          setInput({ ...input, [type.value]: Number(e.target.value) });
        }
        break;
      default:
        setInput({ ...input, [type.value]: e.target.value });
    }
  };

  return (
    <div className={styles.search_wrapper}>
      <div className={styles.query_value_selectbox}>
        <SelectBox
          className={styles.dropdown_searchbox}
          value={searchDropdownState}
          options={searchOptions.map((option) => ({
            name: `${option}`,
            value: option,
          }))}
          onChange={(value) => {
            setSearchDropdownState(value);
          }}
          isFullHeight
        />
      </div>
      <div className={styles.query_value_searchbox}>
        <SearchBox
          value={handleSearchValueSelected(searchDropdownState) ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSelectedInputDebounce(e)
          }
          variant="blue"
          placeholder={`지원자의 ${searchDropdownState}${
            isEndWithVowel(searchDropdownState) ? '을' : '를'
          } 입력해주세요.`}
          isFullWidth
          isFullHeight
        />
      </div>
    </div>
  );
};

export default SearchContent;
