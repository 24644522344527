import { useEffect, useState } from 'react';
import { Check, Search } from 'react-feather';

import styles from './DropdownSearchBox.module.scss';

interface DropdownSearchBoxProps {
  onChange: (value: string) => void;
  onSelect: (value: any) => void;
  options?: any[];
  label?: string;
  className?: string;
  placeholder?: string;
  value?: string;
  upward?: boolean;
  setValue: (value: string) => void;
  isOptionLoading: boolean;
  isOptionSelected: boolean;
  propertyForOptionTitle: string;
}

export const DropdownSearchBox = ({
  onChange,
  onSelect,
  options = [],
  label,
  upward,
  className,
  placeholder,
  value = '',
  setValue,
  isOptionLoading,
  isOptionSelected,
  propertyForOptionTitle,
}: DropdownSearchBoxProps) => {
  const [isSearching, setIsSearching] = useState(false);
  const [_placeholder, setPlaceholder] = useState(placeholder);

  const isMatched = options.length === 1 && options[0] === value;
  const isOptionsOpen = options.length > 0 && isSearching;

  const handleInputChanged = (value: string) => {
    onChange(value);
    setIsSearching(true);
  };
  const handleOptionSelected = (option: any) => {
    setIsSearching(false);
    onSelect(option);
    setPlaceholder(option[propertyForOptionTitle]);
  };
  const handleInputFocused = () => {
    if (options) {
      setPlaceholder(value);
      setValue('');
    }
    setTimeout(() => {
      setIsSearching(true);
    }, 400);
  };
  const handleInputBlurred = () => {
    setValue(_placeholder || '');
    setIsSearching(false);
  };

  useEffect(() => {
    if (value === '' && !isOptionSelected) {
      setPlaceholder(placeholder);
    }
  }, [value]);

  className = [
    styles.searchbox,
    isSearching ? styles.searching : '',
    className,
  ].join(' ');

  return (
    <section>
      {label && (
        <label className={styles.label} htmlFor={label}>
          {label}
        </label>
      )}
      <div className={styles.searchbox_wrapper}>
        <div className={className}>
          <input
            value={value}
            onChange={(e) => handleInputChanged(e.target.value)}
            onFocus={handleInputFocused}
            onBlur={handleInputBlurred}
            placeholder={_placeholder}
          />
          <Search />
        </div>
        {options && !isOptionLoading && (
          <ul
            className={`${styles.options} ${
              isOptionsOpen && !isMatched ? '' : styles.hidden
            } ${upward ? styles.upward : ''}`}
          >
            <li className={`${styles.empty_space} ${styles.top}`} />
            {options.map((option, index) => (
              <li key={index + 1}>
                <button
                  className={styles.item_button}
                  onClick={() => handleOptionSelected(option)}
                  type="button"
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <Check className={styles.check} />
                  <p>{option[propertyForOptionTitle]}</p>
                </button>
              </li>
            ))}
            <li className={`${styles.empty_space} ${styles.bottom}`} />
          </ul>
        )}
      </div>
    </section>
  );
};
