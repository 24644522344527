import { AxiosError } from 'axios';
import queryAxios from 'query/queryAxios';

import type { DownloadDocumentType } from './types';
import type {
  EvalStandardResponse,
  PageImplTemplateResponse,
  UploadResult,
  AutomaticEvaluationResponse,
  TemplatePageSearch,
  Pagination,
} from 'generated';

export const getSurveySetListRequest = async (params: {
  search: TemplatePageSearch;
  pagination: Pagination;
}) =>
  queryAxios()
    .get<PageImplTemplateResponse>('templates', {
      params,
    })
    .then(({ data }) => data);

export const downloadEvaluationDataRequest = async (
  documentType: DownloadDocumentType,
  productId?: number,
  templateId?: number,
) => {
  if (!productId && !templateId) {
    throw new AxiosError('상품 ID가 없습니다.');
  }

  return queryAxios().get(`evaluations/documents/${documentType}`, {
    params: {
      search: {
        productId,
        templateId,
      },
      filter: {
        applyingStatus: 'COMPLETED',
      },
    },
    responseType: 'blob',
  });
};

export const uploadEvaluationDataRequest = async (file: File) =>
  queryAxios().post<UploadResult>(
    `evaluations/templates/documents`,
    { document: file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

export const getEvaluationStandard = async (templateId?: number) => {
  if (!templateId) {
    throw new AxiosError('질문지 세트 ID가 없습니다.');
  }

  return queryAxios()
    .get<EvalStandardResponse>(`templates/${templateId}/standard`)
    .then((res) => res.data);
};

export const evaluateTemplateRequest = async (
  selectedNumber: number,
  templateId?: number,
) => {
  if (!templateId) {
    throw new AxiosError('질문지 세트 ID가 없습니다.');
  }

  return queryAxios().post<AutomaticEvaluationResponse>(
    'applications/evaluations',
    {
      requestType: 'TEMPLATE',
      ids: [templateId],
      selectedNumber,
    },
  );
};

export const syncAppUserInfoRequest = (id?: number) => {
  if (!id) {
    throw new AxiosError('질문지 세트 ID가 없습니다.');
  }
  return queryAxios().post(`users/templates/${id}/sync`);
};
