import queryAxios from 'query/queryAxios';
import queryKeys from 'query/queryKeys';

import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import type {
  PageImplSurveyResponse,
  Pagination,
  SurveyResponse,
} from 'generated';

export interface GetSurveysParams {
  search?: {
    title?: string;
    used?: boolean;
  };
  pagination?: {
    page: number;
    size: number;
    sortDirection?: Pagination.sortDirection;
    sortFields?: string[];
  };
}

interface UseSurveyQueryType {
  params: GetSurveysParams;
  options?: Omit<
    UseQueryOptions<
      PageImplSurveyResponse,
      unknown,
      PageImplSurveyResponse,
      readonly ['survey', 'list', any]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >;
}

interface UseSurveyByIdQueryType {
  surveyId: number;
  options?: Omit<
    UseQueryOptions<
      SurveyResponse,
      unknown,
      SurveyResponse,
      readonly ['survey', 'detail', { readonly id: number }]
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >;
}

const getSurveyByIdRequest = async (surveyId: number) =>
  queryAxios()
    .get<SurveyResponse>(`surveys/${surveyId}`)
    .then(({ data }) => data);

const getSurveysRequest = async (params: GetSurveysParams) =>
  queryAxios()
    .get<PageImplSurveyResponse>('surveys', {
      params: { ...params },
    })
    .then((data) => data.data);

const useGetSurveyById = ({ surveyId, options }: UseSurveyByIdQueryType) =>
  useQuery(
    queryKeys.SURVEY_DETAIL_BY_ID(surveyId),
    () => getSurveyByIdRequest(surveyId),
    options,
  );

const useGetSurvey = ({
  params: getSurveysParams,
  options,
}: UseSurveyQueryType) =>
  useQuery(
    queryKeys.SURVEY_LIST(getSurveysParams),
    () => getSurveysRequest(getSurveysParams),

    options,
  );

export { useGetSurvey, useGetSurveyById };
