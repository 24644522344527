/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserSearchRequest = {
    email?: string;
    name?: string;
    role: UserSearchRequest.role;
    mobile?: string;
};

export namespace UserSearchRequest {

    export enum role {
        SUPER_ADMIN = 'SUPER_ADMIN',
        ADMIN = 'ADMIN',
        CREW = 'CREW',
        APPLICANT = 'APPLICANT',
    }


}

