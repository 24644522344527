import classNames from 'classnames';

import React, { useEffect, useMemo, useRef } from 'react';

import { useModalContext } from './Modal';
import styles from './Modal.module.scss';
import { useModalStyleContext } from './ModalContent';
import { ReactComponent as IconClose } from '../../assets/modal-x.svg';

interface ModalHeaderProps {
  height?: number;
}

const OFFSET_WITH_FOOTER = 23;

const ModalHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({
  height,
  children,
}) => {
  const { onClose, size } = useModalContext();
  const { setHeaderRef, footerRef } = useModalStyleContext();
  const ref = useRef<HTMLElement | null>(null);

  const memoizedHeaderStyle = useMemo(
    () =>
      height ? { height: `${height}px`, minHeight: `${height}px` } : undefined,
    [height],
  );

  const memoizedTitleStyle = useMemo(
    () =>
      footerRef && size === 'large'
        ? {
            width: `calc(100% - ${footerRef.clientWidth}px - ${OFFSET_WITH_FOOTER}px)`,
          }
        : undefined,
    [footerRef, size],
  );

  useEffect(() => {
    if (setHeaderRef && ref.current) {
      setHeaderRef(ref.current);
    }
  }, [ref]);

  return (
    <header
      ref={ref}
      className={classNames(
        styles.modal_header,
        size === 'large' && styles.modal_header_large,
      )}
      style={memoizedHeaderStyle}
    >
      <div className={styles.modal_header_title} style={memoizedTitleStyle}>
        {children}
      </div>
      <button className={styles.btn_close} onClick={onClose}>
        <IconClose />
      </button>
    </header>
  );
};

const ModalHeaderSubTitle = ({ children }: React.PropsWithChildren) => (
  <p className={styles.modal_header_subTitle}>{children}</p>
);

export default Object.assign(ModalHeader, {
  SubTitle: ModalHeaderSubTitle,
});
