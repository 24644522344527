import { ReactComponent as Equal } from 'assets/equal.svg';
import classNames from 'classnames';
import DateInput from 'components/DateInput/DateInput';
import Input from 'components/Input/Input';
import SearchSelectBox from 'components/SearchSelectBox/SearchSelectBox';
import { SelectBox } from 'components/SelectBox/SelectBox';

import React from 'react';
import { Trash2 } from 'react-feather';

import styles from './Execution.module.scss';
import useExecution from './useExecution';
import {
  executionOperatorOptions,
  executionRightVarTypeOptions,
  executionTypeOptions,
} from '../data';
import { ExecutionArithmeticVarType, ExecutionType, InputStyle } from '../type';

import type { ExecutionResponse } from 'generated';

export interface ExecutionProps {
  clientId: string;
  execution: ExecutionResponse;
  isValid?: boolean;
  isDone?: boolean;
}

const Execution = (props: ExecutionProps) => {
  const { execution, isDone } = props;
  const {
    executionType,
    variableOptions,
    leftVariable,
    rightVariable,
    isInvalidActive,
    handleDeleteExecution,
    handleExecutionTypeChange,
    handleLeftValueChange,
    handleRightInputChange,
    preventInputOnWheel,
    replacementInputStyle,
    replacementChoiceOptions,
    handleReplacementChoiceChange,
    handleReplacementDateChange,
    arithmeticVarType,
    arithmeticText,
    handleArithmeticSelectChange,
    handleArithmeticOperatorChange,
    handleArithmeticVarTypeChange,
  } = useExecution(props);

  return (
    <div className={styles.statement_wrapper}>
      <div className={styles.execution_type}>
        <SelectBox
          className={classNames(
            styles.row,
            styles.selectbox,
            isDone && styles.done,
          )}
          value={executionType}
          placeholder="선택"
          onChange={handleExecutionTypeChange}
          options={executionTypeOptions}
          isInvalid={isInvalidActive && !executionType}
          alignItems="left"
          isDisabled={isDone}
        />
      </div>

      {executionType === ExecutionType.TEXT_REPLACEMENT && (
        <div className={classNames(styles.textReplacement_wrapper, styles.row)}>
          <SearchSelectBox
            className={classNames(
              styles.searchSelectbox,
              isDone && styles.done,
            )}
            value={execution.leftVariableId}
            options={variableOptions.VARIABLE}
            onChange={handleLeftValueChange}
            isInvalid={isInvalidActive && !leftVariable}
            isDisabled={isDone}
          />
          <Equal width={24} height={24} />
          {replacementInputStyle === InputStyle.CHOICE && (
            <SearchSelectBox
              className={classNames(
                styles.searchSelectbox,
                isDone && styles.done,
              )}
              value={execution.rightValue}
              options={replacementChoiceOptions ?? []}
              onChange={handleReplacementChoiceChange}
              placeholder="선택해 주세요."
              isInvalid={isInvalidActive && !execution.rightValue}
              isDisabled={isDone}
            />
          )}
          {replacementInputStyle === InputStyle.DATE && (
            <DateInput
              value={execution.rightValue}
              onChange={handleReplacementDateChange}
              isFullWidth
              placeholder="날짜를 입력해 주세요."
              isInvalid={isInvalidActive && !execution.rightValue}
              isDisabled={isDone}
            />
          )}
          {replacementInputStyle === InputStyle.NUMBER && (
            <Input
              className={classNames(styles.input, isDone && styles.done)}
              type="number"
              value={execution.rightValue ?? ''}
              onChange={handleRightInputChange}
              onWheel={preventInputOnWheel}
              placeholder="숫자를 작성해 주세요."
              isInvalid={isInvalidActive && !execution.rightValue}
              isDisabled={isDone}
            />
          )}
          {replacementInputStyle === InputStyle.STRING && (
            <Input
              className={classNames(styles.input, isDone && styles.done)}
              value={execution.rightValue ?? ''}
              onChange={handleRightInputChange}
              placeholder="텍스트를 작성해 주세요."
              isInvalid={isInvalidActive && !execution.rightValue}
              isDisabled={isDone}
            />
          )}
          {replacementInputStyle === InputStyle.DISABLED && (
            <Input
              className={classNames(styles.input, isDone && styles.done)}
              value={execution.rightValue ?? ''}
              placeholder="변수를 선택해 주세요."
              isDisabled
              isInvalid={isInvalidActive && !execution.rightValue}
            />
          )}
        </div>
      )}

      {executionType === ExecutionType.ARITHMETIC && (
        <div className={classNames(styles.arithmetic_wrapper, styles.row)}>
          <SearchSelectBox
            className={classNames(
              styles.searchSelectbox,
              isDone && styles.done,
            )}
            value={execution.leftVariableId}
            options={variableOptions.VARIABLE_NUM}
            onChange={handleLeftValueChange}
            isInvalid={isInvalidActive && !leftVariable}
            isDisabled={isDone}
          />
          <SelectBox
            className={classNames(
              styles.selectbox,
              styles.solid,
              isDone && styles.done,
            )}
            value={execution.operator}
            options={executionOperatorOptions}
            onChange={handleArithmeticOperatorChange}
            alignItems="left"
            isDisabled={isDone}
          />
          <SelectBox
            className={classNames(styles.selectbox, isDone && styles.done)}
            value={arithmeticVarType}
            options={executionRightVarTypeOptions}
            onChange={handleArithmeticVarTypeChange}
            isInvalid={isInvalidActive && !arithmeticVarType}
            alignItems="left"
            isDisabled={isDone}
          />
          {arithmeticVarType === ExecutionArithmeticVarType.ANSWER && (
            <SearchSelectBox
              className={classNames(
                styles.searchSelectbox,
                isDone && styles.done,
              )}
              value={execution.rightVariableId}
              options={variableOptions.ANSWER_NUM}
              placeholder={'답변을 선택해 주세요.'}
              onChange={handleArithmeticSelectChange}
              isInvalid={isInvalidActive && !rightVariable}
              isDisabled={isDone}
            />
          )}
          {arithmeticVarType === ExecutionArithmeticVarType.VARIABLE && (
            <SearchSelectBox
              className={classNames(
                styles.searchSelectbox,
                isDone && styles.done,
              )}
              value={execution.rightVariableId}
              options={variableOptions.VARIABLE_NUM}
              placeholder={'변수를 선택해 주세요.'}
              onChange={handleArithmeticSelectChange}
              isInvalid={isInvalidActive && !rightVariable}
              isDisabled={isDone}
            />
          )}
          {arithmeticVarType === ExecutionArithmeticVarType.INPUT && (
            <Input
              className={classNames(styles.input, isDone && styles.done)}
              defaultValue={execution.rightValue}
              placeholder={'숫자를 작성해 주세요.'}
              type="number"
              onWheel={preventInputOnWheel}
              onChange={handleRightInputChange}
              isInvalid={isInvalidActive && !execution.rightValue}
            />
          )}
          {arithmeticText && (
            <div className={styles.arithmetic_text}>{arithmeticText}</div>
          )}
        </div>
      )}

      <button
        className={styles.statement_delete}
        onClick={handleDeleteExecution}
        disabled={isDone}
      >
        <Trash2 size={16} />
      </button>
    </div>
  );
};

export default Execution;
