import { STATUS_CODE } from 'utils/httpStatusCode';

import type { AxiosError, AxiosInstance } from 'axios';

export const responseTokenInterceptor = (axios: AxiosInstance) => {
  const onRejected = async (err: AxiosError) => {
    const { response } = err;
    if (response?.status === STATUS_CODE.UNAUTHORIZED) {
      alert('로그인 토큰이 만료되었습니다. 다시 로그인해주세요.');
      window.location.href = '/login';
    } else {
      return Promise.reject(err);
    }
  };

  axios.interceptors.response.use((response) => response, onRejected);
};
