import classNames from 'classnames';

import React from 'react';

import { useModalContext } from './Modal';
import styles from './Modal.module.scss';

const ModalBody = ({ children }: React.PropsWithChildren) => {
  const { size } = useModalContext();

  return (
    <div
      className={classNames(
        styles.modal_body,
        size === 'large' && styles.modal_body_large,
      )}
    >
      {size === 'large' ? (
        <div className={styles.modal_body_scroll}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};

export default ModalBody;
