/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-use-before-define */
import { Button } from 'components/Button/Button';
import Loading from 'components/Loading/Loading';
import PaginationBar from 'components/PaginationBar/PaginationBar';
import TablePage from 'components/Table/TablePage';
import { useLoadingState } from 'contexts/loadingState';
import { ApplicationChangeStateRequest } from 'generated';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import { useDebounce } from 'utils/hooks/useDebounce';
import useHeader from 'utils/hooks/useHeader';
import useToast from 'utils/hooks/useToast';
import getUrls from 'utils/urls';

import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
// import { useNavigate } from 'react-router-dom';

import { useQuery, useMutation } from '@tanstack/react-query';

import styles from './ApplicationPage.module.scss';
import DataContent from './DataContent/DataContent';
import FilterContent from './DropDownContent/DropDownContent';
import {
  applyingStatusOptions,
  columnProps,
  evaluationStatusOptions,
  intentionStatusOptions,
  searchDropdownOptions,
} from './DropDownContent/dropdownOptions';
import IsHoverLight from './IsHoverLight';
import Memo from './Memo';
import MemoModal from './Modal/MemoModal/MemoModal';
import UpdatedApplicationModal from './Modal/UpdateApplicationModal/UpdateApplicationModal';
import SearchContent from './SearchContent/SearchContent';
import UnderLineData from './UnderlineData';
import {
  getApplicationsRequest,
  getOrganizationsRequest,
  getProductsRequest,
  getTemplatesRequest,
  updateApplicationMemoRequest,
  updateApplicationStatusRequest,
} from './requests';

import type {
  ContentType,
  UpdateApplicationInputType,
  UpdateApplicationMemoInputType,
} from './types';
import type { AxiosError, AxiosResponse } from 'axios';
import type {
  ApplicationPageResponse,
  ApplicationPageFilter,
  ApplicationPageSearch,
} from 'generated';

const ApplicationPage = () => {
  // const navigate = useNavigate();
  const { uploadLoading } = useLoadingState();
  const { showToast, toastType } = useToast();

  const [applications, setApplications] = useState<
    ApplicationPageResponse[] | undefined
  >();
  const [products, setProducts] = useState<
    { name: string; value: number | undefined }[]
  >([
    {
      name: '모든 상품',
      value: undefined,
    },
  ]);
  const [templates, setTemplates] = useState<
    { name: string; value: number | undefined }[]
  >([
    {
      name: '모든 질문 세트',
      value: undefined,
    },
  ]);
  const [organizations, setOrganizations] = useState<
    { name: string; value: number | undefined }[]
  >([
    {
      name: '모든 기관',
      value: undefined,
    },
  ]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [content, setContent] = useState<ContentType[]>([]);

  const [filters, setFilters] = useState<ApplicationPageFilter>({});
  const [search, setSearch, debouncedSearch] =
    useDebounce<ApplicationPageSearch>({});
  const [updateApplicationInput, setUpdateApplicationInput] =
    useState<UpdateApplicationInputType>({
      applicationId: undefined,
      modalTitle: '',
      value: '',
      modalOption: [],
      changeType: undefined,
    });
  const [updateApplicationMemoInput, setUpdateApplicationMemoInput] =
    useState<UpdateApplicationMemoInputType>({
      applicationId: undefined,
      name: '',
      memo: '',
    });

  const [filterOpen, setFilterOpen] = useState<boolean>(true);
  const [updateApplicationModalOpen, setUpdateApplicationModalOpen] =
    useState(false);
  const [memoModalOpen, setMemoModalOpen] = useState(false);
  const [checkBoxStates, setCheckBoxStates] = useState<boolean[]>([]);
  const [searchDropdownState, setSearchDropdownState] =
    useState<string>('이름');

  useHeader(
    {
      title: `지원 관리 ${total ? `(${total})` : ''}`,
    },
    [total],
  );

  const handleSearchValueSelected = (searchDropdownState: string) => {
    const option = searchDropdownOptions.find(
      (option) => option.name === searchDropdownState,
    );
    if (!option) {
      return '';
    }

    return search[option.value]?.toString();
  };

  const applicationRequest = {
    search: { ...debouncedSearch },
    filter: { ...filters },
    pagination: {
      page,
      size: pageSize,
    },
  };

  const {
    data: getApplicationsData,
    isLoading: isGetApplicationsDataLoading,
    refetch: getApplicationDataRefetch,
  } = useQuery(
    queryKeys.APPLICATION_LIST(applicationRequest),
    () => getApplicationsRequest(applicationRequest),
    {
      refetchInterval: 1000 * 60 * 10,
    },
  );

  useEffect(() => {
    getApplicationDataRefetch();
  }, [debouncedSearch, filters, page, pageSize]);

  useEffect(() => {
    if (!getApplicationsData) {
      return;
    }

    const { content, totalElements } = getApplicationsData;
    setApplications(content);
    setTotal(totalElements ?? 0);
    getProductsDataRefetch();
    getTemplatesDataRefetch();
    getOrganizationsDataRefetch();
  }, [getApplicationsData]);

  const { data: getProductsData, refetch: getProductsDataRefetch } = useQuery(
    queryKeys.PRODUCT_LIST,
    () => getProductsRequest(),
    {
      refetchInterval: 1000 * 60 * 60,
    },
  );

  const { data: getTemplatesData, refetch: getTemplatesDataRefetch } = useQuery(
    queryKeys.TEMPLATE_LIST,
    () => getTemplatesRequest(),
    {
      refetchInterval: 1000 * 60 * 60,
    },
  );

  const { data: getOrganizationsData, refetch: getOrganizationsDataRefetch } =
    useQuery(queryKeys.ORGANIZATION_LIST, () => getOrganizationsRequest(), {
      refetchInterval: 1000 * 60 * 60,
    });

  useEffect(() => {
    if (!getProductsData) {
      return;
    }
    setProducts([
      products[0],
      ...getProductsData.map((data) => ({
        name: data.name ?? '',
        value: data.id,
      })),
    ]);
  }, [getProductsData]);

  useEffect(() => {
    if (!getOrganizationsData) {
      return;
    }
    setOrganizations([
      organizations[0],
      ...getOrganizationsData.map((data) => ({
        name: data.name ?? '',
        value: data.id,
      })),
    ]);
  }, [getOrganizationsData]);

  useEffect(() => {
    if (!getTemplatesData) {
      return;
    }
    setTemplates([
      templates[0],
      ...getTemplatesData.map((data) => ({
        name: data.title ?? '',
        value: data.id,
      })),
    ]);
  }, [getTemplatesData]);

  const {
    mutate: updateApplicationMutator,
    isLoading: isUpdateApplicationLoading,
  } = useMutation<
    AxiosResponse,
    AxiosError,
    ApplicationChangeStateRequest,
    unknown
  >(
    (request) =>
      updateApplicationStatusRequest(
        updateApplicationInput.applicationId,
        request,
      ),
    {
      onSuccess: () => {
        showToast({
          message: '상태가 변경되었습니다.',
          type: toastType.positive,
        });
        queryClient.invalidateQueries(queryKeys.APPLICATION_LIST());
      },
      onError: (error) => {
        showToast({
          message: `상태 변경을 실패했습니다. ${error.message}`,
          type: toastType.negative,
        });
      },
      onSettled: () => {
        setUpdateApplicationModalOpen(false);
      },
    },
  );

  const { mutate: updateMemo, isLoading: isUpdateMemoLoading } = useMutation(
    (newMemo: string) =>
      updateApplicationMemoRequest(
        updateApplicationMemoInput.applicationId,
        newMemo,
      ),
    {
      onSuccess: () => {
        showToast({
          message: '메모가 변경되었습니다.',
          type: toastType.positive,
        });
        queryClient.invalidateQueries(queryKeys.APPLICATION_LIST());
      },
      onError: () => {
        showToast({
          message: '메모 변경에 실패했습니다.',
          type: toastType.negative,
        });
      },
      onSettled: () => {
        setMemoModalOpen(false);
      },
    },
  );

  useEffect(() => {
    if (!applications) {
      return;
    }

    setCheckBoxStates(new Array(applications.length).fill(false));

    const list = applications.map((application: ApplicationPageResponse) => {
      const applyingStatus = applyingStatusOptions.find(
        (option) => option.value === application.applyingStatus,
      );
      const evaluationStatus = evaluationStatusOptions.find(
        (option) => option.value === application.evaluationStatus,
      );
      const intentionStatus = intentionStatusOptions.find(
        (option) => option.value === application.intentionStatus,
      );

      return {
        id: (
          <UnderLineData
            content={application.id ?? '-'}
            // onClick={() => {
            //   if (!application.id) {
            //     return;
            //   }
            //   navigate(`./${application.id}`);
            // }}
            isUnderLine
          />
        ),
        userId: (
          <IsHoverLight
            content={application.user?.id}
            hoverContent={
              <UnderLineData
                content={application.user?.uuid ?? '-'}
                onClick={() =>
                  showToast({
                    message: 'uuid가 복사되었습니다.',
                    type: toastType.positive,
                  })
                }
                isCopiable
                copyText={application.user?.uuid}
                isUnderLine
              />
            }
          />
        ),
        userName: (
          <UnderLineData
            content={application.user?.name ?? '-'}
            onClick={() => {
              if (application.user?.uuid) {
                window.open(
                  `${getUrls('users').cod_admin}/${application.user.uuid}`,
                );
              }
            }}
            isUnderLine
          />
        ),
        email: <IsHoverLight content={application.user?.email} />,
        mobile: <IsHoverLight content={application.user?.mobile} />,
        productOrTemplateName: (
          <IsHoverLight content={application.productOrTemplateName} />
        ),
        academy: application.paymentModel,
        applyingStatus: (
          <UnderLineData
            content={applyingStatus?.name || ''}
            onClick={() => {
              setUpdateApplicationInput({
                applicationId: application.id,
                changeType:
                  ApplicationChangeStateRequest.changeType.APPLYING_STATUS,
                modalTitle: '지원',
                value: applyingStatus?.value || undefined,
                modalOption: applyingStatusOptions,
              });
              setUpdateApplicationModalOpen(true);
            }}
            isUnderLine
          />
        ),
        submissionProgress: application.answerProgress ?? '-',
        evaluationStatus: (
          <UnderLineData
            content={evaluationStatus?.name || ''}
            onClick={() => {
              setUpdateApplicationInput({
                applicationId: application.id,
                changeType:
                  ApplicationChangeStateRequest.changeType.EVALUATION_STATUS,
                modalTitle: '심사',
                value: evaluationStatus?.value || undefined,
                modalOption: evaluationStatusOptions,
                name: application.user?.name,
              });
              setUpdateApplicationModalOpen(true);
            }}
            underLineColor
            isUnderLine
          />
        ),
        intentionStatus: (
          <UnderLineData
            content={intentionStatus?.name || ''}
            onClick={() => {
              setUpdateApplicationInput({
                applicationId: application.id,
                changeType:
                  ApplicationChangeStateRequest.changeType.INTENTION_STATUS,
                modalTitle: '탑승 희망',
                value: intentionStatus?.value || undefined,
                modalOption: intentionStatusOptions,
              });
              setUpdateApplicationModalOpen(true);
            }}
            isUnderLine
          />
        ),
        memo: (
          <Memo
            onDoneBtnClicked={() => {
              setUpdateApplicationMemoInput({
                applicationId: application.id,
                name: application.user?.name ?? '',
                memo: application.memo ?? '',
              });
              setMemoModalOpen(true);
            }}
            isHavingMemo={!!application.memo}
          />
        ),
      };
    });

    if (!list) {
      return;
    }
    setContent(list);
  }, [applications]);

  const loading =
    isGetApplicationsDataLoading ||
    isUpdateMemoLoading ||
    isUpdateApplicationLoading ||
    uploadLoading;

  return (
    <div>
      <MemoModal
        input={updateApplicationMemoInput}
        isOpen={memoModalOpen}
        onClose={() => setMemoModalOpen(false)}
        updateMemoMutator={updateMemo}
      />
      <UpdatedApplicationModal
        updateApplicationInput={updateApplicationInput}
        updateApplicationModalOpen={updateApplicationModalOpen}
        setUpdateApplicationModalOpen={setUpdateApplicationModalOpen}
        updateApplicationMutator={updateApplicationMutator}
      />

      <div className="content-wrapper">
        <div className={styles.content_wrapper}>
          <div className={styles.top_box}>
            <SearchContent
              input={search}
              setInput={setSearch}
              setCurrentPage={setPage}
              searchOptions={searchDropdownOptions.map((option) => option.name)}
              searchDropdownState={searchDropdownState}
              setSearchDropdownState={setSearchDropdownState}
              handleSearchValueSelected={handleSearchValueSelected}
            />
            <div className={styles.download_wrapper}>
              <DataContent
                filters={filters}
                search={search}
                products={products}
                applications={applications ?? []}
                checkBoxStates={checkBoxStates}
              />
            </div>

            <Button
              onClick={() => {
                setFilterOpen(!filterOpen);
              }}
              variant="skeleton"
            >
              {filterOpen ? (
                <>
                  필터 접기 <ChevronUp size={18} />
                </>
              ) : (
                <>
                  필터 펴기 <ChevronDown size={18} />
                </>
              )}
            </Button>
          </div>
          <FilterContent
            filterOpen={filterOpen}
            filters={filters}
            setFilters={setFilters}
            setCurrentPage={setPage}
            productsOptions={products}
            organizationOptions={organizations}
            templatesOptions={templates}
            resetHandler={() => {
              setFilters({});
            }}
          />
          {loading ? (
            <Loading isTable />
          ) : (
            <>
              <div className={styles.query_value_table}>
                <TablePage
                  columns={columnProps}
                  contents={content}
                  checkBoxStates={checkBoxStates}
                  setCheckBoxStates={setCheckBoxStates}
                  className={styles.query_value_table}
                  noResultMessage={
                    handleSearchValueSelected(searchDropdownState)
                      ? `"${handleSearchValueSelected(
                          searchDropdownState,
                        )}"에 대한 검색결과가 없습니다.`
                      : `필터링을 만족하는 결과가 없습니다.`
                  }
                />
              </div>
              <footer>
                <PaginationBar
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  currentPage={page}
                  setCurrentPage={setPage}
                  total={total}
                  options={[50, 30, 10]}
                />
              </footer>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationPage;
