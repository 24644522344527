/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
import { Button } from 'components/Button/Button';
import Modal from 'components/Modal';
import { SelectBox } from 'components/SelectBox/SelectBox';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import { useUpdateTestTypeSurvey } from 'utils/hooks/queries/surveys/useSurveyMutation';
import { useGetSurveyById } from 'utils/hooks/queries/surveys/useSurveyQuery';
import useToast from 'utils/hooks/useToast';

import { useState } from 'react';

import styles from './UpdateSurveyTestTypeModal.module.scss';

interface TestSurveyModalProps {
  isOpen: boolean;
  onClose: () => void;

  testModal: {
    targetId?: number;
    targetValue?: boolean;
    isOpen: boolean;
  };
}

const testOptions = [
  { name: '일반', value: false },
  { name: '테스트', value: true },
];

const UpdateSurveyTestTypeModal = ({
  isOpen,
  onClose,
  testModal,
}: TestSurveyModalProps) => {
  const { showToast, toastType } = useToast();
  const [newValue, setNewValue] = useState<boolean>(false);

  useGetSurveyById({
    surveyId: testModal.targetId ?? 0,
    options: {
      enabled: !!testModal.targetId,
      onSuccess: (data) => {
        if (data.test) {
          setNewValue(data.test);
        }
      },
    },
  });

  const { mutate: updateSurveyTestType } = useUpdateTestTypeSurvey({
    surveyId: testModal.targetId,
    test: newValue,
    options: {
      onSuccess: () => {
        showToast({
          message: '질문지 유형이 변경되었습니다.',
          type: toastType.positive,
        });
        queryClient.invalidateQueries(queryKeys.SURVEY_LIST());
      },
      onError: () => {
        showToast({
          message: '질문지 유형 변경에 실패했습니다.',
          type: toastType.negative,
        });
      },
      onSettled: () => {
        onClose();
      },
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <p>질문지 유형 설정</p>
          <Modal.Header.SubTitle>
            질문지 유형을 테스트로 설정할 수 있습니다.
          </Modal.Header.SubTitle>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.selectBox}>
            <SelectBox
              className={styles.dropdown}
              value={newValue}
              options={testOptions}
              onChange={(_newValue: boolean) => {
                setNewValue(_newValue);
              }}
              hasCheckIcon
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={updateSurveyTestType}>확인</Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default UpdateSurveyTestTypeModal;
