import { TITLE_TYPING_LIMIT } from 'constants/common';

import { Button } from 'components/Button/Button';
import Input from 'components/Input/Input';
import Modal from 'components/Modal';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import { useCreateSurvey } from 'utils/hooks/queries/surveys/useSurveyMutation';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './CreateSurveyModal.module.scss';

interface Props {
  title: string;
  setTitle: (letters: string) => void;
  titleDebounceQuery: string;
  isOpen: boolean;
  onClose: () => void;
}

const CreateSurveyModal = ({
  title,
  setTitle,
  titleDebounceQuery,
  isOpen,
  onClose,
}: Props) => {
  const navigate = useNavigate();

  const [error, setError] = useState({
    isError: false,
    message: '',
  });

  const { mutate: createSurvey, isLoading: isCreateSurveyLoading } =
    useCreateSurvey();

  useEffect(() => {
    if (isOpen) {
      setTitle('');
      setError({
        isError: true,
        message: '',
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (titleDebounceQuery === '') {
      setError({ isError: true, message: '질문지 제목을 입력해주세요' });
      return;
    }
    if (titleDebounceQuery.length > TITLE_TYPING_LIMIT) {
      setError({
        isError: true,
        message: `${TITLE_TYPING_LIMIT}자까지만 입력할 수 있습니다.`,
      });
      return;
    }

    setError({ isError: false, message: '' });
  }, [titleDebounceQuery]);

  const handleCreateSurvey = () => {
    createSurvey(
      { title },
      {
        onSuccess: ({ data }) => {
          if (data.id) {
            navigate(`./${data.id}`);
          }
          queryClient.invalidateQueries(queryKeys.SURVEY_LIST());
        },
        onError: () => {
          setError({
            isError: true,
            message: '중복된 질문지 제목은 입력할 수 없습니다.',
          });
        },
      },
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setTitle('');
      }}
    >
      <Modal.Content>
        <Modal.Header>질문지 제목 입력</Modal.Header>
        <Modal.Body>
          <div className={styles.modal_wrapper}>
            <Input
              placeholder="질문지 제목을 입력해주세요"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              isInvalid={!!error.message}
            />
            {error.message && (
              <div className={styles.input_error_message}>{error.message}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCreateSurvey}
            isDisabled={error.isError}
            isLoading={isCreateSurveyLoading}
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default CreateSurveyModal;
