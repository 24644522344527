import type { VariableResponse } from 'generated';

const SURVEY = 'survey';
const ORGANIZATION = 'organization';
const SURVEYSET = 'survey-set';
const SURVEYSET_FOR_EVA = 'survey-set-for-eva';
const APPLICATION = 'application';
const TEMPLATE = 'template';
const PRODUCT = 'product';
const STANDARD = 'standard';
const VARIABLE = 'variable';
const LOGIC = 'logic';

const LIST = 'list';
const DETAIL = 'detail';
const DOWNLOAD = 'download';

const queryKeys = {
  // survey
  SURVEY_LIST: (options: any = {}) => [SURVEY, LIST, { ...options }] as const,
  SURVEY_DETAIL_BY_ID: (id: number) => [SURVEY, DETAIL, { id }] as const,
  SURVEY_DETAIL_BY_UUID: (uuid: string) => [SURVEY, DETAIL, { uuid }] as const,
  // survey set
  SURVEYSET_LIST: (options: any = {}) =>
    [SURVEYSET, LIST, { ...options }] as const,
  SURVEYSET_DETAIL_BY_ID: (id: number) => [SURVEYSET, DETAIL, { id }] as const,
  SURVEYSET_DETAIL_BY_UUID: (uuid: string) =>
    [SURVEYSET, DETAIL, { uuid }] as const,
  // survey set for evaluation standards
  SURVEYSET_FOR_EVA_DETAIL_BY_ID: (id: number) =>
    [SURVEYSET_FOR_EVA, DETAIL, { id }] as const,
  // application
  APPLICATION_LIST: (options: any = {}) =>
    [APPLICATION, LIST, { ...options }] as const,
  APPLICATION_DETAIL_BY_ID: (id: number | undefined) =>
    [APPLICATION, DETAIL, { id }] as const,
  APPLICATION_DETAIL_BY_UUID: (uuid: string | undefined) =>
    [APPLICATION, DETAIL, { uuid }] as const,
  APPLICATION_DOWNLOAD: (type: string, date: Date) =>
    [APPLICATION, DOWNLOAD, { type, date }] as const,
  // product
  PRODUCT_LIST: [PRODUCT, LIST] as const,
  ORGANIZATION_LIST: [ORGANIZATION, LIST] as const,
  TEMPLATE_LIST: [TEMPLATE, LIST] as const,
  PRODUCT_DETAIL_BY_UUID: (uuid: string | undefined) =>
    [PRODUCT, DETAIL, { uuid }] as const,
  // standard
  STANDARD_DETAIL_BY_ID: (templateId: number | undefined) =>
    [
      STANDARD,
      DETAIL,
      ...(templateId !== undefined ? [{ templateId }] : []),
    ] as const,
  // variables
  VARIABLE_LIST: (itemId: number, varTypes?: VariableResponse.varType[]) =>
    [VARIABLE, LIST, { itemId }, ...(varTypes ? [{ varTypes }] : [])] as const,
  LOGIC_LIST: (itemId: number) => [LOGIC, LIST, { itemId }] as const,
};

export default queryKeys;
