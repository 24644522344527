/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IssueTokenResponse = {
    authType?: IssueTokenResponse.authType;
    token?: string;
    expiredAt?: string;
};

export namespace IssueTokenResponse {

    export enum authType {
        GOOGLE = 'GOOGLE',
        EMAIL = 'EMAIL',
    }


}

