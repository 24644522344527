import { CozSurveyTheme } from 'styles/CozSurveyTheme';
import { SurveyCreatorComponent, StylesManager } from 'survey-creator-react';

import { useEffect, useRef } from 'react';

import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import 'styles/coz-survey.css';

import type { SurveyCreator } from 'survey-creator-react';

interface Props {
  creator: SurveyCreator;
  surveyId: number;
  isUsed: boolean;
  isTimerStarted: boolean;
  onBlockedModification: () => void;
  timerDelay?: number;
}

const blockedModifications = [
  'ADDED_FROM_TOOLBOX',
  'PAGE_ADDED',
  'PAGE_MOVED',
  'OBJECT_DELETED',
];

const SurveyCreatorWidget = ({
  creator,
  surveyId,
  isUsed,
  onBlockedModification,
  isTimerStarted,
  timerDelay = 1000 * 10,
}: Props) => {
  const autoSaveCallback = () => {
    if (isTimerStarted) {
      window.localStorage.setItem(`survey-${surveyId}`, creator.text);
    }
  };
  const capturedAutoSaveCallback = useRef<() => void>();

  useEffect(() => {
    capturedAutoSaveCallback.current = autoSaveCallback;
  }, [autoSaveCallback]);

  useEffect(() => {
    StylesManager.applyTheme('modern');

    creator.onModified.add((sender, option) => {
      const { type } = option;
      if (isUsed && blockedModifications.includes(type)) {
        onBlockedModification();
        creator.undo();
      }
    });

    const executeAutoSaveCallback = () => {
      if (!capturedAutoSaveCallback.current) {
        return;
      }
      capturedAutoSaveCallback.current();
    };
    const autoSaveTimer = setInterval(executeAutoSaveCallback, timerDelay);
    return () => clearInterval(autoSaveTimer);
  }, []);

  return (
    <CozSurveyTheme>
      <SurveyCreatorComponent creator={creator} />
    </CozSurveyTheme>
  );
};

export default SurveyCreatorWidget;
