/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FaqContentResponse } from './FaqContentResponse';

export type LandingPageFaqResponse = {
    id?: number;
    bootCampCategory?: LandingPageFaqResponse.bootCampCategory;
    sequence?: number;
    title?: string;
    contents?: Array<FaqContentResponse>;
};

export namespace LandingPageFaqResponse {

    export enum bootCampCategory {
        ALL = 'ALL',
        SEBFE = 'SEBFE',
        SEBBE = 'SEBBE',
        UUB = 'UUB',
        GMB = 'GMB',
        DOB = 'DOB',
        PMB = 'PMB',
        BEB = 'BEB',
        AIB = 'AIB',
    }


}

