import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Select: ComponentStyleConfig = {
  baseStyle: {
    icon: {
      color: 'bluishGray.400',
    },
    field: {
      border: '1px solid',
      borderColor: 'bluishGray.300',
    },
  },
  variants: {
    default: {
      field: {
        bg: 'white',
        color: 'gray.800',
        transition: 'border 0.3s, borderColor 0.3s',
        _focus: {
          border: '3px solid',
          borderColor: 'blue.300',
        },
      },
    },
    blue: {
      field: {
        bg: 'bluishGray.100',
        color: 'gray.600',
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
};
