import queryAxios from 'query/queryAxios';

import type {
  VariableResponse,
  TemplateResponse,
  VariableUpsertRequest,
} from 'generated';

export const getVariables = async (
  itemId: number,
  varTypes: VariableResponse.varType[],
) =>
  queryAxios()
    .get<VariableResponse[]>(
      `/template-items/${itemId}/variables?varTypes=${varTypes}`,
    )
    .then((res) => res.data);

export const getSurveySetRequest = async (id: number) =>
  queryAxios().get<TemplateResponse>(`templates/${id}`);

export const createNewVariable = async (
  itemId: number,
  request: VariableUpsertRequest[],
) => queryAxios().post(`template-items/${itemId}/variables`, request);
