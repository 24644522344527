import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import { AnswerChangeStateRequest } from 'generated';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import useHeader from 'utils/hooks/useHeader';

import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery, useMutation } from '@tanstack/react-query';

import styles from './ApplicationDetailPage.module.scss';
import InformationItems from './InformationItem/InformationItems';
import StatusCard from './StatusCard/StatusCard';
import StatusSummary from './StatusSummary/StatusSummary';
import {
  getApplicationByIdRequest,
  getApplicationByUuidRequest,
  updateAnswerStatusRequest,
  createAndUpdateStatusRequest,
} from './requests';

import type {
  ApplicationAnswerResponse,
  ApplicationFullResponse,
} from 'generated';

const ApplicationPage = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [applicationId, setApplicationId] = useState<number>();
  const [applicationUuid, setApplicationUuid] = useState<string>();
  const [application, setApplication] = useState<ApplicationFullResponse>();

  const {
    data: getApplicationByIdData,
    isLoading: isGetApplicationByIdLoading,
    isError: isGetApplicationByIdError,
  } = useQuery(
    queryKeys.APPLICATION_DETAIL_BY_ID(applicationId),
    () => getApplicationByIdRequest(applicationId),
    {
      enabled: !!applicationId,
      refetchInterval: 1000 * 60 * 10,
    },
  );

  const {
    data: getApplicationByUuidData,
    isLoading: isGetApplicationByUuidLoading,
    isError: isGetApplicationByUuidError,
  } = useQuery(
    queryKeys.APPLICATION_DETAIL_BY_UUID(applicationUuid),
    () => getApplicationByUuidRequest(applicationUuid),
    {
      enabled: !applicationId && !!applicationUuid,
      refetchInterval: 1000 * 60 * 10,
    },
  );

  const { mutate: updateAnswerStatus, isError: isUpdateAnswerStatusError } =
    useMutation(
      ({
        answerId,
        request,
      }: {
        answerId: number | undefined;
        request: AnswerChangeStateRequest | undefined;
      }) =>
        updateAnswerStatusRequest(
          application?.user?.uuid,
          application?.uuid,
          answerId,
          request,
        ),
    );

  const {
    mutate: createAndUpdateAnswerStatus,
    isError: isCreateAndUpdateAnswerStatusError,
  } = useMutation((itemId: number | undefined) =>
    createAndUpdateStatusRequest(
      application?.user?.uuid,
      application?.uuid,
      itemId,
    ),
  );

  useEffect(() => {
    if (Number.isNaN(Number(id))) {
      setApplicationUuid(id);
    } else {
      setApplicationId(Number(id));
    }
  }, []);

  useEffect(() => {
    if (!getApplicationByIdData) {
      return;
    }
    setApplication(getApplicationByIdData);
  }, [getApplicationByIdData]);

  useEffect(() => {
    if (!getApplicationByUuidData) {
      return;
    }

    if (!application) {
      setApplication(getApplicationByUuidData);
      const { id } = getApplicationByUuidData;
      if (id) {
        setApplicationId(id);
      }
    }
  }, [getApplicationByUuidData]);

  const changeAnswerStatusSubmitted = async (
    itemId?: number | undefined,
    answerId?: number,
    changeFrom?: ApplicationAnswerResponse.status,
  ) => {
    if (!answerId) {
      createAndUpdateAnswerStatus(itemId, {
        onSuccess: () => {
          queryClient.invalidateQueries(
            queryKeys.APPLICATION_DETAIL_BY_ID(applicationId),
          );
          queryClient.invalidateQueries(
            queryKeys.APPLICATION_DETAIL_BY_UUID(applicationUuid),
          );
        },
      });
      return;
    }

    updateAnswerStatus(
      {
        answerId,
        request: changeFrom && {
          changeFrom: AnswerChangeStateRequest.changeFrom[changeFrom],
          changeTo: AnswerChangeStateRequest.changeTo.SUBMITTED,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            queryKeys.APPLICATION_DETAIL_BY_ID(applicationId),
          );
          queryClient.invalidateQueries(
            queryKeys.APPLICATION_DETAIL_BY_UUID(applicationUuid),
          );
        },
      },
    );
  };

  const changeAnswerStatusCancelled = (
    answerId: number | undefined,
    changeFrom: ApplicationAnswerResponse.status | undefined,
  ) => {
    updateAnswerStatus(
      {
        answerId,
        request: changeFrom && {
          changeFrom: AnswerChangeStateRequest.changeFrom[changeFrom],
          changeTo: AnswerChangeStateRequest.changeTo.CANCELLED,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            queryKeys.APPLICATION_DETAIL_BY_ID(applicationId),
          );
          queryClient.invalidateQueries(
            queryKeys.APPLICATION_DETAIL_BY_UUID(applicationUuid),
          );
        },
      },
    );
  };

  useHeader({
    title: '지원 상세',
    pageInfo: [
      { page: '지원 관리', onClick: () => navigate('/application') },
      { page: '지원 상세' },
    ],
  });

  if (
    isGetApplicationByIdLoading ||
    (!applicationId && isGetApplicationByUuidLoading)
  ) {
    return <Loading />;
  }
  if (
    isGetApplicationByIdError ||
    isGetApplicationByUuidError ||
    !application
  ) {
    return <Error isFull />;
  }

  return (
    <div>
      <div className={`content-wrapper ${styles.content_wrapper}`}>
        <section className={styles.information}>
          <div className={styles.section_header}>
            <h3>지원자 정보</h3>
          </div>
          <InformationItems
            isLoading={isGetApplicationByIdLoading}
            id={applicationId}
            name={application.user?.name}
            email={application.user?.email}
            mobile={application.user?.mobile}
            productName={application.template?.product?.name}
            programName={application.template?.product?.programName}
            paymentModel={application.template?.product?.academy}
            createdAt={application.createdAt}
          />
        </section>
      </div>

      <div className={styles.divider} />

      <div className={`content-wrapper ${styles.content_wrapper}`}>
        <section className={styles.status}>
          <div className={styles.section_header}>
            <h3>지원 진행 상태</h3>
            <StatusSummary
              progress={application.answerProgress}
              applyingStatus={application.applyingStatus}
            />
          </div>
          <div className={styles.status_item_wrapper}>
            {application.template?.items &&
              application.template.items.map((item) => (
                <StatusCard
                  key={`status-card-${item.id}`}
                  templateItem={item}
                  isError={
                    isUpdateAnswerStatusError ||
                    isCreateAndUpdateAnswerStatusError
                  }
                  answer={application.answers?.find(
                    (answer) => answer.itemId === item.id,
                  )}
                  onChangeStatusSubmitted={changeAnswerStatusSubmitted}
                  onChangeStatusCancelled={changeAnswerStatusCancelled}
                />
              ))}
          </div>
          <div className={styles.status_item_wrapper} />
        </section>
      </div>
    </div>
  );
};

export default ApplicationPage;
