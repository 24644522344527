import CopyToClipboard from 'react-copy-to-clipboard';
import * as Icon from 'react-feather';

import styles from './UnderlineData.module.scss';

interface Props {
  content: string | number;
  isUnderLine?: boolean;
  isCopiable?: boolean;
  copyText?: string;
  onClick?: () => void;
  underLineColor?: boolean;
}
const UnderLineData = ({
  onClick,
  content,
  isUnderLine,
  underLineColor,
  isCopiable,
  copyText,
}: Props) => {
  const setUnderLineColor = (content: string) => {
    switch (content) {
      case '합격':
        return 'blue';
      case '불합격':
        return 'red';
      case '구제 대기':
        return 'gray';
      default:
        return 'black';
    }
  };
  return (
    <div className={styles.wrapper}>
      {isCopiable && copyText ? (
        <>
          <span className={`${styles.copiable}`}>{content}</span>
          <CopyToClipboard text={copyText}>
            <Icon.Copy onClick={onClick} size={20} color="#5C5C5C" />
          </CopyToClipboard>
        </>
      ) : (
        <button
          onClick={onClick}
          className={`${isUnderLine ? styles.underline : ''} ${
            underLineColor && typeof content === 'string'
              ? styles[setUnderLineColor(content)]
              : ''
          }`}
        >
          {' '}
          {content}
        </button>
      )}
    </div>
  );
};

export default UnderLineData;
