import classNames from 'classnames';
import { Button } from 'components/Button/Button';
import { useSidebarStore } from 'store';

import { PlusCircle } from 'react-feather';

import styles from './PageFooter.module.scss';

interface PageFootertProps {
  variant: '변수' | '규칙';
  title: string;
  count: number;
  onAdd: () => void;
  onSave: () => void;
  onInit?: () => void;
  beforeAnnounce?: boolean; // 합격발표일 지난 여부
}

const PageFooter = ({
  variant,
  title,
  count,
  onAdd,
  onSave,
  onInit,
  beforeAnnounce,
}: PageFootertProps) => {
  const { sidebarStatus } = useSidebarStore();

  return (
    <footer
      className={classNames(
        styles.page_footer,
        styles[`sidebar_${sidebarStatus}`],
      )}
    >
      <div className={styles.content_wrapper}>
        <div className={styles.left}>
          <h3 className={styles.title}>{title}</h3>
          <span className={styles.count}>
            {variant}&nbsp;{count}개
          </span>
        </div>
        <div className={styles.right}>
          {beforeAnnounce ? (
            <>
              {variant === '규칙' && onInit && (
                <button
                  onClick={onInit}
                  className={styles.init_btn}
                  type="button"
                >
                  전체 초기화
                </button>
              )}
              <Button
                onClick={onAdd}
                variant="line-skeleton"
                className={styles.add_btn}
              >
                새 {variant} 추가&nbsp;
                <PlusCircle />
              </Button>
              <Button onClick={onSave}>저장</Button>
            </>
          ) : (
            <p className={styles.unannounced}>
              합격발표일이 지나 심사 기준을 변경할 수 없는 상태입니다.
            </p>
          )}
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
