/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AnswerChangeStateRequest = {
    changeFrom?: AnswerChangeStateRequest.changeFrom;
    changeTo?: AnswerChangeStateRequest.changeTo;
};

export namespace AnswerChangeStateRequest {

    export enum changeFrom {
        CREATED = 'CREATED',
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        SUBMITTED = 'SUBMITTED',
        RECREATED = 'RECREATED',
    }

    export enum changeTo {
        CREATED = 'CREATED',
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        SUBMITTED = 'SUBMITTED',
        RECREATED = 'RECREATED',
    }


}

