import axios from 'axios';

import { requestJwtInterceptor } from './requestJwtInterceptor';
import { responseTokenInterceptor } from './responseTokenInterceptor';
import { API_ENDPOINT } from '../constants/env';

import type { AxiosInstance } from 'axios';

export const baseURL = `${API_ENDPOINT}v2/`;

const queryAxios = () => {
  const instance: AxiosInstance = axios.create({ baseURL });
  requestJwtInterceptor(instance);
  responseTokenInterceptor(instance);

  return instance;
};

export default queryAxios;
