export const ExecutionType = {
  ARITHMETIC: 'ARITHMETIC',
  TEXT_REPLACEMENT: 'TEXT_REPLACEMENT',
} as const;
export type ExecutionType = (typeof ExecutionType)[keyof typeof ExecutionType];

export const ExecutionArithmeticVarType = {
  ANSWER: 'ANSWER',
  VARIABLE: 'VARIABLE',
  INPUT: 'INPUT',
} as const;
export type ExecutionArithmeticVarType =
  (typeof ExecutionArithmeticVarType)[keyof typeof ExecutionArithmeticVarType];

export const InputStyle = {
  DISABLED: 'DISABLED',
  STRING: 'STRING',
  NUMBER: 'NUMBER',
  DATE: 'DATE',
  CHOICE: 'CHOICE',
  BLOCKED: 'BLOCKED',
} as const;
export type InputStyle = (typeof InputStyle)[keyof typeof InputStyle];

export const ConditionLeftType = {
  VARIABLE: 'VARIABLE',
  ANSWER: 'ANSWER',
} as const;
export type ConditionLeftType =
  (typeof ConditionLeftType)[keyof typeof ConditionLeftType];
