import { useToast as useChakraToast } from '@chakra-ui/react';

import Toast, { POSITIVE, NEGATIVE } from '../../components/Toast/Toast';

import type { ToastProps } from '../../components/Toast/Toast';

export default function useToast() {
  const toast = useChakraToast();

  return {
    showToast: ({ message, type }: ToastProps) => {
      const randomId = Math.floor(Math.random() * 100000);

      toast({
        id: `toast-${randomId}`,
        position: 'top',
        duration: 2000,
        render: () => (
          <Toast
            message={message}
            type={type}
            onClose={() => toast.close(`toast-${randomId}`)}
          />
        ),
      });
    },
    toastType: {
      positive: POSITIVE,
      negative: NEGATIVE,
    },
  };
}
