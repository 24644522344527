import styles from './Error.module.scss';
import { ReactComponent as ErrorComponent } from '../../assets/error.svg';

interface ErrorProps {
  iconSize?: {
    width: number | string;
    height: number | string;
  };
  isFull?: boolean;
  message?: string;
}

const Error = ({
  iconSize = {
    width: 100,
    height: 100,
  },
  isFull = false,
  message = '페이지를 불러올 수 없습니다.\n다시 시도해주세요.',
}: ErrorProps) => (
  <div className={isFull ? styles.error_container : ''}>
    <div className={styles.wrapper}>
      <ErrorComponent width={iconSize.width} height={iconSize.height} />
      {message && <p>{message}</p>}
    </div>
  </div>
);

export default Error;
