import { ApplicationPageResponse } from 'generated';

import styles from './DataContent.module.scss';
import DownloadDataButton from '../Button/DownloadDataButton';
import DownloadSurveyButton from '../Button/DownloadSurveyButton';

import type { ApplicationPageFilter, ApplicationPageSearch } from 'generated';

interface Props {
  filters: ApplicationPageFilter;
  search: ApplicationPageSearch;
  products: { name: string; value?: number }[];
  applications: ApplicationPageResponse[];
  checkBoxStates: boolean[];
}
const DataContent = ({
  filters,
  search,
  products,
  applications,
  checkBoxStates,
}: Props) => {
  const checkedIds = applications
    .map((app) => Number(app.id))
    .filter((id) => !Number.isNaN(id))
    .filter((id, index) => checkBoxStates[index]);

  return (
    <>
      <DownloadDataButton
        disabled={applications.length === 0}
        filters={filters}
        search={{
          ...search,
          applicationIds:
            checkedIds && checkedIds.length > 0
              ? checkedIds
              : search.applicationIds,
        }}
      />
      <div className={styles.button_wrapper}>
        <DownloadSurveyButton
          filters={filters}
          search={{
            ...search,
            applicationIds:
              checkedIds && checkedIds.length > 0
                ? checkedIds
                : search.applicationIds,
          }}
          products={products}
          applications={applications ?? []}
          hasCheckedApplicationIds={checkBoxStates.includes(true)}
          hasCheckedApplicationIncompleted={
            applications
              .map((application) => application.applyingStatus)
              .filter((_, index) => checkBoxStates[index])
              .filter(
                (status) =>
                  status !== ApplicationPageResponse.applyingStatus.COMPLETED,
              ).length > 0
          }
        />
      </div>
    </>
  );
};

export default DataContent;
