import { TOKEN_KEY } from 'hooks/useAuth/jwtTokenParser';

import type { AxiosInstance } from 'axios';

export const paramSerializer = (params?: any) => {
  const leafNodes: { key: string; value: any }[] = [];
  const getLeaf = (node: { [key: string]: any }) => {
    for (const key in node) {
      if ({}.hasOwnProperty.call(node, key)) {
        if (typeof node[key] === null || undefined) {
          return;
        }
        if (typeof node[key] === 'object') {
          if (Array.isArray(node[key])) {
            node[key].forEach((value: any) => {
              leafNodes.push({ key, value });
            });
          } else {
            getLeaf(node[key]);
          }
        } else {
          leafNodes.push({ key, value: node[key] });
        }
      }
    }
  };
  getLeaf(params);

  return leafNodes
    .map(({ key, value }) => `${key}=${value !== undefined ? value : ''}`)
    .join('&');
};

export const requestJwtInterceptor = (axios: AxiosInstance) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(TOKEN_KEY);
  axios.defaults.headers.common.Authorization = `Bearer ${token || undefined}`;

  axios.interceptors.request.use((config) => {
    config.paramsSerializer = (params) => paramSerializer(params);
    return config;
  });
};
