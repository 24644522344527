import { useState } from 'react';

export interface AlertContentType {
  title: string;
  subTitle?: string;
  doneLabel: string;
  onDoneClick: () => void;
  cancelLabel?: string;
  onCancelClick?: () => void;
  caption?: string;
  onClose?: () => void;
}

export const useAlert = (initialMode = false) => {
  const [isAlertOpen, setIsAlertOpen] = useState(initialMode);
  const [alertContent, setAlertContent] = useState<AlertContentType>({
    title: '',
    doneLabel: 'Done',
    onDoneClick: () => console.log('alert'),
  });
  return { isAlertOpen, setIsAlertOpen, alertContent, setAlertContent };
};
