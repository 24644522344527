/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from 'components/Button/Button';
import getUrls from 'utils/urls';

import styles from './ProductSection.module.scss';
import ProductSectionTab from '../ProductSectionTab/ProductSectionTab';
import SectionTemplate from '../SectionTemplate/SectionTemplate';

import type { ProductResponse } from 'generated';

interface ProductSectionProps {
  products?: ProductResponse[];
}

const ProductSection = ({ products }: ProductSectionProps) => (
  <SectionTemplate ordinal={3} title="연결된 상품">
    <div className={styles.survey_section_wrapper}>
      {products?.length ? (
        <>
          <ul className={styles.product_list}>
            {products.map((product) => (
              <ProductSectionTab
                key={product.id}
                productType={product.academy}
                name={product.name || ''}
                id={product.uuid || ''}
              />
            ))}
          </ul>
          <div className={styles.link_product}>
            <a
              href={getUrls('product').cod_admin}
              target="_blank"
              rel="noreferrer"
            >
              상품 더 연결하기
            </a>
          </div>
        </>
      ) : (
        <div className={styles.blank_section}>
          <div className={styles.blank_notice}>
            <p>상품은 Urclass Admin에서 연결하실 수 있습니다.</p>
            <p>상품 연결 후에는 질문지 세트 정보를 변경할 수 없습니다.</p>
          </div>
          <a
            href={getUrls('product').cod_admin}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.button_box}>
              <Button variant="skeleton" isFullWidth>
                Urclass Admin 가기
              </Button>
            </div>
          </a>
        </div>
      )}
    </div>
  </SectionTemplate>
);

export default ProductSection;
