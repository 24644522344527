/* eslint-disable @typescript-eslint/no-explicit-any */
import { StylesManager, Model, surveyLocalization } from 'survey-core';
import { Survey } from 'survey-react-ui';

import { useCallback } from 'react';

import 'survey-core/modern.min.css';
import { CozSurveyTheme } from '../../../../styles/CozSurveyTheme';
import { koreanStrings } from '../../../../utils/surveyLocalization';
import '../../../../styles/coz-survey.css';

interface DefinitionType {
  logoPosition?: string;
  pages?: {
    elements: any;
    name: string;
  };
}
interface Props {
  definition: DefinitionType;
}

surveyLocalization.locales.ko = koreanStrings;
surveyLocalization.localeNames.ko = '한국어';

StylesManager.applyTheme('modern');

const PreviewSurvey = ({ definition }: Props) => {
  const survey = new Model(definition);

  // 파일 문항은 무조건 base64 URL 텍스트로 저장
  const questions = survey.getAllQuestions();
  for (const question of questions) {
    const type = question.getType();
    if (type === 'file') {
      question.setPropertyValue('storeDataAsText', true);
    }
  }

  survey.focusFirstQuestionAutomatic = false;
  survey.locale = 'ko';

  const alertResults = useCallback((sender: { data: any }) => {
    const results = JSON.stringify(sender.data);
    alert(results);
  }, []);

  survey.onComplete.add(alertResults);

  return (
    <CozSurveyTheme>
      <Survey model={survey} />
    </CozSurveyTheme>
  );
};

export default PreviewSurvey;
