import styles from './ApplicationPage.module.scss';

interface Props {
  onDoneBtnClicked: () => void;
  isHavingMemo: boolean;
}
const Memo = ({ onDoneBtnClicked, isHavingMemo }: Props) => (
  <button
    onClick={onDoneBtnClicked}
    className={[
      styles.memo_btn,
      isHavingMemo ? styles.memo_btn_true : styles.memo_btn_false,
    ].join(' ')}
  >
    메모
  </button>
);

export default Memo;
