import FullScreenLoader from 'components/FullScreenLoader/FullScreenLoader';
import { TitleHelmet } from 'components/Helmet';

import { Outlet } from 'react-router-dom';

const RootLayout = () => (
  <>
    <TitleHelmet />
    <FullScreenLoader />
    <Outlet />
  </>
);

export default RootLayout;
