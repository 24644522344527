/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EvaluationRequest = {
    requestType: EvaluationRequest.requestType;
    ids: Array<number>;
    selectedNumber?: number;
};

export namespace EvaluationRequest {

    export enum requestType {
        APPLICATION = 'APPLICATION',
        TEMPLATE = 'TEMPLATE',
    }


}

