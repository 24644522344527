/* eslint-disable @typescript-eslint/no-use-before-define */
import { ExecutionResponse, VariableResponse } from 'generated';
import { find } from 'lodash';

import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { useLogicsActions, useVariables } from '../../../store';
import { ExecutionType, InputStyle, ExecutionArithmeticVarType } from '../type';
import { generateArithmeticText } from '../util';

import type { ExecutionProps } from './Execution';

import VarType = VariableResponse.varType;
import ValueType = VariableResponse.valueType;

const BLOCKED_FIXED = ['TRAINING_START', 'TRAINING_END', 'NOW'];

const useExecution = ({ clientId, execution, isValid }: ExecutionProps) => {
  const variables = useVariables();
  const { updateLogicExecution, deleteLogicExecution, cleanLogicIsValid } =
    useLogicsActions();
  const [arithmeticVarType, setArithmeticVarType] =
    useState<ExecutionArithmeticVarType>();

  const withCleanValidation =
    <T extends any[]>(handler: (...args: T) => void) =>
    (...args: T) => {
      handler(...args);
      cleanLogicIsValid(clientId);
    };

  const executionType = useMemo(() => {
    if (!execution.operator) {
      return;
    }
    return execution.operator === ExecutionResponse.operator.ASSIGN
      ? ExecutionType.TEXT_REPLACEMENT
      : ExecutionType.ARITHMETIC;
  }, [execution.operator]);

  const variableOptions = useMemo(() => {
    const filterAndConvert = (
      varTypes: VarType[],
      valueType?: ValueType,
      blockedValues?: string[],
    ) =>
      variables
        .filter((v) => v.varType && varTypes.includes(v.varType))
        .filter((v) => (!valueType ? true : v.valueType === valueType))
        .filter((v) => v.name && !blockedValues?.includes(v.name))
        .map((v) => ({
          value: v.id,
          name: varTypes.includes(VarType.ANSWER)
            ? `[${v.name}] ${v.title}`
            : v.title ?? '',
        }));

    return {
      VARIABLE: filterAndConvert(
        [VarType.CUSTOM, VarType.FIXED],
        undefined,
        BLOCKED_FIXED,
      ),
      ANSWER: filterAndConvert([VarType.ANSWER], undefined),
      VARIABLE_NUM: filterAndConvert(
        [VarType.CUSTOM, VarType.FIXED],
        ValueType.NUMBER,
        BLOCKED_FIXED,
      ),
      ANSWER_NUM: filterAndConvert([VarType.ANSWER], ValueType.NUMBER),
    };
  }, [variables]);

  const leftVariable = useMemo(() => {
    if (!execution.leftVariableId) {
      return;
    }
    return find(variables, { id: execution.leftVariableId });
  }, [execution.leftVariableId, variables]);

  const rightVariable = useMemo(() => {
    if (!execution.rightVariableId) {
      return;
    }
    return find(variables, { id: execution.rightVariableId });
  }, [execution.rightVariableId, variables]);

  const isInvalidActive = useMemo(() => isValid === false, [isValid]);

  const handleDeleteExecution = () => {
    if (execution.sequence === undefined) {
      return;
    }
    deleteLogicExecution(clientId, execution.sequence);
  };

  const handleExecutionTypeChange = withCleanValidation(
    (value: ExecutionType) => {
      if (executionType === value) {
        return;
      }
      const operator =
        value === ExecutionType.TEXT_REPLACEMENT
          ? ExecutionResponse.operator.ASSIGN
          : ExecutionResponse.operator.PLUS;
      updateLogicExecution(clientId, {
        id: execution.id,
        sequence: execution.sequence,
        operator,
      });
      setArithmeticVarType(undefined);
    },
  );

  const handleLeftValueChange = withCleanValidation((value: number) => {
    if (value === execution.leftVariableId) {
      return;
    }
    updateLogicExecution(clientId, {
      ...execution,
      leftVariableId: value,
    });

    if (executionType === ExecutionType.TEXT_REPLACEMENT && leftVariable) {
      const newLeftVariable = find(variables, { id: value });
      if (!leftVariable || !newLeftVariable) {
        return;
      }

      const { valueType: prevValueType } = leftVariable;
      const { valueType: nextValueType } = newLeftVariable;

      if (prevValueType !== nextValueType) {
        updateLogicExecution(clientId, {
          ...execution,
          leftVariableId: value,
          rightVariableId: undefined,
          rightValue: undefined,
        });
      } else if (nextValueType === 'CHOICE' || nextValueType === 'LIST') {
        updateLogicExecution(clientId, {
          ...execution,
          leftVariableId: value,
          rightVariableId: undefined,
          rightValue: undefined,
        });
      }
    }
  });

  const handleRightInputChange = withCleanValidation(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      updateLogicExecution(clientId, {
        ...execution,
        rightValue: value,
      });
    },
  );

  const preventInputOnWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    e.currentTarget.blur();
    e.stopPropagation();
  };

  // replacement
  const replacementInputStyle = useMemo(() => {
    if (!leftVariable) {
      return InputStyle.DISABLED;
    }

    const { valueType } = leftVariable;
    if (valueType) {
      switch (valueType) {
        case VariableResponse.valueType.CHOICE:
        case VariableResponse.valueType.LIST:
          return InputStyle.CHOICE;
        case VariableResponse.valueType.DATE:
          return InputStyle.DATE;
        case VariableResponse.valueType.NUMBER:
          return InputStyle.NUMBER;
        default:
          return InputStyle.STRING;
      }
    }
    return InputStyle.DISABLED;
  }, [leftVariable]);

  const replacementChoiceOptions = useMemo(() => {
    if (replacementInputStyle !== InputStyle.CHOICE) {
      return;
    }
    if (!leftVariable || !leftVariable.choices) {
      return;
    }
    return leftVariable.choices.map(({ value, text }) => ({
      value,
      name: text ?? '',
    }));
  }, [leftVariable, replacementInputStyle]);

  const handleReplacementChoiceChange = withCleanValidation((value: string) => {
    if (value === execution.rightValue) {
      return;
    }
    const leftVariable = find(variables, { id: execution.leftVariableId });
    const choice = find(leftVariable?.choices, { value });
    updateLogicExecution(clientId, {
      ...execution,
      rightValue: choice?.value,
    });
  });

  const handleReplacementDateChange = withCleanValidation((value?: string) => {
    if (value === execution.rightValue) {
      return;
    }
    updateLogicExecution(clientId, {
      ...execution,
      rightValue: value,
    });
  });

  // arithmetic
  const arithmeticText = useMemo(() => {
    const leftVariable = find(variables, { id: execution.leftVariableId });
    if (!leftVariable) {
      return;
    }
    return generateArithmeticText(
      leftVariable.title,
      arithmeticVarType,
      execution.rightValue,
      execution.operator,
    );
  }, [variables, execution, arithmeticVarType]);

  const handleArithmeticOperatorChange = (
    value: ExecutionResponse.operator,
  ) => {
    updateLogicExecution(clientId, {
      ...execution,
      operator: value,
    });
  };

  const handleArithmeticVarTypeChange = withCleanValidation(
    (value: ExecutionArithmeticVarType) => {
      if (value === arithmeticVarType) {
        return;
      }
      setArithmeticVarType(value);
      updateLogicExecution(clientId, {
        ...execution,
        rightVariableId: undefined,
        rightValue: undefined,
      });
    },
  );

  const handleArithmeticSelectChange = withCleanValidation((value: number) => {
    if (value === execution.rightVariableId) {
      return;
    }
    const variable = find(variables, { id: value });
    updateLogicExecution(clientId, {
      ...execution,
      rightVariableId: value,
      rightValue: variable?.title,
    });
  });

  useEffect(() => {
    if (arithmeticVarType) {
      return;
    }

    if (!rightVariable) {
      if (execution.rightValue) {
        setArithmeticVarType(ExecutionArithmeticVarType.INPUT);
      }
      return;
    }

    const { varType } = rightVariable;
    if (varType === VarType.CUSTOM) {
      setArithmeticVarType(ExecutionArithmeticVarType.VARIABLE);
    } else if (varType === VarType.ANSWER) {
      setArithmeticVarType(ExecutionArithmeticVarType.ANSWER);
    }
  }, [rightVariable, execution.rightValue]);

  return {
    // common
    execution,
    executionType,
    variableOptions,
    leftVariable,
    rightVariable,
    isInvalidActive,
    handleDeleteExecution,
    handleExecutionTypeChange,
    handleLeftValueChange,
    handleRightInputChange,
    // replacement
    replacementInputStyle,
    replacementChoiceOptions,
    handleReplacementChoiceChange,
    handleReplacementDateChange,
    // arithmetic
    arithmeticVarType,
    arithmeticText,
    handleArithmeticOperatorChange,
    handleArithmeticVarTypeChange,
    handleArithmeticSelectChange,
    // etc
    preventInputOnWheel,
  };
};

export default useExecution;
