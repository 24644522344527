/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ApplicationAnswerWithoutResponse = {
    id?: number;
    uuid?: string;
    applicationId?: number;
    templateItemId?: number;
    status?: ApplicationAnswerWithoutResponse.status;
    answer?: Record<string, Record<string, any>>;
    spentTime?: number;
    resubmitCount?: number;
    lastAnswerQuestion?: string;
    lastAnswerPage?: string;
    lastAnswerPageNumber?: number;
};

export namespace ApplicationAnswerWithoutResponse {

    export enum status {
        CREATED = 'CREATED',
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        SUBMITTED = 'SUBMITTED',
        RECREATED = 'RECREATED',
    }


}

