import styles from './NoResults.module.scss';

interface NoResultProps {
  message: string;
}

const NoResult = ({ message }: NoResultProps) => (
  <div className={styles.container}>
    <p>{message}</p>
  </div>
);

export default NoResult;
