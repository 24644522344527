import { AxiosError } from 'axios';
import { Pagination } from 'generated';
import queryAxios from 'query/queryAxios';

import type {
  ApplicationChangeStateRequest,
  ApplicationPageFilter,
  ApplicationPageSearch,
  PageImplApplicationPageResponse,
  ProductResponse,
  TemplateResponse,
  OrganizationUuidResponse,
} from 'generated';

interface GetApplicationsRequestParams {
  search: ApplicationPageSearch;
  filter: ApplicationPageFilter;
  pagination: {
    page: number;
    size: number;
  };
}
export const getApplicationsRequest = async ({
  search,
  filter,
  pagination,
}: GetApplicationsRequestParams) =>
  queryAxios()
    .get<PageImplApplicationPageResponse>(`applications`, {
      params: {
        search: { ...search },
        filter: { ...filter },
        pagination: {
          page: pagination.page,
          size: pagination.size,
          sortDirection: Pagination.sortDirection.DESC,
          sortFields: ['id'],
        },
      },
    })
    .then((data) => data.data);

export const getProductsRequest = async () =>
  queryAxios()
    .get<ProductResponse[]>('products')
    .then((data) => data.data);

export const getOrganizationsRequest = async () =>
  queryAxios()
    .get<OrganizationUuidResponse[]>('organizations')
    .then((data) => data.data);

export const getTemplatesRequest = async () =>
  queryAxios()
    .get<TemplateResponse[]>('templates/all')
    .then((data) => data.data);

export const updateApplicationStatusRequest = async (
  applicationId: number | undefined,
  request: ApplicationChangeStateRequest,
) => {
  if (!applicationId) {
    throw new AxiosError('지원서 ID가 없습니다.');
  }
  return queryAxios()
    .patch(`applications/${applicationId}/state-change`, request)
    .then(({ data }) => data);
};

export const updateApplicationMemoRequest = async (
  applicationId: number | undefined,
  newMemo: string,
) => {
  if (!applicationId) {
    throw new AxiosError('지원서 ID가 없습니다.');
  }
  return queryAxios()
    .put(`applications/${applicationId}`, { memo: newMemo })
    .then(({ data }) => data);
};

export const DownloadDocumentType = {
  XLSX: 'XLSX',
  CSV: 'CSV',
};
export type DownloadDocumentType =
  (typeof DownloadDocumentType)[keyof typeof DownloadDocumentType];

export const downloadApplicationsDataRequest = async (
  exportType: 'APPLICATION' | 'EVALUATION',
  documentType: DownloadDocumentType,
  filters: ApplicationPageFilter,
  search: ApplicationPageSearch,
) => {
  const url = {
    APPLICATION: `applications/documents/${documentType}`,
    EVALUATION: `evaluations/documents/${documentType}`,
  };

  return queryAxios().get(url[exportType], {
    params: {
      filter: { ...filters },
      search: { ...search },
    },
    responseType: 'blob',
  });
};

export const uploadApplicationEvaluationDataRequest = async (file: File) =>
  queryAxios().post(`evaluations/templates/documents`, {
    document: file,
  });
