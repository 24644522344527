/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConditionResponse = {
    id?: number;
    sequence?: number;
    logicGate?: ConditionResponse.logicGate;
    leftVariableId?: number;
    operator?: ConditionResponse.operator;
    rightVariableId?: number;
    rightValue?: string;
};

export namespace ConditionResponse {

    export enum logicGate {
        AND = 'AND',
        OR = 'OR',
        NONE = 'NONE',
    }

    export enum operator {
        EQUAL = 'EQUAL',
        IN_EQUAL = 'IN_EQUAL',
        LESS_THEN = 'LESS_THEN',
        LESS_EQUAL = 'LESS_EQUAL',
        GREATER_THEN = 'GREATER_THEN',
        GREATER_EQUAL = 'GREATER_EQUAL',
        IN = 'IN',
        NOT_IN = 'NOT_IN',
        IS_EMPTY = 'IS_EMPTY',
        IS_NOT_EMPTY = 'IS_NOT_EMPTY',
    }


}

