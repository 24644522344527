/* eslint-disable @typescript-eslint/no-unused-vars */
import Input from 'components/Input/Input';

import { useState } from 'react';

import styles from './VariableItem/VariableItem.module.scss';
import { parsingChoices } from '../util/parsingChoices';

import type { Choice, VariableResponse } from 'generated';

interface Props {
  readOnly: boolean;
  thisCard: VariableResponse;

  handleChoiceValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ChoiceValue = ({
  readOnly,
  thisCard,
  handleChoiceValueChange,
}: Props) => {
  const [text, setText] = useState('');
  const { choices, defaultValue } = thisCard;

  const parsedChoiceTexts = parsingChoices(defaultValue, choices);

  return (
    <>
      {!readOnly ? (
        <Input
          isReadOnly={readOnly}
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            handleChoiceValueChange(e);
          }}
          className={styles.variable_input}
        />
      ) : (
        <Input
          isReadOnly={readOnly}
          defaultValue={parsedChoiceTexts}
          className={styles.variable_input}
        />
      )}
    </>
  );
};

export default ChoiceValue;
