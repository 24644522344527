import styles from './InformationItems.module.scss';

export interface InformationItemProps {
  isLoading: boolean;
  label: string;
  value: number | string | undefined | null;
}

export const InformationItem = ({
  label,
  value,
  isLoading,
}: InformationItemProps) => (
  <div className={styles.item_wrapper}>
    <div className={styles.label}>
      <p>{label}</p>
    </div>
    <div className={styles.value}>
      <p>{isLoading ? 'loading...' : !value ? '-' : value}</p>
    </div>
  </div>
);

interface InformationItemsProps {
  isLoading: boolean;
  id: number | undefined;
  name: string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  productName: string | undefined;
  programName: string | undefined;
  paymentModel: string | undefined;
  createdAt: string | undefined;
}

const InformationItems = ({
  isLoading,
  id,
  name,
  email,
  mobile,
  productName,
  programName,
  paymentModel,
  createdAt,
}: InformationItemsProps) => {
  const items = [
    { label: '지원 ID', value: id },
    { label: '이름', value: name },
    { label: '이메일', value: email },
    { label: '휴대전화', value: mobile },
    { label: '상품명', value: productName },
    { label: '과정명', value: programName },
    { label: '결제모델', value: paymentModel },
    {
      label: '제출 시간',
      value: createdAt
        ? new Date(createdAt).toISOString().slice(0, 19).replace('T', ' ')
        : '-',
    },
  ];

  return (
    <div className={styles.items_wrapper}>
      {items.map((item, index) => (
        <InformationItem
          isLoading={isLoading}
          label={item.label}
          value={item.value}
          key={`information-item-${index}`}
        />
      ))}
    </div>
  );
};

export default InformationItems;
