import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const pageTitles: pageTitle[] = [
  {
    path: '/application',
    title: '지원 관리',
  },
  {
    path: '/application/',
    title: '지원 상세',
    regex: /^\/application\/\d+$/,
  },
  {
    path: '/survey',
    title: '질문지',
  },
  {
    path: '/survey/',
    title: '질문지 등록 및 수정',
    regex: /^\/survey\/\d+$/,
  },
  {
    path: '/survey/set',
    title: '질문지 세트',
  },
  {
    path: '/survey/set/',
    title: '질문지 세트 등록 및 수정',
    regex: /^\/survey\/set\/new/,
  },
  {
    path: '/survey/set/',
    title: '질문지 세트 등록 및 수정',
    regex: /^\/survey\/set\/\d+\/edit/,
  },
  {
    path: '/survey/set/',
    title: '질문지 세트 정보보기',
    regex: /^\/survey\/set\/\d+$/,
  },
  {
    path: '/login',
    title: '로그인',
  },
  {
    path: '/signup',
    title: '관리자 등록',
  },
  {
    path: '/pending-approval',
    title: '승인 대기',
  },
  {
    path: '/admin',
    title: '관리자 권한',
  },
];
const surfix = '지원∙선발 ADMIN';

interface pageTitle {
  path: string;
  title: string;
  regex?: RegExp;
}

interface Props {
  children?: JSX.Element;
}
export const TitleHelmet = ({ children }: Props) => {
  const location = useLocation();
  const find = pageTitles.find((x) => {
    if (x.path === location.pathname) {
      return true;
    }

    if (!x.regex) {
      return false;
    }
    return !!x.regex.exec(location.pathname);
  });

  return (
    <Helmet>
      <title>{find ? `${find.title} | ${surfix}` : surfix}</title>
      {children}
    </Helmet>
  );
};
