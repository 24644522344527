import type { ChangeEvent } from 'react';

export const regexTextChecker = /^\D*$/;
export const regexNumberChecker = /^[0-9]+$/;
export const koreanExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
export const regexBarOrUnderBarChecker = /^[-_a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]*$/; // -,_ 외의 모든 문자

export const checkIsOnlyNum = (value: string) => regexNumberChecker.test(value);

export const checkIsOnlyText = (value: string) => regexTextChecker.test(value);

export const checkBarOrUnderBar = (value: string) =>
  regexBarOrUnderBarChecker.test(value);

export const checkTextKoreanOrNot = (e: ChangeEvent<HTMLInputElement>) => {
  const isKorean = koreanExp.test(e.target.value);
  if (isKorean) {
    e.preventDefault();
    e.target.value = e.target.value.replace(koreanExp, '');
    return true;
  }
  return false;
};

export const preventWriteExclamationMark = (
  e: ChangeEvent<HTMLInputElement>,
) => {
  if (!checkBarOrUnderBar(e.target.value)) {
    e.preventDefault();
    e.target.value = e.target.value.replace(regexBarOrUnderBarChecker, '');
  }
};

export const preventWriteNumber = (e: ChangeEvent<HTMLInputElement>) => {
  if (!checkIsOnlyNum(e.target.value)) {
    e.preventDefault();
    e.target.value = e.target.value.replace(regexNumberChecker, '');
  }
};

export const preventWriteText = (e: ChangeEvent<HTMLInputElement>) => {
  if (!checkIsOnlyText(e.target.value)) {
    e.preventDefault();
    e.target.value = e.target.value.replace(regexTextChecker, '');
  }
};
