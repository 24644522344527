import { Button } from 'components/Button/Button';
import Error from 'components/Error/Error';
import Loading from 'components/Loading/Loading';
import Modal from 'components/Modal';
import { queryClient } from 'query/client';
import queryKeys from 'query/queryKeys';
import { useGetSurveyById } from 'utils/hooks/queries/surveys/useSurveyQuery';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import PreviewSurvey from './PreviewSurvey';

export interface PreviewSurveyState {
  surveyId: number | undefined;
  isOpen: boolean;
}

interface PreviewSurveyModalProps {
  surveyId: number;
  isOpen: boolean;
  onClose: () => void;
}

const PreviewSurveyModal = ({
  surveyId,
  isOpen,
  onClose,
}: PreviewSurveyModalProps) => {
  const navigate = useNavigate();

  const {
    data: surveyData,
    isLoading,
    isError,
  } = useGetSurveyById({
    surveyId,
    options: {
      cacheTime: 0,
    },
  });

  useEffect(() => {
    if (isOpen) {
      queryClient.invalidateQueries(queryKeys.SURVEY_DETAIL_BY_ID(surveyId));
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'large'}>
      <Modal.Content>
        <Modal.Header>{surveyData?.title ?? '-'}</Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <Error
              message={`질문지를 불러올 수 없습니다.\n다시 시도해주세요`}
            />
          ) : surveyData.definition ? (
            <PreviewSurvey definition={surveyData.definition} />
          ) : (
            <p>미리보기가 없습니다.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => navigate(`/survey/${surveyId}`)}>
            수정하기
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default PreviewSurveyModal;
