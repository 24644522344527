import { useCallback } from 'react';

import {
  DragDropContext,
  Droppable,
  Draggable,
  type DropResult,
} from '@hello-pangea/dnd';

import LogicBox from './LogicBox/LogicBox';
import { useLogics, useLogicsActions } from '../store';

interface Props {
  className: string;
  beforeAnnounce?: boolean;
}

const LogicsView = ({ className, beforeAnnounce }: Props) => {
  const logics = useLogics();
  const { reorderLogics } = useLogicsActions();

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const draggedLogics = Array.from(logics);
      const [removed] = draggedLogics.splice(result.source.index, 1);
      draggedLogics.splice(result.destination.index, 0, removed);

      reorderLogics(draggedLogics);
    },
    [logics],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={className}
          >
            {logics.map((logic, idx) => (
              <Draggable
                draggableId={String(logic.clientId)}
                key={logic.clientId}
                index={idx}
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <LogicBox
                      logic={logic}
                      dragHandleProps={provided.dragHandleProps}
                      isDone={!beforeAnnounce}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default LogicsView;
