import { ReactComponent as Logo } from 'assets/adm-logo.svg';
import { ReactComponent as BookOpenBlue } from 'assets/bookopen-blue.svg';
import { ReactComponent as FileTextBlue } from 'assets/filetext-blue.svg';
import { ReactComponent as GridBlue } from 'assets/grid-blue.svg';
import { ReactComponent as Toggle } from 'assets/toggle.svg';
import { ReactComponent as UsersBlue } from 'assets/users-blue.svg';
import classNames from 'classnames';
import { Button } from 'components/Button/Button';
import { useAuth } from 'hooks/useAuth';
import { useSidebarStore } from 'store';

import { Users, Grid, BookOpen, FileText } from 'react-feather';
import { NavLink } from 'react-router-dom';

import styles from './Sidebar.module.scss';

export const MENU_TABS = [
  {
    title: '대시보드',
    pathname: '/',
    iconSet: { inactive: <Grid />, active: <GridBlue /> },
  },
  {
    title: '지원 / 심사 관리',
    pathname: '/application',
    iconSet: { inactive: <BookOpen />, active: <BookOpenBlue /> },
    subMenu: [
      { title: '지원관리', pathname: '/application' },
      { title: '심사 기준 관리', pathname: '/application/evaluation-standard' },
      { title: '심사결과', pathname: '/application/evaluation-result' },
    ],
  },
  {
    title: '질문지 관리',
    pathname: '/survey',
    iconSet: { inactive: <FileText />, active: <FileTextBlue /> },
    subMenu: [
      { title: '질문지', pathname: '/survey' },
      { title: '질문지 세트', pathname: '/survey/set' },
    ],
  },
  {
    title: '관리자 권한',
    pathname: '/admin',
    iconSet: { inactive: <Users />, active: <UsersBlue /> },
  },
];

const Sidebar = () => {
  const auth = useAuth();
  const { sidebarStatus, updateSidebarStatus } = useSidebarStore();

  return (
    <aside className={classNames(styles.side_bar, styles[sidebarStatus])}>
      <div className={styles.background_layer} />
      <ul className={styles.menu_list}>
        <li className={classNames(styles.menu_title, styles.first)}>
          <div className={styles.link_wrap}>
            <button
              onClick={() =>
                updateSidebarStatus(
                  sidebarStatus === 'expanded' ? 'collapsed' : 'expanded',
                )
              }
              className={styles.detail_button}
            >
              <Toggle />
            </button>
          </div>
          <h1>
            <Logo />
          </h1>
        </li>

        {MENU_TABS.map((menu) => (
          <li key={menu.title}>
            <section className={styles.menu_title}>
              <div className={styles.link_wrap}>
                <NavLink
                  to={menu.pathname}
                  className={classNames(styles.link, styles.link_icon)}
                >
                  {({ isActive }) =>
                    menu.iconSet[isActive ? 'active' : 'inactive']
                  }
                </NavLink>
              </div>
              <h3>
                <NavLink
                  to={menu.pathname}
                  className={({ isActive }) =>
                    isActive ? styles.active : undefined
                  }
                >
                  {menu.title}
                </NavLink>
              </h3>
            </section>

            {menu.subMenu && (
              <ul
                className={classNames(
                  styles.sub_menu_list,
                  styles[sidebarStatus],
                )}
              >
                {menu.subMenu.map((el, idx) => (
                  <li className={styles.sub_menu} key={el.title}>
                    <NavLink
                      to={el.pathname}
                      className={({ isActive }) =>
                        isActive
                          ? classNames(styles.link, styles.active)
                          : styles.link
                      }
                      end={idx === 0}
                    >
                      {el.title}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      <div className={styles.logout_button_box}>
        <Button
          variant="skeleton-gray"
          onClick={() => auth.signout()}
          isFullWidth
        >
          로그아웃
        </Button>
      </div>
    </aside>
  );
};

export default Sidebar;
