import { create } from 'zustand';

type SidebarStatus = 'expanded' | 'collapsed';

export interface SidebarStoreType {
  sidebarStatus: SidebarStatus;
  updateSidebarStatus: (newStatus: SidebarStatus) => void;
}

export interface PageInfoType {
  page: string;
  onClick?: () => void;
}

export interface HeaderInfoType {
  title: string;
  titleTag?: string;
  pageInfo?: PageInfoType[];
}

export const useSidebarStore = create<SidebarStoreType>((set) => ({
  sidebarStatus: 'expanded',
  updateSidebarStatus: (newStatus) => set({ sidebarStatus: newStatus }),
}));

export interface HeaderStoreType {
  headerInfo: HeaderInfoType;
  setHeaderInfo: (state: HeaderInfoType) => void;
}

export const useHeaderInfoStore = create<HeaderStoreType>((set) => ({
  headerInfo: {
    title: '',
    titleTag: '',
    pageInfo: undefined,
  },
  setHeaderInfo: (headerInfo) =>
    set({
      headerInfo,
    }),
}));
