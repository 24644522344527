import { Navigate } from 'react-router-dom';

import styles from './PendingApprovalPage.module.scss';
import { useAuth } from '../../hooks/useAuth';

const PendingApprovalPage = () => {
  const auth = useAuth();

  if (auth.user) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.cover} />
      <p className={styles.title}>관리자 권한 승인 대기 중입니다!</p>
      <p className={styles.description}>
        ISP팀에게 슬랙을 통해 승인을 요청해 주세요.
      </p>
    </div>
  );
};

export default PendingApprovalPage;
