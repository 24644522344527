/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserResponse } from './UserResponse';

export type ApplicationResponse = {
    id?: number;
    uuid?: string;
    title?: string;
    description?: string;
    slugUrl?: string;
    productId?: number;
    templateId?: number;
    user?: UserResponse;
    memo?: string;
    applyingStatus?: ApplicationResponse.applyingStatus;
    evaluationStatus?: ApplicationResponse.evaluationStatus;
    intentionStatus?: ApplicationResponse.intentionStatus;
    answerProgress?: string;
};

export namespace ApplicationResponse {

    export enum applyingStatus {
        IN_PROGRESS = 'IN_PROGRESS',
        CANCELLED = 'CANCELLED',
        COMPLETED = 'COMPLETED',
        INCOMPLETED = 'INCOMPLETED',
    }

    export enum evaluationStatus {
        NONE = 'NONE',
        WAITING = 'WAITING',
        IN_PROGRESS = 'IN_PROGRESS',
        PASSED = 'PASSED',
        FAILED = 'FAILED',
        RELIEVED = 'RELIEVED',
        RELIEVE_WAIT = 'RELIEVE_WAIT',
        DISQUALIFIED = 'DISQUALIFIED',
        RESIGNED = 'RESIGNED',
    }

    export enum intentionStatus {
        INITIALIZE = 'INITIALIZE',
        CONFIRMED = 'CONFIRMED',
        REVOKED = 'REVOKED',
        NON_RESPONSE = 'NON_RESPONSE',
    }


}

