import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Input: ComponentStyleConfig = {
  baseStyle: {
    fontStyle: 'body-regular',
  },
  variants: {
    default: {
      field: {
        bg: 'white',
        border: '1px solid',
        borderColor: 'bluishGray.200',
        color: 'bluishGray.400',
        _focus: {
          color: 'bluishGray.700',
        },
        _disabled: {
          bgColor: 'bluishGray.100',
          color: 'bluishGray.500',
          borderColor: 'bluishGray.300',
        },
      },
    },
    blue: {
      field: {
        bgColor: 'bluishGray.100',
        color: 'bluishGray.500',
        borderColor: 'bluishGray.300',
      },
    },
    blue_searchbox: {
      field: {
        bg: 'bluishGray.100',
        color: 'gray.600',
        border: '1px solid',
        borderColor: 'bluishGray.300',
      },
    },
    default_searchbox: {
      field: {
        bg: 'white',
        fontSize: '14px',
        color: 'gray.600',
        border: '1px solid',
        borderColor: 'bluishGray.300',
        transition: 'border 0.3s, borderColor 0.3s',
        _focus: {
          border: '3px solid',
          borderColor: 'blue.300',
        },
        _disabled: {
          bg: 'bluishGray.100',
          color: 'gray.600',
          border: '1px solid',
          borderColor: 'bluishGray.300',
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
};
