/* eslint-disable @typescript-eslint/no-use-before-define */
import { ReactComponent as ChevronForward } from 'assets/chevron-forward.svg';

import styles from './PageTitle.module.scss';

interface PageTitleProps {
  title: string;
  className?: string;
  description?: string;
  pageInfo?: {
    page: string;
    onClick?: () => void;
  }[];
  titleTag?: string;
}

export const PageTitle = ({
  title,
  className,
  description,
  pageInfo,
  titleTag,
}: PageTitleProps) => (
  <header className={`${styles.header} ${className}`}>
    <div className="center-align">
      <div className={styles.title_wrapper}>
        <h2 className={`${styles.title} text-style-h3`}>{title}</h2>
        {titleTag ? (
          <div className={styles.title_tag}>
            <p>{titleTag}</p>
          </div>
        ) : null}
      </div>
      {description ? <div className="text-style-t1">{description}</div> : null}

      {pageInfo ? (
        <div className={styles.page_info}>
          {pageInfo.map((info, index) =>
            index !== pageInfo.length - 1 ? (
              <Breadcrumb {...info} key={`breadcrumb-${index}`} />
            ) : (
              <Breadcrumb isLast {...info} key={`breadcrumb-${index}`} />
            ),
          )}
        </div>
      ) : null}
    </div>
  </header>
);

interface BreadcrumbProps {
  page: string;
  onClick?: () => void;
  isLast?: boolean;
}

const Breadcrumb = ({ page, isLast = false, onClick }: BreadcrumbProps) => {
  if (isLast) {
    return (
      <span className={[styles.breadcrumb, 'text-style-body'].join(' ')}>
        {page}
      </span>
    );
  }

  return (
    <>
      <button onClick={onClick}>
        <span className={[styles.breadcrumb, 'text-style-t1'].join(' ')}>
          {page}
        </span>
      </button>
      <ChevronForward />
    </>
  );
};
